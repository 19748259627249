.get-in-touch-form{
    margin-bottom: 75px;
    textarea.form-control{
        height: 500px;
    }
    .col-12{
        position: relative;
        .absolute {
            position: absolute;
            bottom: 0;
            right: 0;
            @media (max-width:769px){
                position: static;
            }
        }
    }
}