.nutrition-advice-centre-page{

  .content-wrapper{

    img{
      margin-bottom: 0 !important;
    }
    position: relative;
    &:before{
      content: '';
      position: absolute;
      bottom: -6px;
      height: 5px;
      z-index: 9999;
    }
    &:after{
      content: '';
      display: block;
      height: 5px;
      //background: $red;
      position: absolute;
      z-index: 9999;
      top: 0;
      width: calc(100% - 30px);
      left: 15px;
    }
  }
  .image-with-text {
    margin-bottom: 0;
    padding-bottom: 0;
    p{
      margin-bottom: 0;
    }
    img{
      @media screen and (max-width: 768px){
        width: 100%;
      }
    }
    h2{
      margin-top: 15px;
      margin-bottom: 40px;
      @media screen and (max-width: 768px){
        margin-top: 30px;
      }
    }
    h2,
    p{
    @media screen and (max-width: 768px){
      text-align: left !important;
    }
  }
    &:nth-of-type(2) {
      .content-wrapper{
        &:before,
        &:after{
          background: $red;
        }
      }
    }
    &:nth-of-type(6){
      .content-wrapper{
        &:before,
        &:after{
          background: $green;
        }
      }
    }
    &:nth-of-type(4){
      .content-wrapper{
        &:before,
        &:after{
          background: $goldLineGold;
        }
        img{
          float: right;
        }
      }
    }
    &:nth-child(4){
      div[class*="col-"]{
        &:first-child{
          order: 2;
        }
        &:nth-child(2){
          order: 1;
        }
        @media screen and (max-width: 768px){
          &:first-child{
            order: 1;
          }
          &:nth-child(2){
            order: 2;
          }
        }
      }
    }

  }

  .ambassador-hero-promo{
    position: relative;
    padding: 75px 0;
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(16, 20, 62, 0.5);
    }
  }

  .page-heading-alt {
    background-color: $lightestBlue;
    .additional-image{
      left: -260px;
    }
  }



  .helpline{
    margin-bottom: 80px;
    .employees-list{
      background-color: $lightestBlue;
      .employee-wrapper{
        justify-content: space-around;
      }
    }
  }

  .nutrition,
  .canine-message{
    .employee-wrapper{
      background-color: $lightestBlue;
      .particle-image{
        top: 95px;
        right: -280px;
        left: unset;
      }
    }
  }

  .canine-message{
    .employees-list{
      .row{
        justify-content: center;
      }
    }
  }

  .helpline,
  .nutrition,
  .canine-message{
    .employees-list{
      @media screen and (max-width: 768px){
        background: $lightestBlue;
      }
      @media screen and (max-width: 480px){
        background: $lightestBlue;
      }
    }
  }

  .employee-slider{
    .slick-dots{
      bottom: -35px;
      width: 100%;
      @media screen and (max-width: 480px){
        bottom: -90px;
      }
      li{
        button{
          border-color: $darkBlue;
          &:hover{
            background: transparent;
          }
        }
        &.slick-active{
          button{
            background: $lightBlue;
          }
        }
        &:after{
          background: $darkBlue;
        }
      }
    }
  }
  .employees-list{
    padding-top: 0;
    .particle-image{
      display: none;
    }
    .job-role{
      margin: 0;
    }
  }


}




