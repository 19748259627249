.careers-single-page{
  form{
    background-color: $lightestBlue;
    padding: 20px;
    textarea{
      height: auto;
    }
  }
}

.application-form{
  border-radius: 3px;
  margin-bottom: 5rem;
  .blue-header{
    background: $lightestBlue;
    color: $autarkyGrey;
    display: block;
    font-size: rem(20);
    font-weight:bold;
    padding: 0.75rem 1.5rem;
    border-radius: 3px 3px 0 0;
  }
  form{
    padding: 0.75rem 1.5rem;
    .alert {
      margin-bottom: 0;
    }
    .hidden{
      display:none;
    }
    .form-group {
      .error{
        color:red;
        font-size: 14px;
        margin-top: -10px;
        display: block;
      }
      label {
        color: $autarkyGrey;
        font-size: rem(14);
        margin-bottom: 0;
        display:block;
      }
      input.hidden-input{
        opacity: 0;
        width: 0;
        height: 0;
        z-index: -99999;
        pointer-events: none;
        overflow: hidden;
        display: none;
      }
      label.file-label{
        margin-top: 10px;
        background: $gold;
        padding: 0;
        width: 200px;
        height: 50px;
        line-height: 50px;
        margin-left: 0;
        border-radius:5px;
        .fal{
          margin-right: 10px;
        }
        &:hover{
          background: rgba($gold, 0.8);
          box-shadow:none;
        }
      }
    }

    .form-footer {
      text-align: right;
      //transform: translateY(75%);
      input[type="submit"] {
        margin: 0 auto;
      }
    }
  }
}
