.products--link-block{
    text-align: center;
    margin-bottom: 50px;
    .product-title{
        margin-top:10px;
        display: block;
        margin-bottom: 15px;
    }
    .btn.block{
        width: 80%;
    }
}