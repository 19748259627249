.products-single {

    margin-bottom: 50px;

    *.lang-hide {
        visibility: hidden;
        height: 0;
        overflow: hidden;
        margin-bottom: 0;
        margin: 0 !important;
    }

    h3.subtitle {
        margin-bottom: 1em;
    }

    h1 {
        word-break: break-word;
        @media screen and (max-width: 768px) {
            font-size: 30px;
        }
    }

    .content {
        h4 {
            &:nth-of-type(2) {
                margin-top: 45px;
            }
        }
    }

    #nutritional-analysis {
        .inner {
            columns: 2;
        }
    }


    .content {
        strong {
            color: $darkBlue;
        }
    }

    .product-buttons {
        padding-top: 30px;

        .product.btn {
            background: $red;
            width: 100%;
            margin-bottom: 15px;
            @media (max-width: 1200px) {
                height: 7em;
                display: flex;
                align-items: center;
            }
            @media (max-width: 400px) {
                height: auto;
            }
        }
    }

    .large-slider {
        margin: 0 auto 50px;

        .slick-slide {

            img {
                width: auto !important;
                max-height: 500px;
                max-width: 100%;
                margin: auto !important;
                display: block !important;
            }
        }
    }

    .slider-nav {
        width: 90%;
        margin: 0 auto 40px;

        .slick-slide {
            height: 193px;

            > div {
                height: 100%;
                display: flex;

                img {
                    max-width: 90%;
                    width: auto !important;
                    max-height: 100%;
                    display: block !important;
                    margin: auto !important;
                }
            }
        }

        .slick-arrow {
            &:before {
                color: $darkBlue;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 300;
            }

            &.slick-prev {
                &:before {
                    content: '\f053';
                }
            }

            &.slick-next {
                &:before {
                    content: '\f054';
                }
            }
        }
    }

    aside {
        text-align: center;
        position: relative;
        z-index: 4;

        .tips-bubble {
            padding: 30px;
            text-align: left;
            background: $lighterBlue;
            margin-top: 30px;
            margin-bottom: 35px;

            strong {
                font-family: $titleFont;
                margin-bottom: 10px;
                display: block;
                font-weight: normal;
            }

            p {
                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            position: relative;

            &:after {
                content: "";
                width: 0;
                height: 0;
                border-top: 0px solid transparent;
                border-bottom: 40px solid transparent;
                border-left: 40px solid $lighterBlue;
                position: absolute;
                top: 100%;
                left: 0;
            }
        }
    }

    .ambassador-section {
        margin-top: 74px;

        .ambassador-slider {
            a {
                display: block;
                width: 100%;
                height: 300px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: top center;
            }

            li {
                text-align: center;

                img {
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                    height: auto;
                }
            }
        }

        p.underline {
            display: block;
            border-bottom: 1px solid rgba($bodyCopy, 0.4);
            text-align: left;
            margin-bottom: 20px;
        }

        .slick-arrow {
            position: absolute;
            font-size: 0;
            color: transparent;
            width: 40px;
            height: 40px;
            top: 50%;
            transform: translateY(-50%);
            background: transparent;
            border: 0;
            cursor: pointer;

            &:before {
                color: $darkBlue;
                font-size: 20px;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 300;
            }

            &.slick-prev {
                left: -40px;
                @media screen and (max-width: 768px) {
                    left: 0;
                    top: 100%;
                }

                &:before {
                    content: '\f053';
                }
            }

            &.slick-next {
                right: -40px;
                @media screen and (max-width: 768px) {
                    right: 0;
                    top: 100%;
                }

                &:before {
                    content: '\f054';
                }
            }
        }
    }

    main {
        padding-left: 65px;
        @media (max-width: 768px) {
            padding-left: 15px;
        }

        .links {
            margin-top: 50px;

            .btn {
                width: 100%;
            }
        }

        .tabs {
            margin: 60px auto;
            margin-bottom: 20px;

            .row {
                margin: 0;
            }

            header {
                li {
                    list-style: none;
                    padding: 8px 10px;
                    display: flex;
                    font-size: 16px;
                    text-align: center;
                    background: $darkBlue;
                    font-family: $titleFont;
                    border: 1px solid $darkBlue;
                    border-right: 0;
                    border-bottom-color: $white;
                    color: $white;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;

                    &:last-child {
                        border-right: 1px solid $darkBlue;
                    }

                    &.active {
                        background: $white;
                        border-bottom: 0;
                        border-color: $darkBlue;
                        color: $darkBlue;
                        border-right: 1px solid $darkBlue;
                        cursor: default;

                        + li {
                            border-left: none;
                        }
                    }
                }
            }

            .tab-content {
                > div {
                    display: none;
                    padding: 20px;
                    background: $white;
                    border: 1px solid $darkBlue;
                    border-top: none;
                    font-size: 16px;
                    max-height: 20em;
                    overflow: hidden;
                    position: relative;
                    transition: all 1s;

                    &.active {
                        display: block;
                    }

                    &.show-more {
                        .button-wrap {
                            display: block;
                        }
                    }

                    &.expanded {
                        max-height: unset;

                        .button-wrap {
                            opacity: 0;
                        }
                    }

                    .button-wrap {
                        display: none;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        padding: 20px;
                        background: $lightestBlue;
                        opacity: 1;
                        transition: opacity 0.5s;
                    }

                    .table-wrap {
                        overflow: auto;
                        margin-bottom: 30px;

                        table {
                            th {
                                padding: 10px;
                                white-space: nowrap;
                                background-color: rgba($darkBlue, 0.10);
                                border: 1px solid $grey;
                                font-weight: bold;
                            }

                            td {
                                padding: 10px;
                                border: 1px solid $grey;
                            }
                        }
                    }
                }
            }
        }

        h4 {
            &.content-section-heading {
                font-weight: bold;
            }
        }

        .pdf-button {
            padding: 0.85rem 1.13rem;

            img {
                margin-bottom: 0 !important;
                float: left;
            }
        }

    }

    .products-loop {
        background: $lightestBlue;

        .container {
            padding: 60px 15px;
        }

        position: relative;

        &:after {
            content: "";
            position: absolute;
            right: -100px;
            top: -450px;
            //background-image: url(/dod/images/particles-2.svg);
            background-repeat: no-repeat;
            background-position: 0 0;
            transform: rotate(-180deg);
            width: 400px;
            height: 800px;
            background-size: contain;

            @media(max-width: 1024px) {
                z-index: -1;
            }
        }

        h2 {
            font-family: $titleFont;
            font-weight: 300;
            text-transform: uppercase;
            margin-bottom: 45px;
        }

        .products--link-block {
            .image-container {
                text-align: center;

                img {
                    margin: 0 auto;
                }
            }
        }

        .slick-arrow {
            position: absolute;
            font-size: 0;
            color: transparent;
            width: 40px;
            height: 40px;
            top: 50%;
            transform: translateY(-50%);
            background: transparent;
            border: 0;
            cursor: pointer;

            &:before {
                color: $darkBlue;
                font-size: 20px;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 300;
            }

            &.slick-prev {
                left: -40px;

                &:before {
                    content: '\f053';
                }
            }

            &.slick-next {
                right: -40px;

                &:before {
                    content: '\f054';
                }
            }
        }
    }

    .info,
    .feeding-guidelines {
        h4 {
            font-family: $bodyFont-bold;
        }

        p {
            color: $black;
        }
    }

    .info {
        h4 {
            margin-top: 1rem;
        }

        p {
            &:nth-child(n+3) {
                margin-bottom: 2px;
            }
        }
    }

    .feeding-guidelines {
        h4 {
            margin-top: 2rem;
        }
    }
}
