header.site-header {
    background: $darkBlue;
    position: relative;
    z-index: 99;
    @media screen and (min-width: 768px) {
        height: auto;
    }

    a.header-social-link{
        color: $white !important;
    }

    /**
        TODO
     */
    //font-size: 12px;

    .logo-container {
        .site-logo {
            img {
                margin-top: 7px;
            }
        }
    }

    .site-crest {
        margin-left: 10px;
    }

    .topbar {
        width: 100%;
        text-align: right;
        font-family: $bodyFont-bold;

        /**
            TODO
         */
        font-size: 16px;
        //font-size: 14px;

        .notices {
            display: inline-block;
            color: $white;

            span {
                margin-left: 15px;
            }

            i {
                margin-right: 5px;
            }

            img {
                margin-left: 10px;
            }
        }

        .search-container {
            .search-group {
                width: 270px;
                float: right;

                .form-control {
                    margin-top: 3px;
                    height: auto;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    background: $white;
                    padding-left: 10px;
                    font-weight: normal;
                    border-radius: 0;

                    /**
                          TODO
                    */
                    //font-size: 12px;
                    border: 1px solid $white;
                }

                .input-group-append {
                    button {
                        background: white;
                        border: 0;
                        height: 36px;
                        margin-top: 3px;
                        line-height: 35px;
                    }
                }
            }
        }

        .btn.light-button {
            margin: 0;
            margin-top: -6px;
            margin-left: 6px;
            width: auto;
            letter-spacing: 1px;
        }

        .contact-info {
            display: flex;
            align-items: center;
            padding: 5px 15px;
            padding-top: 10px;
            margin-left: 10px;
            line-height: 32px;
            padding-right: 0;
            font-size: 12px;
            position: relative;
            @media (max-width: 992px) {
                display: none;
            }

            a {
                color: $white;
            }

            > a {
                &:before {
                    margin-right: 5px;
                    font-weight: bold;
                }

                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }

            .social {
                padding: 0;
                margin: 0 0 0 20px;
                display: inline-block;

                a.header-social-link {
                    display: inline-block;
                    list-style: none;
                    font-size: 20px;
                    margin-left: 3px;
                    position: relative;
                    top: 1px;
                }
            }
        }

        .ct-language {
            cursor: pointer;

            /**
                TODO
             */
            font-size: 15px;
            //font-size: 12px;
        }

        .translate-ul {
            display: inline-block;
            position: relative;

            .ct-language {
                color: $white;
            }

            img.powered {
                position: absolute;
                left: 0;
            }

            .ct-language__dropdown {
                position: absolute;
                height: 0;
                min-width: 135px;
                background-color: $white;
                border-radius: 7px;
                z-index: 99;
                overflow: hidden;
                text-align: left;
                left: -10px;

                &.open {
                    height: auto;
                }

                a {
                    display: block;

                    &:hover {
                        background-color: $gold;
                        color: $white;
                    }

                    padding: 2px 15px;
                    color: $black;
                }
            }
        }
    }
}

nav.main-nav {
    margin: 25px 0 20px;
    text-align: center;
    @media (max-width: 1200px) {
        margin-top: 0;
    }

    //text-transform: uppercase;
    li {
        display: inline-block;
        font-family: $bodyFont-bold;
        font-weight: 600;
        position: relative;

        /**
            TODO
         */
        font-size: 16px;
        //font-size: 14px;

        i {
            color: $gold;
        }

        @media (max-width: 1200px) {
            font-size: 14px;
        }

        ul {
            opacity: 0;
            position: absolute;
            top: 75%;
            background: $lightBlue;
            text-align: left;
            padding: 0;
            padding: 5px;
            list-style: none;
            min-width: 100%;
            left: 0;
            pointer-events: none;
            transition: all 0.25s;
            border-top: 5px solid $gold;
            border-bottom: 0;
            min-width: 165px;
            @media screen and (max-width: 768px), screen and (max-width: 480px) {
                background: transparent;
                border-top: none;
            }

            li {
                width: 100%;
                border-bottom: 1px solid $darkBlue;

                &:last-of-type {
                    border-bottom: 0;
                }

                &.item-has-child {
                    ul {
                        position: absolute;
                        top: -5px;
                        left: calc(100% + 2px);
                        border-top:0;
                        //border-left: 1px solid $darkBlue;
                    }

                    i {
                        display: none;
                        //position: absolute;
                        //top: 34%;
                        //right: 0;
                        //transform: rotate(-90deg);
                    }
                }
            }
        }

        &:hover {
            > ul {
                opacity: 1;
                top: 95%;
                pointer-events: auto;

                a {
                    display: block;
                }
            }

            > a,
            > i {
                color: $gold;
            }
        }

        a {
            color: $white;
            padding: 8px 8px;
            transition: all 0.25s;
            letter-spacing: 0px;

            /**
                TODO
             */
            //font-size: 12px;
            font-size: 16px;

            @media (max-width: 992px) {
                padding: 8px 10px;
            }
            //firefox only
            @-moz-document url-prefix() {
                font-size: 15px;
            }

            //Safari only
            @media not all and (min-resolution: .001dpcm) {
                font-size: 14px;
            }

            &.highlight {
                background: $gold;

                &:hover {
                    background: $white;
                    color: $gold;
                }

                &.active {
                    color: $white;

                    &:hover {
                        color: $gold;
                    }
                }
            }

            &.active {
                color: $gold;
            }

            &.has-child {
                padding-right: 5px;
            }
        }

        &:last-of-type {
            a {
                padding-right: 0;
            }
        }
    }
}

@media (max-width: 992px) {
    height: 70px;
    .logo-container {
        text-align: center;
        padding: 20px 0;
        justify-content: center;
    }
    .topbar {
        text-align: center;
        //display: inline-block;

        .contact-info {
            margin-top: 20px;

            &:after {
                content: '';
                background: $gold;
                height: 100%;
                position: absolute;
                width: 100%;
                top: 0;
                right: 100%;
            }
        }
    }
}

@media (max-width: 768px) {
    .logo-container {
        text-align: center !important;
    }
}

.nav-toggle {
    width: 34px;
    height: 4px;
    position: relative;
    background: $gold;
    margin: 33px auto;
    float: right;
    transition: all 0.5s;
    border-radius: 50px;

    &:before {
        content: '';
        position: absolute;
        top: -12px;
        height: 4px;
        background: $gold;
        width: 100%;
        left: 0;
        transition: all 0.5s;
        border-radius: 50px;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: -12px;
        height: 4px;
        background: $gold;
        width: 100%;
        border-radius: 50px;
        left: 0;
        transition: all 0.5s;
    }

    &.open {
        background: transparent;
        transition: all 0.5s;

        &:before {
            transform: rotate(45deg);
            transform-origin: center left;
            transition: all 0.5s;
        }

        &:after {
            transform: rotate(-45deg);
            transform-origin: center left;
            transition: all 0.5s;
        }
    }
}

@media (max-width: 992px) {
    &.nav-open {
        nav.main-nav {
            top: 111px;
            opacity: 1;
            pointer-events: auto;
        }
    }
    .logo-container {
        text-align: left;
        justify-content: left;

        .site-logo {
            padding: 0 15px;

            img {
                width: auto;
                height: 46px;
                margin-top: -10px;
            }
        }
    }
    nav.main-nav {
        position: fixed;
        top: 100%;
        opacity: 0;
        pointer-events: 0;
        left: 0;
        background: $darkBlue;
        height: calc(100vh - 60px);
        margin: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 0px 40px 120px;
        overflow: auto;
        transition: all 0.5s;

        li {
            font-size: 24px;
            display: block;
            text-align: left;
            margin: 10px 0;

            a {
                &.highlight {
                    display: inline-block;
                    margin-top: 20px;
                }

                &:not(.highlight) {
                    padding: 10px 0;
                }

                &.has-child {
                    &:before {
                        display: none;
                    }

                    //&:after{
                    //    content: '\f078';
                    //    font-family: 'Font Awesome 5 Pro';
                    //    font-weight: 900;
                    //    font-size: 0.8em;
                    //    margin-left:15px;
                    //}

                }

                @media screen and (max-width: 768px), screen and (max-width: 480px) {
                    font-size: 25px;
                }
            }

            &.open {
                > a {
                    color: $gold;
                }
            }

            &:hover {
                > ul a {
                    display: inline;
                }
            }

            i {
                margin-top: 10px;
                margin-left: 25px;
                font-size: 25px;
                color: $white;

                &:hover {
                    color: $gold;
                }

                @media screen and (max-width: 768px), screen and (max-width: 480px) {
                    font-size: 25px;
                }
            }

            ul {
                position: static;
                opacity: 1;
                padding-left: 25px;
                display: none;
            }
        }

        .social {
            padding: 0;
            margin-top: 2rem;
            margin-bottom: 0;
            text-align: left;

            ul {
                margin-left: 0;
                margin-bottom: 0;
                padding-left: 0;

                li {
                    display: inline-block;
                    margin-right: 15px;
                    font-size: 36px;

                    a {
                        font-size: 24px;
                        color: $white;
                    }
                }
            }
        }

        footer {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 20px;
            background: $darkBlue;
            color: $white;

            .contact-info {
                font-size: 16px;
                text-align: left;
                margin-bottom: 25px;

                a {
                    color: $white;
                    text-transform: none;

                    &:before {
                        content: '|';
                        margin-right: 5px;
                        font-weight: bold;
                    }

                    &:first-child {
                        &:before {
                            display: none;
                        }
                    }
                }

            }

            .notices {
                font-size: 16px;

                span {
                    float: left;

                    &:last-child {
                        float: right;
                        text-transform: none;
                    }
                }
            }
        }
    }
}

.fix {
    position: fixed;
    width: 100%;
}
