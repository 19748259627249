.testimonials-slider-with-media {
    .testimonials-slider-with-media-body {
        display: flex;
        max-width: 100%;
        @media(max-width: 1024px){
            display: block;
            width: 100%;
        }
        @media(max-width: 320px){
            display: block;
            width: 100%;
        }
        .testimonials-media {
            display: flex;
            width: 50%;
            @media(max-width: 1024px){
                width: 100%;
            }
            @media(max-width: 320px){
                width: 100%;
            }
            .plyr__video-embed{
                height: 100%;
            }
        }
        .testimonials-slider-wrapper {
            display: flex;
            flex-direction: column;
            width: 50%;
            background: $lightestBlue;
            position: relative;
            @media(max-width: 1024px){
                display: block;
                width: 100%;
            }
            @media(max-width: 320px){
                display: block;
                width: 100%;
            }
            h2 {
                text-align: center;
                margin-bottom: 0 !important;
                padding: 65px 0 35px 0;
            }
            .testimonials-slider {
                display: block;
                width: 100%;
                .testimonial-slide {
                    padding: 0 150px 80px 150px;
                    @media(max-width: 1024px){
                        padding: 10px;
                    }
                    @media(max-width: 320px){
                        padding: 10px;
                    }
                }
            }
        }
        .testimonials-slider-button {
            position: absolute;
            top: 80%;
            left: calc(50% - 50px);
            width: 30px;
            height: 30px;
            border-radius: 30px;
            text-align: center;
            line-height: 28px;
            font-size: 14px;
            background-color: $darkBlue;
            color: $white;
            cursor: pointer;
            opacity: 1;
            &.testimonials-slider-button-next{
                left: calc(50% + 0px);
                right: 0;
            }
            &:hover{
                opacity: 0.8;
            }
            i {
                line-height: 30px;
            }
            @media(max-width: 1024px){
                top: 87%;
            }
            @media(max-width: 320px){
                top: 93%;
            }
        }
    }
}