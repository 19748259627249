.link-block{
    width: 100%;
    padding-bottom: 100%;
    @media (max-width:769px){
        padding-bottom: 60%;
    }
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($darkBlue, 0.35);
        width: calc(100%);
        height: calc(100%);
        z-index: 0;
        transition:all ease 0.3s;
    }
    .info{
        position: absolute;
        top:50%;
        left: 20px;
        transform: translateY(-50%);
        color: $white;
        width: calc(100% - 40px);
        text-align: center;
        margin-bottom: 0;
        h3{
            margin-bottom: 0;
        }
        p{
            height: auto;
            max-height: 0;
            transition:all ease 0.3s;
            overflow: hidden;
            opacity: 0;
            margin-bottom: 0;
            font-size: 15px;
        }
    }
    &:hover{
        .info{
            p{
                max-height: 214px;
                opacity: 1;
            }
        }
        &:before{
            background: rgba($darkBlue, 0.75);
        }
    }
}