.category-single {

    h1 {
        @media screen and (max-width: 768px) {
            font-size: 30px;
        }
    }

    .page-intro {
        .breadcrumbs {
            display: none;
        }

        .content-container {
            margin-top: 90px;
        }

        @media(max-width: 1024px) {
            .image {
                top: 300px;
                right: -180px;

                .image-container {
                    width: 330px !important;
                    height: 330px !important;
                }
            }
        }
        @media screen and (max-width: 425px) {
            .image {
                top: 625px;
                right: -100px;
            }
        }
        @media screen and (max-width: 375px) {
            .image {
                top: 750px;
                right: -80px;
            }
            .image-container {
                width: 250px !important;
                height: 250px !important;
            }
        }
        @media(max-width: 320px) {
            .image {
                top: 772px;
                right: -93px;

                .image-container {
                    width: 270px !important;
                    height: 270px !important;
                }
            }
        }
    }

    .products--grid{
        &:after{
            display: none;
        }
    }

    div.text-block {
        h2 {
            font-family: $titleFont;
        }

        margin-bottom: 50px;

        p {
            font-weight: bold;
            color: $darkBlue;
        }

        position: relative;
        &:after {
            content:"";
            position: absolute;
            right:-100px;
            top: -400px;
            //background-image: url(/dod/images/particles-2.svg);
            background-repeat: no-repeat;
            background-position: 0 0;
            transform: rotate(-180deg);
            width: 400px;
            height: 800px;
            background-size: contain;
            opacity: 0.3;
        }
    }
}
