.resources{

    h3{
        background:$lightestBlue;
        border-bottom:1px solid $darkBlue;
        padding:10px;
        font-size:24px;
        //text-transform:uppercase;
    }

    .items{
        padding:0;
        li{
            margin:15px 0;
            list-style:none;
            .image-container{
                display: block;
                width: 100%;
                height: 200px;
            }
            img{
                width:auto;
                height:auto;
                max-height: 100%;
                max-width: 100%;
                margin:0 auto;
                display: block;
                margin-bottom:0!important;
                @media (max-width:600px){
                    height: auto;
                    width: 120px;
                    float: none;
                    margin:0 auto;
                    margin-bottom: 15px!important;
                }
            }
            h4{
                font-size:14px;
                color: $darkBlue;
                margin:10px 0;
            }
            .content{
                font-size:14px;
                line-height:1.4em;
                margin-bottom:10px;
            }
        }
    }

    .point-of-sale{
        .items{
            li{
                clear:both;
                padding-bottom:20px;
                //border-bottom:1px solid $borderGrey;
            }
            a.btn{
                width:100%;
            }
        }
    }

}
