.other-employees{
  position: relative;
}

.other-employee-slider{
  position: relative;
  margin-top: 40px;
  margin-bottom: 20px;
  .employee-thumbnail{
    height: 230px;
    width: 230px;
    background-position: -36px -37px;
    border-radius: 50%;
    background-size: 282px;
    background-repeat: no-repeat;
    margin-bottom: 15px;
    margin: 0 auto;
  }
  .employee-info{
    margin-top: 15px;
    font-weight: bold;
  }
  p{
    color: $grey;
  }
}

.other-employee{
  &.next-button,
  &.prev-button{
    position: absolute;
    cursor: pointer;
    i{
      color: $black;
    }
  }
  &.prev-button{
    top: 180px;
    left: -105px;
    @media screen and (max-width: 1024px){
      left: -5px;
    }
    @media screen and (max-width: 768px){
      left: -55px;
    }
    @media screen and (max-width: 480px){
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
    @media screen and (orientation: landscape){
      left: -25px;
    }
  }
  &.next-button{
    top: 180px;
    right: -105px;
    @media screen and (max-width: 1024px){
      right: -5px;
    }
    @media screen and (max-width: 768px){
      right: -55px;
    }
    @media screen and (max-width: 480px){
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
    @media screen and (orientation: landscape){
      right: -25px;
    }
  }
}

