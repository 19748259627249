.page-intro{
    background-color: $darkBlue;
    height: auto;
    //background-image: url('/dod/images/particles-1.svg');
    background-repeat: no-repeat;
    background-position: 100% 20%;
    margin-bottom: 100px;
    @media (max-width:769px){
        //padding-bottom: 300px;
        //margin-bottom: 200px;
    }
    .container{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .breadcrumbs{
        color: $white;
        li{
            a{
                color: $white;
            }
        }
    }
    .content{
        text-align: left;
        color: $white;
        padding-bottom: 50px;
    }
    .image{
        position: absolute;
        top: 150px;
        right: -100px;
        width: 450px;
        @media (max-width:769px){
            width: 350px;
            overflow: hidden;
            width: 350px;
            overflow: hidden;
            top: 450px;
            right: 0px;
            .image-container{
                width: 400px !important;
                height: 400px !important;
            }
        }
        .image-container{
            position: relative;
            border: 5px solid $gold;
            background-color: $gold;
            border-radius: 1234567px;
            overflow:hidden;
            width: 450px;
            height: 450px;
            &:after{
                content:"";
                padding-top:100%;
            }
            img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                height: 100%;
                width: auto;
                max-width: unset;
                display: block;
                z-index: 1;
                transition:z-index ease 0.2s;
            }
        }
    }
}
