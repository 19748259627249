/* Fonts */

@import url("https://p.typekit.net/p.css?s=1&k=ipa4njp&ht=tk&f=30023.30026&a=39581558&app=typekit&e=css");

@font-face {
    font-family:"agenda";
    src:url("https://use.typekit.net/af/2d6d3a/00000000000000003b9ae38e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff2"),url("https://use.typekit.net/af/2d6d3a/00000000000000003b9ae38e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff"),url("https://use.typekit.net/af/2d6d3a/00000000000000003b9ae38e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("opentype");
    font-display:swap;font-style:normal;font-weight:100;
}

@font-face {
    font-family:"agenda";
    src:url("https://use.typekit.net/af/2439e4/00000000000000003b9ae391/27/l?subset_id=2&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/2439e4/00000000000000003b9ae391/27/d?subset_id=2&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/2439e4/00000000000000003b9ae391/27/a?subset_id=2&fvd=n3&v=3") format("opentype");
    font-display:swap;font-style:normal;font-weight:300;
}

.tk-agenda { font-family: "agenda",sans-serif; }

@font-face {
    font-family: 'CaxtonTL-Bold';
    src: url('/dod/fonts/Caxton/CaxtonTL-Bold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CaxtonTL-BoldItalic';
    src: url('/dod/fonts/Caxton/CaxtonTL-BoldItalic.woff') format('woff');
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CaxtonTL-Book';
    src: url('/dod/fonts/Caxton/CaxtonTL-Book.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CaxtonTL-BookItalic';
    src: url('/dod/fonts/Caxton/CaxtonTL-BookItalic.woff') format('woff');
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AgendaBold';
    src: url('/dod/fonts/Agenda/Agenda-Bold.eot');
    src: url('/dod/fonts/Agenda/Agenda-Bold.eot?#iefix') format('embedded-opentype'),
    url('/dod/fonts/Agenda/Agenda-Bold.woff2') format('woff2'),
    url('/dod/fonts/Agenda/Agenda-Bold.woff') format('woff'),
    url('/dod/fonts/Agenda/Agenda-Bold.ttf') format('truetype'),
    url('/dod/fonts/Agenda/Agenda-Bold.svg#Agenda-Bold') format('svg');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GothamBold';
    src: url('/dod/fonts/Gotham/Gotham-BoldItalic.eot');
    src: url('/dod/fonts/Gotham/Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/dod/fonts/Gotham/Gotham-BoldItalic.woff2') format('woff2'),
    url('/dod/fonts/Gotham/Gotham-BoldItalic.woff') format('woff'),
    url('/dod/fonts/Gotham/Gotham-BoldItalic.ttf') format('truetype'),
    url('/dod/fonts/Gotham/Gotham-BoldItalic.svg#Gotham-BoldItalic') format('svg');
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GothamBold';
    src: url('/dod/fonts/Gotham/Gotham-Bold.eot');
    src: url('/dod/fonts/Gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('/dod/fonts/Gotham/Gotham-Bold.woff2') format('woff2'),
    url('/dod/fonts/Gotham/Gotham-Bold.woff') format('woff'),
    url('/dod/fonts/Gotham/Gotham-Bold.ttf') format('truetype'),
    url('/dod/fonts/Gotham/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-style: normal;
    font-display: swap;
}


$bodyFont: 'agenda', sans-serif;
$bodyFont-bold: 'AgendaBold', sans-serif;
$titleFont: 'GothamBold', sans-serif;
$caxtonFont: 'CaxtonTL-Book', sans-serif;


/* Theme Brand Colours */
$white: rgba(255,255,255,1);
$black: rgba(0,0,0,1);
$grey: rgba(50,50,50, 1);
$darkBlue: rgba(16, 20, 62, 1);
$lightBlue: rgba(112,114,139, 1);
$lighterBlue: rgba(207, 208, 216, 1);
$lightestBlue: rgba(246, 247, 255, 1);
$gold: rgba(217,189,69, 1);
$bodyCopy: rgba(87, 87, 86, 1);
$red: rgba(232, 39, 77, 1);
$green: rgba(107, 201, 191, 1);
/* Other brand colors */
$autarkyGrey: rgb(47, 73, 84);
$chudleysBlue: rgb(23, 36, 61);
$goldLineGold: rgb(216, 168, 9);
$gustoBlue: rgb(18, 176, 218);
$instagramSolid: #94489A;
$borderGrey: rgb(196,196,196);



$themeColours:(
    'white': $white,
    'black': $black,
    'darkBlue': $darkBlue,
    'lightBlue': $lightBlue,
    'ligherBlue': $lighterBlue,
    'lighestBlue': $lightestBlue,
    'gold': $gold,
    'bodyCopy': $bodyCopy
);


/* EM Convertor */
$browser-context: 16; // Default

/* REM to PX Convertor */
@function rem($pixels, $context: $browser-context) {
    @return #{$pixels/$context}rem;
}

/* Breakpoints */
$mobile-sm: 320px;
$mobile: 375px;
$mobile-lg: 500px;
$mobile-xl: 600px;
$tablet-sm: 767px;
$tablet: 768px;
$desktop-sm: 900px;
$bootstrap-md: 991px;
$desktop: 1024px;
$desktop-lg: 1200px;

/* Breakpoint mixin */
@mixin breakpoint($breakPointWidth) {
    @media screen and (max-width: $breakPointWidth) { @content; }
}
