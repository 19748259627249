.stockists-list{

    .form-container{
        margin-bottom: 30px;
        margin-top:-45px;
        .map-link{
            font-size: 16px;
            color: $darkBlue;
            margin-top:-20px;
            margin-bottom: 10px;
        }
        form{
            background:rgba($lightBlue, 0.1);
            padding: 15px;
            padding-bottom: 0;

            .waves-input-wrapper{
                max-width: 100%;
                overflow:initial;
            }

            input[type=text] {
                max-width: 100%;
                box-sizing: border-box;
                height: 50px;
                line-height: 50px;
                background:$white;
                padding-left: 15px;
                padding-right: 15px;
                border:0;
            }
            input[type=submit]{
                height: 50px;
                border-radius: 0 !important;
            }
            select {
                border: 1px solid $darkBlue;
            }
        }
    }

    .page-heading-alt{
        background-color: $lightestBlue;
        padding-bottom: 30px;
    }

    .tab-container{
        display: none;
        &.active{
            display: block;
        }
    }

    ul{
        &.letters-list{
            padding: 0;
            list-style-type: none;
            li{
                background-color: $white;
                text-align: center;
                box-shadow: #909090 0px 1px 5px 0;
                margin-bottom: 15px;
                padding-top:15px;
                padding-bottom: 15px;
                cursor: pointer;
                text-transform: uppercase;
                &.active{
                    position: relative;
                    background-color: $gold;
                    cursor:default;
                    &:after{
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        width: 0;
                        height: 0;
                        border-left: 30px solid transparent;
                        border-right: 30px solid transparent;
                        border-top: 30px solid $gold;
                        @media screen and (max-width: 480px){
                            display: none;
                        }
                    }
                    a{
                        color: $white;
                    }
                }
                a{
                    color: $darkBlue;
                    padding: 12px 0;
                    display: block;
                }
            }
        }
    }
    .stockist-accordion{
        margin-top: 35px;
        margin-bottom: 30px;
        .stockist-container{
            //margin-bottom: 15px;
            padding: 20px 0;
            position: relative;
            &:after{
                content:"";
                position: absolute;
                bottom:0;
                border-bottom: 2px solid $grey;
                width: calc(100% - 15px);
                left: 0px;
            }
            h5{
                margin-bottom: 0;
                font-family: $titleFont;
                font-size: 16px;
                cursor: pointer;
            }
            .accordion-expand{
                float: right;
                margin-right: 15px;
            }
            .stockist-content{
                &.open{
                    max-height: 100%;
                    overflow: auto;
                    margin-top: 20px;
                }
                &.closed{
                    max-height: 0;
                    overflow: hidden;
                }
            }
        }
    }
}
