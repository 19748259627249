.circle-image-header {
    position: relative;
    width: 100%;
    background-color: $darkBlue;
    ////background-image: url(/dod/images/particles-1.svg);
    background-repeat: no-repeat;
    background-position: right -65px;
    color: white;
    margin-bottom: 25px;

    h1 {
        text-transform: uppercase;
    }

    @media screen and (max-width: 1024px) {
        background-position: 633px 111px;
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 100px;
        background-position: 398px 218px;
    }
    @media screen and (max-width: 480px) {
        margin-bottom: 100px;
        background-position: 100px 257px;
    }
    @media screen and (max-width: 320px) {
        margin-bottom: 10px;
    }

    .breadcrumbs {
        margin: 0;
        padding-top:25px;

        li {
            &:after {
                color: white;
            }

            a {
                color: white;
            }
        }
    }

    .circle-hero-image {
        width: 500px;
        height: 500px;
        border: 5px solid $gold;
        border-radius: 50%;
        position: absolute;
        right: -625px;
        bottom: -250px;
        background-repeat: no-repeat;
        background-size: cover;
        @media screen and (max-width: 1024px) {
            width: 300px;
            height: 300px;
            right: -250px;
            bottom: -150px;
        }
        @media screen and (max-width: 768px) {
            width: 350px;
            height: 350px;
            top: initial;
            right: -175px;
            bottom: -175px;
        }
        @media screen and (max-width: 480px) {
            width: 250px;
            height: 250px;
            right: -50px;
            top: initial;
            bottom: -127px;
        }
        @media screen and (max-width: 320px) {
            width: 200px;
            height: 200px;
            right: -40px;
            bottom: -103px;
        }
    }

    .circle-header-wrapper {
        position: relative;
        padding-bottom: 15px;
        @media screen and (max-width: 768px) {
            padding-bottom: 140px;
        }
    }
}
