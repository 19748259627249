.products--grid{
    position: relative;
    margin-top: 30px;
    &:after {
        content:"";
        position: absolute;
        right:-100px;
        top: 50%;
        //background-image: url(/dod/images/particles-2.svg);
        background-repeat: no-repeat;
        background-position: 0 0;
        transform: rotate(-180deg) translateY(50%);
        width: 400px;
        height: 800px;
        background-size: contain;
    }
}
