.what-goes-in-page{
    .page-heading-alt{
        background-color: $lightestBlue;
        padding-bottom: 50px;
    }

    .item-row{
        border-bottom: 1px solid $grey;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        p{
            margin-bottom: 0;
        }
        img{
            width: 123px;
            height: 123px;
        }
    }


    .references-text{
        font-size: 14px;
        p{
            font-size: inherit;
        }
    }
}