.products-landing-page{
    padding-bottom: 50px;
    h2.page-title{
        margin-bottom: 50px;
    }
    .image-container{
        display: block;
        border:5px solid $gold;
        border-radius: 50%;
        padding-bottom: 100%;
        position: relative;
        overflow: hidden;
        margin-bottom: 50px;
        transition:all ease 0.2s;
        background-size: cover;
        background-color: transparent;
        background-blend-mode: multiply;
        background-position: center center;
        box-shadow: 12px 12px 10px 0px rgba(0,0,0,0.1);

        h2{
            position: absolute;
            top:50%;
            left: 0;
            width: 100%;
            text-align: center;
            transform: translateY(-50%);
            color: $white;
            opacity: 0;
            transition:all ease 0.2s;
        }

        &.hover-red{
            border-color: $red;
            &:hover{
                background-color: $red;
            }
        }
        &.hover-green{
            border-color: $green;
            &:hover{
                background-color: $green;
            }
        }
        &:hover{
            transform: scale(1.2);
            z-index: 2;
            h2{
                opacity: 1;
            }
        }
        @media (max-width:769px){
            margin-top: 50px;
            margin-bottom: 15px;
            &:hover{
                transform: scale(1);
            }
            h2{
                display: none;
            }
        }
    }
    //background-image: url('/dod/images/particles-2.svg');
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 200px;
    &:after{
        content: "";
        width: 500px;
        height: 500px;
        //background-image: url(/dod/images/particles-2.svg);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        right: -100px;
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
