.stockists {
    > .container {
        margin: 50px auto;
        margin-bottom: 50px;
    }

    .form-group {
        margin-bottom: 0;
    }

    .town-input {
        background-image: url('/dod/images/map-crosshair.svg') !important;
        background-repeat: no-repeat !important;
        background-size: 17px 25px !important;
        padding-left: 50px !important;
        background-position: 15px center !important;
        height: 50px !important;
        line-height: 50px !important;
        max-width: 100% !important;

        &.searching {
            background-image: url('/dod/images/location-searching.gif') !important;
            background-size: 25px 17px !important;
        }
    }

    .intro-section {
        h2 {
            text-align: center;
            color: $darkBlue;
        }
    }

    .content {

    }

    .search-group {
        position: relative;

        #getCurrentLocation {
            position: absolute;
            top: 0;
            left: 0;
            width: 50px;
            height: 50px;
            background: $lightBlue;
            opacity: 0;
            border: 0;
            cursor: pointer;
            z-index: 20;
            color: transparent;
            padding: 0;

            &:hover {
                opacity: 0.2;
            }
        }

        input.town-input {
            width: 90%;
            display: inline-block;
            border: 1px solid $darkBlue;
        }

        .input-group-append {
            width: 10%;
            display: inline-block;

            .btn {
                padding: 0.95rem 0;
            }
        }
    }

    .form-wrap {
        //background:rgba($lightBlue, 0.1);
        padding-top: 15px;
        padding-bottom: 15px;

        input {
            max-width: 100%;
            box-sizing: border-box;
            height: 45px;
            line-height: 50px;
            border: 1px solid $darkBlue;
        }

        select {
            border: 1px solid $darkBlue;
        }
    }

    .map-wrap {
        position: relative;
        .loading-block{
            display: none;
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            background:rgba($white, 0.5);

            #loading-circle {
                display: block;
                width: 80px;
                height: 80px;
                text-align: center;
                position: absolute;
                top:50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
            #loading-circle:after {
                content: " ";
                display: block;
                width: 64px;
                height: 64px;
                margin: 8px;
                border-radius: 50%;
                border: 6px solid #fff;
                border-color: $darkBlue transparent $darkBlue transparent;
                animation: lds-dual-ring 1.2s linear infinite;
            }
            @keyframes lds-dual-ring {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }


        }

        @media (max-width: 900px) {
            display: flex;
            flex-direction: column-reverse;
        }

        &.open {
            .stockist-list {
                width: 400px;
                overflow: scroll;
                //transition:width 0.5s 0.5s;
            }

            .map {
                width: calc(100% - 400px);
                //transition:width 0.5s 0.5s;
            }
        }

        .stockist-list {
            height: 625px;
            width: 0;
            overflow: hidden;
            float: left;
            //transition: width 0.5s;

            .results-heading {
                font-size: 14px;
                padding: 0 20px;
            }

            .show-all {
                display: none;
                font-size: 14px;
                padding:8px;
                width: 100%;
            }

            h3 {
                font-size: 18px;
                text-align: center;
                border-bottom: 1px solic $darkBlue;
                padding-bottom: 10px;
                margin-bottom: 10px;
            }

            ul {
                padding: 0;
                margin: 0;
                list-style: none;

                li {
                    padding: 15px 20px;
                    font-size: 16px;
                    cursor: pointer;
                    .other-details{
                        padding-left: 35px;
                    }
                    .address{
                        min-height: 55px;
                    }

                    &:hover {
                        background: rgba($lightBlue, 0.05);
                    }

                    &.active {
                        background: rgba($lightBlue, 0.2);

                        .address {
                            //height:auto;
                        }
                    }

                    h4 {
                        font-size: 16px;
                        font-weight: bold;
                        color: $darkBlue;
                    }

                    i {
                        color: $white;
                        background: url('/dod/images/map-marker-stockists.svg');
                        width: 24px;
                        height: 55px;
                        display: inline-block;
                        background-size: contain;
                        background-repeat: no-repeat;
                        text-align: center;
                        padding-top: 3px;
                        font-style: normal;
                        font-weight: 900;
                        font-family: "Hind", sans-serif;
                        margin-right: 10px;
                        float: left;
                    }

                    .address {
                        height: 0;
                        max-height: 400px;
                        overflow: hidden;
                        transition: height 0.5s;
                        height: 1.4em;
                    }
                }
            }
        }

        .map {
            height: 625px;
            //transition: width 0.5s;
        }

        @media (max-width: 900px) {

            &.open {
                .stockist-list {
                    height: 300px;
                    width: 100%;

                    li {
                        width: 50%;
                        float: left;
                    }
                }

                .map {
                    width: 100%;
                }
            }
            .stockist-list {
                width: 100%;
                height: 0;
            }

        }

        @media (max-width: 576px) {
            &.open {
                .stockist-list {
                    height: 600px;
                    width: 100%;

                    li {
                        width: 100%;
                        float: left;
                    }
                }
            }
        }
    }

    #markerContent {
        background: $darkBlue;
        color: $white;
        min-width: 400px;
        font-size: 16px;
        padding: 20px;

        p {
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 0;
        }

        #firstHeading {
            font-size: 18px;
        }

        a {
            color: $white;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .gm-style-iw.gm-style-iw-c {
        padding: 0 !important;
        border-radius: 0 !important;
    }

    .gm-style .gm-style-iw-d {
        box-sizing: border-box;
        overflow: auto !important;
        border-radius: 0 !important;
    }

    button.gm-ui-hover-effect:before {
        content: '×';
        color: white;
        font-size: 25px;
        position: relative;
        top: 5px;
        right: 3px;
    }

    button.gm-ui-hover-effect img {
        display: none;
    }

    .gm-style .gm-style-iw-t::after {
        background: linear-gradient(45deg, rgba(16, 20, 62, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
    }

    .results-search {
        .content {
            font-size: 14px;
        }

        #countryName {
            color: $darkBlue;
            font-weight: bold;
        }

        select{
            margin-top:5px;
            padding:8px;
            padding-right: 25px;
            font-size: 16px;
            max-width: 100% !important;
            width: 100%;
            border:1px solid $darkBlue;
        }

        .search-group {
            .town-input {
                padding-left: 50px !important;
                background-position: 15px center !important;
                height: 25px !important;
                line-height: 25px !important;
                max-width: 100% !important;
                width: auto;
            }

            .input-group-append {
                width: 13%;

                .btn {
                    padding: 13px 0;
                    margin: 0;
                    margin-top: 3px;
                }
            }
        }
    }
}
