$timeline: t1, t2, t3, t4, t5, t6, t7, t8, t9, t10, t11, t12;
$base: 80;
$baseTabLandcsape: 73;
$basepx: $base * 1px;



@each $item in $timeline{
  $i: index($timeline, $item);
  .bubble-t#{$i}{
    left: $i * $basepx;
    @media screen and (max-width: 1024px){
      left: $i * $baseTabLandcsape+px;
    }
  }
}

.about-us-timeline{
  //background-image: url(/dod/images/particles-2.svg);
  background-repeat: no-repeat;
  background-position: -359px;
  background-color: $darkBlue;
  padding: 50px 0px 35px 0px;

  h3{
    &.timeline-heading{
      text-transform: uppercase;
    }
  }
}

.timeline-about-us {
  position: relative;
  margin-top: 300px;
  margin-bottom: 345px;
  @media screen and (max-width: 768px){
    margin-bottom: 120px;
    margin-top: 75px;
  }
  &:before{
    content: '';
    display: block;
    height: 100%;
    background-color: $white;
  }

  .main-line {
    width: 100%;
    height: 3px;
    background: $white;

    &:before,
    &:after {
      position: absolute;
      content: '';
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $white;
      top: -6px;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }
  .bubble{
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 2px solid $white;
    text-align: center;
    .bubble-content{
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      width: 100%;
      padding: 10px 2px 0 7px;
      h4,
      p{
        color: $gold;
      }
      h4{
        font-size: 1.5rem;
      }
      p{
        /**
            TODO
         */
        font-size: 0.75rem;
        //font-size: 0.6rem;
      }
    }
    .bubble-icon{
      position: absolute;
      width: 68px;
    }
    &.bubble-top,
    &.bubble-bottom{
      &:before,
      &:after{
        content: '';
        display: block;
        position: absolute;
        background-color: #ffffff;
      }
      &:before{
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
    &.bubble-top {
      top: -241px;
      &:before,
      &:after{
        content: '';
        display: block;
        position: absolute;
        background-color: #ffffff;
      }
      .bubble-icon{
         bottom: -102px;
         left: 99px;
       }
      &:before{
        bottom: -99px;
        left: 69px;
      }
      &:after{
        width: 2px;
        height: 96px;
        bottom: -96px;
        left: 50%;
      }
      &:nth-of-type(4n + 3){
        top: -200px;
        &:before{
          bottom: -59px;
        }
        &:after{
          bottom: -55px;
          height: 55px;
        }
        .bubble-icon{
          bottom: -60px;
          left: 127px;
        }
      }
    }


    &.bubble-bottom {
      bottom: -200px;
      .bubble-icon{
        right: -58px;
        top: -45px;
      }
      &:before{
        top: -58px;
        left: 69px;
      }
      &:after{
        width: 2px;
        height: 55px;
        top: -55px;
        left: 50%;
      }
      &:nth-of-type(4n + 2){
        bottom: -241px;
        .bubble-icon{
          right: -15px;
          top: -87px;
        }
        &:before{
          left: 69px;
          top: -99px;
        }
        &:after{
          top: -96px;
          height: 96px;
        }
      }
    }
  }
}

.mobile-timeline{
  .vertical-line{
    width: 3px;
    height: 100%;
    background-color: $white;
  }
  .bubble,
  .bubble-content{
    position: relative;
  }
  .bubble{
    height: 250px;
    width: 250px;
    margin-bottom: 15px;
    transform: translate(0);
    .bubble-icon{
      bottom: -84px;
      left: -47px;
    }
    &:before,
    &:after{
      content: '';
      display: block;
      background: white;
      position: absolute;
    }
    &:before{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      left: -95px;
      top: 120px;
      @media screen and (max-width: 480px){
        left: -72px;
      }
    }
    &:after{
      width: 92px;
      height: 3px;
      top: 50%;
      left: -92px;
      @media screen and (max-width: 480px){
        width: 68px;
        left: -69px;
      }
    }
    &:nth-child(even){
      margin-left: 170px;
      .bubble-icon{
        bottom: -83px;
        right: 0;
        left: initial;
        @media screen and (max-width: 320px){
          right: unset;
          left: -35px;
        }
      }
      @media screen and (max-width: 480px){
        margin-left: 70px;
      }
      @media screen and (max-width: 320px){
        margin-left: -5px;
      }
      &:before{
        left: -265px;
        @media screen and (max-width: 480px){
          left: -142px;
        }
        @media screen and (max-width: 320px){
          left: -53px;
        }
      }
      &:after{
        width: 264px;
        left: -264px;
        @media screen and (max-width: 480px){
          width: 138px;
          left: -139px;
        }
        @media screen and (max-width: 320px){
          width: 50px;
          left: -50px;
        }
      }
    }
    .bubble-content{
      padding: 35px 10px 0 10px;
      p{
        font-size: 1.2rem;
      }
    }
  }
}
