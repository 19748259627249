.page-heading-alt {
    margin-bottom: 50px;
    .featured-image {
        height: 340px;
        background-size: cover !important;
        background: no-repeat center center;
    }
    .additional-image {
        width: 565px;
        height: 525px;
        position: absolute;
        right: -260px;
        top: 165px;
        background: no-repeat center center;
        background-size: contain !important;
    }
    .page-title {

    }
    .page-content {

    }
}