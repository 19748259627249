.newsletter-signup-form{
    .relative{
        position: relative;
        .featured-image{
            position: absolute;
            bottom:-20px;
            right: 0;
            width: 45%;
            @media screen and (max-width: 768px){
                width: 100%;
                position: static;
            }
        }
    }

    ul.subjects{
        list-style-type: none;
        padding-left: 0;
        margin:0 !important;
        li{
            padding-top:10px;
            padding-bottom: 10px;
            label{
                display: block;
                width: 100%;
            }
            input, span{
                display: inline-block;
                vertical-align: middle;
            }
            input{
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
            span{
                font-weight: normal !important;
            }
        }
    }
}
