.factsheet-generic-page{
  .page-heading-alt{
    margin-bottom: 0;
    .page-title{
      h2{
        margin-bottom: 0;
      }
    }
  }
  main{
    h3,
    h4{
      font-family: $titleFont;
      color: $gold;
      text-transform: uppercase;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    ul{
      padding-left: 15px;
    }

    .contact-box{
      padding: 1.5rem;
      background-color: $darkBlue;
      ////background-image: url(/dod/images/particles-1.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: -175px -125px;
      .contact-box-title{
        p{
          font-family: $titleFont;
          color: $white;
          font-size: 21px;
        }
      }
      .contact-box-details{
        display: flex;
        align-items: center;
        .icon{
          width: 50px;
          margin-right: .5rem;
        }
        .detail{
          margin-bottom: 1rem;
          .detail-title,
          .detail-main{
            font-family: $titleFont;
          }
          p{
            &.detail-title{
              margin: 0;
              color: $white;
            }
          }
          .detail-main{
            color: $gold;
            font-size: 22px;
          }
        }
      }
    }

    .side-images{
      margin-top: 2rem;
    }
  }

  .related-products{
    position: relative;
    margin-top: 80px;
    background-color: $lightestBlue;
    padding: 2rem 0;
    ////background-image: url(/dod/images/particles-4.svg);
    background-repeat: no-repeat;
    background-position: right -466px;
    background-size: contain;
    h2{
      color: $darkBlue;
    }
    .related-products-slider{
      .slick-list{
        display: flex;
        .slick-track{
          display: flex;
          .related-product-slide{
            display: flex;
            align-items: center;
            .products--link-block{
              display: flex;
              flex-direction: column;
              height: 100%;
              .image-container{
                margin-top: auto;
              }
            }
          }
        }
      }
    }
    i{
      &.prev,
      &.next{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 28px;
        color: $darkBlue;
        cursor: pointer;
      }
      &.prev{
        left: 215px;
      }
      &.next{
        right: 215px;
      }
    }
  }
}
