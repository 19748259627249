.page-heading {
    height: 350px;
    background: $darkBlue;
    border-bottom: 5px solid $red;
    color: $white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    position: relative;

    &.border-green {
        border-bottom: 5px solid $green;
    }

    &:before {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        background: rgba($darkBlue, 0.5);
        width: calc(100% + 5px);
        height: calc(100% + 5px);
        z-index: 0;
    }

    @media screen and (max-width: 768px) {
        padding-top:10px;
        padding-bottom:10px;
        height: auto;
        h1{
            font-size: 30px;
        }
    }
}
