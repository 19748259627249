.home-page {
    overflow-x: hidden;

    .light-page-heading p {
        font-weight: 600;
    }

    h2 {
        font-weight: 900;
    }

    .image-with-text {
        //background-image: url('/dod/images/particles-2.svg');
        background-repeat: no-repeat;
        background-position: 0 0;
        padding-bottom: 100px;
    }

    .advice-center {
        position: relative;
        color: $white;
        font-weight: 400;
        word-break: break-word;

        a {
            color: $white;
            text-decoration: underline;
        }

        strong {
            font-family: $titleFont;
        }

        select {
            width: 100%;
            background: $darkBlue;
            color: $white;
            padding: 10px;
            font-family: $titleFont;
            font-weight: 900;
            max-width: 250px;
            border: 2px solid $gold;
            @media screen and (max-width: 768px) {
                max-width: unset;
            }
        }

        h2 {
            font-weight: 900;
        }

        img {
            width: 100%;
            border: 10px solid #cfd0d8;
        }

        .right-content-image {
            width: 100%;
            //height: 360px;
            border: 10px solid #cfd0d8;
            //background-repeat: no-repeat;
            //background-size: cover;
            //background-position: center;
        }
    }

    .advice-center {
        padding-bottom: 150px;
        //margin-bottom: 150px;
    }

    .values {
        @media screen and (max-width: 768px) {
            transform: translateY(-30px);
        }
    }

    .feed-room-blocks {
        //transform: translateY(-50%);
        margin-top: -150px;

        @media screen and (max-width: 768px) {
            margin-top: 0;
            transform: translateY(-120px);
        }

        .feed-room-block {
            padding: 20px 10px;
            background: $white;
            color: $darkBlue;
            text-align: center;
            display: flex;
            flex-direction: column;
            //transform: translateY(-50%);
            margin-top: 30px;

            h4 {
                font-weight: 900;
                font-family: $titleFont;
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 20px;
                font-weight: 400;
            }

            .btn {
                display: inline-block;
                width: auto;
                margin-top: 10px;
            }

            border: 10px solid #cfd0d8;
        }
    }

    .logo-slider {
        margin: 0 auto;
        justify-content: center;
        gap: 2rem;
        .single-logo {
            img {
                height: 100px;
                width: auto;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }

    .light-page-heading {
        height: auto;
        //padding-top: 50px;
        padding-bottom: 50px;
        background: $white;
    }

    .categories-row {
        .category-button {
            background: $white;
            cursor: pointer;
            transition: all ease 0.25s;

            &:hover, &.active {
                color: $white;
                background: $lightBlue;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            }
        }
    }

    .slick-arrow {
        background: transparent;
        border: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        font-size: 28px;
        cursor: pointer;

        &:before {
            color: $darkBlue;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 300;
        }

        &.slick-prev {
            z-index: 4;

            &:before {
                content: '\f053';
            }
        }

        &.slick-next {
            z-index: 4;

            &:before {
                content: '\f054';
            }

            right: 0;
            left: auto;
        }
    }

    .products-slider {
        .products--link-block {
            margin-bottom: 0;

            img {
                margin: 0 auto !important;
                @media screen and (max-width: 768px) {
                    max-width: 200px;
                    height: auto;
                }
            }

            .btn {
                border: 2px solid $darkBlue;
                color: $darkBlue !important;
                font-size: 16px;
                font-weight: bold;
            }
        }

        .slick-dots {
            li {
                margin-left: 5px;
                margin-right: 5px;
                width: 10px;
                height: 10px;

                &:after {
                    display: none;
                }

                button {
                    background: transparent;
                    border: 1px solid $darkBlue;
                    width: 10px;
                    height: 10px;
                }

                &.slick-active {
                    button {
                        background: $darkBlue;
                    }
                }
            }
        }
    }

    .has-blue-box {
        position: relative;
        margin-bottom: 100px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: $lightestBlue;
            height: 65%;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 10%;
            width: 80%;
            background: transparent;
            pointer-events: none;
            height: 105%;
            z-index: 1;
            border: 10px solid $lightestBlue;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }

        .container {
            z-index: 2;
            position: relative;
        }
    }

    .products-button {
        .btn {
            display: block;
            margin: 0 auto;
            margin-top: 80px;
            padding: 10px;
            padding-left: 15px;
            padding-right: 15px;
            width: 300px;
            font-size: 18px;
            background: transparent;
            border: 2px solid $darkBlue;
            color: $darkBlue !important;

            &:hover {
                color: $white !important;
                background-color: $darkBlue;
            }
        }
    }

    .pl-0 {
        padding-left: 0;
        @media screen and (max-width: 768px) {
            padding-left: 15px !important;
        }
    }

    .factsheet-block {
        border: 3px solid transparent;
        padding-bottom: 50px !important;
        position: relative;

        &:hover {
            border: 3px solid $gold;
            background: $darkBlue !important;

            .text-darkBlue {
                color: $white !important;
            }
        }

        .btn {
            position: absolute;
            bottom: 15px;
            left: 34%;
            width: 62% !important;
            @media screen and (max-width: 768px) {
                width: calc(100% - 30px) !important;
                left: 15px;
            }
        }
    }


    #loading-circle {
        display: block;
        margin: 0 auto;
        width: 80px;
        height: 80px;
        margin-bottom: 20px;
        margin-top: 20px;
        text-align: center;
    }

    #loading-circle:before {
        content: "Loading products...";
        text-align: center;
        font-size: 14px;
    }

    #loading-circle:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #fff;
        border-color: $darkBlue transparent $darkBlue transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .blocks-row {
        @media(max-width: 992px) {
            h4 {
                font-size: 16px;
            }

            .btn {
                font-size: 14px;
            }
        }
    }
}

.has-blue-border {
    border: 10px solid $lighterBlue;
}
