.nutritional-helpline-page{

    .advisors-section{
        position: relative;
        &:after{
            content: "";
            width: 420px;
            height: 590px;
            //background-image: url(/dod/images/particles-4.svg);
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            bottom: -200px;
            left: 0;
            z-index: 1;
        }
    }
}
