.hero-slider {
    position: relative;
    h1{
        font-size: 36px;
    }
    .slick-dots{
        position: absolute;
        left: 50px;
        top: 50%;
        -ms-transform: translateX(-50%);
        transform: translateY(-50%);
        padding-left: 0;
        margin-left: 0;
        list-style-type: none;
        @media (max-width:769px){
            left: 15px;
        }
        li{
            overflow: visible;
            height: 15px;
            width: 15px;
            display: block;
            min-height: unset;
            margin-top: 25px;
            margin-bottom: 25px;
            position: relative;
            &:after{
                content: "";
                position: absolute;
                top: 26px;
                left: 7px;
                height: 27px;
                width: 2px;
                background: $white;
            }
            &:last-of-type{
                &:after{
                    display: none;
                }
            }
            button{
                overflow: visible;
                height: 15px;
                width: 15px;
                display: inline-block;
                border-radius: 15px;
                background: transparent;
                font-size: 1px;
                color: transparent;
                border: 0;
                padding:0;
                border: 2px solid $white;
                cursor: pointer;
                &:hover{
                    background: $white;
                }
            }
            &.slick-active{
                button{
                    background: $white;
                }
            }
        }
    }



    .hero-section {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        color: $white;
        position: relative;
        padding-top: 100px;
        padding-bottom: 100px;
        &:before {
            content: "";
            position: absolute;
            top: -2px;
            left: -2px;
            background: rgba($darkBlue, 0.75);
            width: calc(100% + 5px);
            height: calc(100% + 5px);
            z-index: 0;
        }
        .content {
            min-height: 300px;
            z-index: 1;
            @media (max-width:769px){
                h1{
                    font-size: 28px;
                }
            }
        }
    }
}

