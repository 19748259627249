/**
	Color Variables.
	You are encouraged to import these into your theme for consistency.
 */

$black: rgb(0,0,0);
$white: rgb(255,255,255);

// Social Media Colors
$facebook: rgb(59,89,152);
$twitter: rgb(0,172,237);
$google: rgb(221,75,57);
$pinterest: rgb(203,32,39);
$linkedin: rgb(0,123,182);
$youtube: rgb(187,0,0);
$vimeo: rgb(170,212,80);
$tumblr: rgb(50,80,109);
$instagram: rgb(81,127,164);
$instagramGradient: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
$flickr: rgb(255,0,132);
$dribble: rgb(234,76,137);
$quora: rgb(168,36,0);
$foursquare: rgb(0,114,177);
$rss: rgb(255,153,0);
$wordpress: rgb(22,117,155);
$stumbleupon: rgb(235,72,35);
$yahoo: rgb(123,0,153);
$blogger: rgb(251,143,61);
$vine: rgb(0,191,143);
$github: rgb(51,51,51);
$tripAdvisor: rgb(0,176,135);


$socialColors:
	facebook $facebook,
	facebook-f $facebook,
	twitter $twitter,
	google $google,
	google-plus-g $google,
	pinterest $pinterest,
	linkedin $linkedin,
	linkedin-in $linkedin,
	youtube $youtube,
	vimeo $vimeo,
	tumblr $tumblr,
	instagram $instagram,
	intsagramGradient $instagramGradient,
	flickr $flickr,
	dribble $dribble,
	quora $quora,
	foursquare $foursquare,
	rss $rss,
	wordpress $wordpress,
	stumbleupon $stumbleupon,
	yahoo $yahoo,
	blogger $blogger,
	vine $vine,
	github $github,
	tripAdvisor $tripAdvisor
;