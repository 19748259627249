.our-team-page {
    .page-heading-alt {
        font-weight: bold;
        color: $darkBlue;
        background-color: $lightestBlue;
        margin-bottom: 0;
        padding-bottom: 30px;
        @media screen and (max-width: 768px){
            .featured-image {
                height: 200px;
            }
        }
        @media screen and (max-width: 500px){
            .featured-image {
                height: 130px;
            }
        }
        .page-content {
            padding-top: 30px;

            p {
                font-size: 21px;
            }
        }
    }

    .team-title {
        border-bottom: 1px solid $darkBlue;
        color: $darkBlue;
        padding-bottom: 5px;
        font-weight: 900;
        margin-bottom: 10px;
    }

    .team-list{
        margin-top: 50px;
    }

    .member-block {
        background-size: cover;
        background-position: center center;
        width: 100%;
        position: relative;

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        .inner{
            position: absolute;
            top:0;
            left: 0;
            background: rgba($darkBlue, 0.75);
            width: 100%;
            height: 100%;
            color: $white;
            opacity: 0;
            pointer-events: none;
            transition: all ease-in-out 0.2s;
            .content{
                position: absolute;
                top:50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                text-align: center;
                max-width: 100%;
                strong{
                    font-weight: 900;
                    font-size: 21px;
                }
                a{
                    color: $white;
                    text-decoration: none;
                    font-weight: normal;
                    &:hover{
                        text-decoration: underline;
                    }
                }
                p{
                    font-size: 18px;
                    margin-bottom: 0;
                }
            }
        }

        &:hover{
            .inner{
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}
