.search-results {
    .search-result {
        margin-top: 20px;
        margin-bottom: 10px;
        padding-bottom: 30px;
        h3 {
            font-size: 24px;
            font-weight: bold;
            color: $darkBlue;
            a{
                color: $darkBlue;
                font-weight: 900;
            }
        }
        .badge{
            box-shadow: none;
            background: $lightBlue;
            padding:5px 10px;
            font-weight: 900;
            border-radius: 0;
            margin-bottom: 5px;
            text-transform: uppercase;
        }

        a {
            color: $gold;
            font-weight: normal;
            text-decoration: underline;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.search-page {
    .page-title{
        font-size: 35px;
        margin-bottom: 20px;
    }
    .search-container {
        background: $lightestBlue;
        padding: 20px;
        .form-group input:not([type="submit"]){
            padding:10px;
            max-width: 100%;
        }
    }
    .filter-search{
        padding:20px;
        padding-left: 0 !important;
        label{
            color: $darkBlue;
            font-weight: bold;
        }
        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border:1px solid $darkBlue;
            padding:5px 10px;
            background-color:#fff;
            width: 100%;
            height: 50px;
            background-image:url('/dod/images/down-arrow.svg');
            background-position: calc(100% - 15px) 54%;
            background-size: auto 16%;
            background-repeat: no-repeat;
            color: $darkBlue;
            border-radius: 0;
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                background-position: 190% 65%;
            }
            &:active,
            &:focus{
                border-color:$darkBlue !important;
                box-shadow:none !important;
                outline:none;
            }
            &.has-error{
                border-color:#F00;
            }
        }
    }
}
