.interests{
    background: $darkBlue;
    padding-top: 0px;
    padding-bottom: 75px;
    @media (max-width:769px){
        display: none;
    }
    h2.top-header{
        width: 100%;
        text-align: center;
        transform: translateY(-60px);
    }
    .container {
        position: relative;
    }
    .item{
        background: $white;
        text-align: center;
    }
    /* the slides */
    .slick-slide {
        margin: 0 10px;
        outline: none !important;
        background: $darkBlue;
        h3.title{
            color: $white;
            text-align: center;
            font-size: 18px;
        }
        .image{
            background-size: cover;
            border: 3px solid $darkBlue;
            width: 100%;
            &:after{
                content: "";
                display: block;
                padding-bottom: 100%;
            }
            &:hover{
                border-color: $gold;
            }
        }
    }
    /* the parent */
    .slick-list {
        margin: 0 -10px;
    }

    .interests-button {
        position: absolute;
        top: 58%;
        left: 0;
        transform: translateY(-50%) translateX(-130%);
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        background-color: transparent;
        color: $gold;
        opacity: 0.8;
        cursor: pointer;
        border:2px solid $gold;
        &.interests-next{
            left: auto;
            right: 0;
            transform: translateY(-50%) translateX(130%);
        }
        &:hover{
            opacity: 1;
        }
    }
}