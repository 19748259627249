.factsheets-listing-page{
    h3.toggle-title{
        color: $darkBlue;
        font-size: 30px;
        text-transform: uppercase;
        font-family: $titleFont;
        width: 100%;
        border-bottom: 1px solid #979797;
        position: relative;
        cursor: pointer;
        &:after{
            content:"-";
            position: absolute;
            top:50%;
            right:0;
            font-size: 20px;
            transform: translateY(-50%);
        }
        &.collapsed{
            &:after{
                content:"+";
            }
        }
    }
}