.login-box{
  margin-top: 30px;
  background-color: $white;
  border-radius: 7px;
  .login-box-intro{
    padding-top: 15px;
    margin-bottom: 15px;
    h4{
      margin-bottom: 0;
    }
    .register{
      color: $grey;
      text-decoration: underline;
    }
    a{
      display: block;
    }
  }
  .label-holder{
    background-color: $lightestBlue;
    color: $darkBlue;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding: 15px 0 15px 10px;
    label{
      margin-bottom: 0;
    }
  }

  .input-holder{
    padding-right: 0.8rem;
    input{
      padding: .5rem 0 .6rem;
      padding-left: 0.8rem;
      border: none;
    }
    input[type="password"]{
      border-bottom: 1px solid #e5e5e5;
    }
  }
  form{
    margin-bottom: 30px;
  }
  .login-box-footer{
    padding-top: 38px;
    padding-bottom: 25px;
    background-color: $lightestBlue;
    border-radius: 7px;
    .sign-in-btn{
      padding: 7px 0px;
      font-weight: lighter;
    }
    .forgot-credentials{
      font-size: 15px;
      color: $grey;
      margin-top: 30px;
      display: block;
      text-decoration: underline;
    }
  }
}

