@import '../../../resources/default/scss/includes/colors';

@import 'includes/variables.scss';
@import 'includes/elements.scss';

.content iframe {
    max-width: 100% !important;
}

/**
    Frameworks
 */

@import 'framework/aos';
@import 'framework/slick';
@import 'framework/plyr';

/**
    Global Components
 */

@import 'partials/global/header';
@import 'partials/global/footer';
@import 'partials/global/image-with-text';

@import 'partials/pages/home/header';
@import 'partials/pages/home/hero-slider';
@import 'partials/pages/home/interests-slider';
@import 'partials/global/page-heading';
@import 'partials/global/link-block';
@import 'partials/global/page-intro';
@import 'partials/global/blue-info-block';
@import 'partials/global/get-in-touch-form';
@import 'partials/global/page-title';
@import 'partials/global/view-online-bar';
@import 'partials/global/social-blocks';
@import 'partials/global/events';
@import 'partials/global/page-heading-alt';
@import 'partials/global/testimonials-slider';
@import 'partials/global/google-translate';
@import 'partials/global/see-other-news';
@import 'partials/global/employees-list';
@import 'partials/global/circle-image-header';
@import 'partials/global/video-with-text';
@import 'partials/global/other-employees-slider';
@import 'partials/global/light-page-heading';

@import 'partials/products/link-block';
@import 'partials/products/grid';
@import 'partials/pages/canine-product-overview/canine-product-links';
@import 'partials/pages/about-us/timeline';
@import 'partials/pages/about-us/brand-values';
@import 'partials/pages/about-us/charity-slider';
@import 'partials/pages/about-us/sponsorship-slider';
@import 'partials/pages/trade-login/trade-login-box';
@import 'views/pages/update-profile';
@import 'partials/news/news-card';
@import 'partials/news/news-card-first';
@import 'partials/careers/careers-card';
@import 'partials/careers/careers-form';

@import 'views/stockists/listing';
//@import 'views/ambassadors-categories/single';
/**
    Partials
 */


/**
    Pages
 */

@import 'views/404';
@import 'views/pages/home';
@import 'views/pages/update-profile';
@import 'views/pages/search';
@import 'views/templates/products-landing';
@import 'views/templates/equine-products';
@import 'views/templates/canine-product-overview';
@import 'views/templates/what-goes-in';
@import 'views/templates/trade-login';
@import 'views/templates/code-of-conduct';
@import 'views/templates/standard-tc-of-sale';
@import 'views/templates/tc-of-purchase';
@import 'views/templates/security';
@import 'views/templates/terms-conditions';
@import 'views/templates/privacy-policy';
@import 'views/templates/trade-area';
@import 'views/templates/team-members-page';
@import 'views/templates/nutritional-helpline';
@import 'views/templates/stockist-list';
@import 'views/templates/newsletter-signup';
@import 'views/news/listing';
@import 'views/news/single';
@import 'views/careers/listing';
@import 'views/careers/single';
@import 'views/faqs/listing';
@import 'views/category/single';
@import 'views/factsheet/listing';
@import 'views/factsheet/generic-template';
@import 'views/pages/our-team';
@import 'views/pages/landing-form';

@import 'views/ambassadors/listing';
@import 'views/ambassadors/single';
@import 'views/ambassadors/filter';
@import 'views/templates/nutrition-advice-centre';
@import 'views/templates/about-us';
@import 'views/templates/contact-us';

@import 'views/products/single';
@import 'views/employees/single';

@import 'views/stockists/listing';
@import 'views/ambassadors-categories/single';

@import 'views/resources/listing';

