.news-card-first {
    margin-bottom: 50px;
    a{
        &.img-holder{
            @media screen and (max-width: 768px){
                display: block;
                height: 365px;
            }
        }
    }
    .news-card-first-content {
        padding: 30px;
        float: left;
        a.btn {
            width: 165px;
            padding: 7px;
            margin-bottom: 20px;
        }
        .news-card-first-date {
            display: block;
        }
        .news-card-first-excerpt, .news-card-first-date, h3 {
            margin-bottom: 20px;
        }
    }
    .news-card-first-thumbnail {
        float: left;
        background: no-repeat center center;
        background-size: cover;
        height: 100%;
    }
}