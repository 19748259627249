html {
    overflow-x: hidden;
}

* {
    outline: none !important;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

body {
    font-family: $bodyFont;
    font-size: 18px;
    overflow-x: hidden;
    color: $bodyCopy;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.page-title {
    color: $darkBlue;
    text-transform: uppercase;

    h1 {
        @media screen and (max-width: 768px) {
            font-size: 30px;
        }
    }
}

.content iframe {
    max-width: 100% !important;
}

.title-font {
    font-family: $titleFont;
}

#mainContent {

}

.breadcrumbs {
    li {
        display: none !important;

        a {
            color: $darkBlue;
        }
    }
}

/* Titles */

h1, h2, h3, h4, h5 {
    font-family: $titleFont;
}

h1 {
    font-size: 45px;
    text-transform: uppercase;
}

h2 {
    font-size: 30px;
    text-transform: uppercase;
}

h2 {
    &.text-block {
        font-family: $bodyFont;
    }
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 21px;
}

h3, h4, h5 {
    font-family: $bodyFont;
}

h3 {
    &.subtitle {
        font-size: 24px;
        font-family: $bodyFont-bold;
    }
}

p {
    font-family: $bodyFont;
    font-size: 19px;
}

.bold {
    font-weight: 900;
}

/* Content */

.content {
    h1, h2 {
        color: $darkBlue;
    }

    ul {
        list-style: none;
        padding-left: 20px;

        li {
            position: relative;
            padding-left: 0.5em;

            &:before {
                content: "\2022 \00A0";
                color: $darkBlue;
                margin-right: 0.5em;
                position: absolute;
                top: 0px;
                left: -5px;
            }
        }
    }

    ol {
        list-style: none;
        counter-reset: li;

        li {
            counter-increment: li;

            &:before {
                content: counter(li) ". ";
                color: $gold;
                margin-left: -1.5em;
                margin-right: 0.5em;
                text-align: right;
                direction: rtl;
            }
        }
    }

    //strong{
    //    color: $darkBlue;
    //}
    a {
        color: $darkBlue;
        text-decoration: underline;
        font-weight: bold;
    }

    table {
        width: 100%;
        margin: 20px 0;

        td {
            vertical-align: middle;
        }

        th {
            font-weight: 600;
            color: $gold;
            vertical-align: middle;
        }

        td {
            width: 40px;
            color: $darkBlue;
            font-weight: bold;
            padding-right: 40px;
        }

        td {
            width: 70px;
            text-align: center;
            padding: 3px;
        }

        tr:nth-child(odd) {
            td, th {
                background: rgba($gold, 0.1);
            }
        }
    }
}

.table-wrapper {
    overflow-x: scroll;
    @include breakpoint(390px) {
        border-right: solid 15px $white;
    }
}

/* Buttons */

.btn, a.btn {
    width: 262px;
    //max-width: 85%;
    background: $gold;
    border: 1px solid $gold;
    color: $white;
    font-size: 12px;
    border-radius: 0 !important;
    margin: 0;
    font-family: $titleFont;
    font-weight: 900;
    letter-spacing: 2px;
    text-decoration: none;
    box-shadow: 0 0 0 0 #000;

    &:hover {
        background: lighten($gold, 10);
        box-shadow: 2px 2px 6px 0 #ADAFAF;
    }

    &.btn-secondary {
        border-color: $darkBlue !important;
        background: $darkBlue !important;
        color: $white !important;

        &:hover {
            background: $lightBlue !important;
        }
    }

    &.btn-gold-transparent {
        background: transparent !important;
        color: $gold;
    }

    &.block {
        display: block;

        &.center {
            margin: 0 auto;
        }
    }

    &.center-mobile {
        @media (max-width: 769px) {
            display: block;
            margin: 0 auto;
        }
    }

    &.light-button {
        background-color: rgba(246, 247, 255, 1);
        border-color: rgba(246, 247, 255, 1);
        color: $darkBlue !important;
    }

    &.pdf-button {
        border: 1px solid $darkBlue;
        color: $darkBlue !important;
        background: $white;
    }
}

/* Forms */

form {
    .waves-input-wrapper {
        max-width: 100%;
    }

    .btn, a.btn {
        max-width: calc(100% - 22px);
    }


    .form-group {
        width: 100%;

        .form-control {
            margin-top: 0;
        }

        label {
            color: $darkBlue;
            font-family: $titleFont;
            font-size: 14px;
            margin-bottom: 0;
        }

        input:not([type="submit"]) {
            border: 1px solid $white;
            color: $darkBlue;
            padding: 5px 10px;
            width: 100%;
            background: $white;
            max-width: calc(100% - 20px);

            &:active,
            &:focus {
                background: $white;
                border-color: $darkBlue !important;
                box-shadow: none !important;
                outline: none;
            }

            &.has-error {
                border-color: #F00;
            }
        }

        textarea {
            max-width: 100%;
            border: 1px solid $white;
            color: $darkBlue;
            padding: 5px 10px;
            width: 100%;
            background: $white;
            font-family: $bodyFont;
            resize: none;

            &:active,
            &:focus {
                background: $white;
                border-color: $darkBlue !important;
                box-shadow: none !important;
                outline: none;
            }

            &.has-error {
                border-color: #F00;
            }
        }

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 1px solid $white;
            padding: 5px 10px;
            background-color: #fff;
            width: 100%;
            height: 50px;
            background-image: url('/dod/images/down-arrow.svg');
            background-position: calc(100% - 15px) 54%;
            background-size: auto 16%;
            background-repeat: no-repeat;
            color: $darkBlue;
            border-radius: 0;
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                background-position: 190% 65%;
            }

            &:active,
            &:focus {
                border-color: $darkBlue !important;
                box-shadow: none !important;
                outline: none;
            }

            &.has-error {
                border-color: #F00;
            }
        }
    }
}

/* Colours */
@each $name, $value in $themeColours {

    .background-#{$name} {
        background-color: $value !important;
    }
    .text-#{$name} {
        color: $value !important;
    }
}


.slick-dots {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 0;
    margin-left: 0;
    list-style-type: none;
    width: 100%;
    text-align: center;

    li {
        overflow: visible;
        height: 15px;
        width: 15px;
        display: inline-block;
        min-height: unset;
        margin-left: 15px;
        margin-right: 15px;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 18px;
            left: 15px;
            width: 30px;
            height: 2px;
            background: $gold;
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }

        button {
            overflow: visible;
            height: 15px;
            width: 15px;
            display: inline-block;
            border-radius: 15px;
            background: transparent;
            font-size: 1px;
            color: transparent;
            border: 0;
            padding: 0;
            border: 2px solid $gold;
            cursor: pointer;

            &:hover {
                background: $gold;
            }
        }

        &.slick-active {
            button {
                background: $gold;
            }
        }
    }
}

/* Pagination */
//TODO

ul.pagination {
    width: 100%;
    justify-content: center;

    li {
        text-align: center;
        margin: 0 12px;

        a.page-link {
            width: 30px;
            height: 30px;
            border-radius: 50% !important;
            background-color: #D8D8D8 !important;
            color: $darkBlue !important;
        }

        a[aria-label*="Previous"],
        a[aria-label*="Next"] {
            background-color: transparent !important;
            color: $darkBlue !important;
        }

        a[aria-label],
        span[aria-hidden] {
            font-size: 2.5rem !important;
            line-height: 21px;
        }

        &.active {
            span {
                width: 30px;
                height: 30px;
                border-radius: 50% !important;
                background-color: #D8D8D8 !important;
                color: $darkBlue !important;
            }
        }
    }
}

.h-90 {
    height: 90%;
}

.bg-lightestBlue {
    background: $lightestBlue;
}

.uppercase {
    text-transform: uppercase;
}

.bg-darkBlue {
    background: $darkBlue;
}

.bg-gold {
    background: $gold;
}

.bg-white {
    background: $white;
}

.pl-0 {
    padding-left: 0 !important;
}

.custom-radio {
    opacity: 0;
    position: absolute;
}

.custom-radio, .custom-radio-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.custom-radio-label {
    position: relative;
}

.custom-radio + .custom-radio-label:before {
    content: '';
    background: #fff;
    border: 1px solid $darkBlue;
    display: inline-block;
    vertical-align: middle;
    line-height: 20px;
    width: 25px;
    height: 25px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
}

.checkbox-custom-label:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    background: $darkBlue;
    color: #fff;
}

.custom-radio + .custom-radio-label:before {

}

.custom-radio:checked + .custom-radio-label:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    color: $darkBlue;

}

.custom-radio:focus + .custom-radio-label {
    outline: 1px solid #ddd; /* focus style */
}


.helpline-button {
    p {
        font-size: 16px;
        margin-bottom: 0;
        margin-top: 5px;
    }
}

.gold-bottom {
    border-bottom: 5px solid $gold;
}

.p-no-margin {
    p:last-of-type {
        margin-bottom: 0 !important;
    }
}

.title-font {
    font-family: $titleFont;
}

.small-p {
    font-size: 16px;

    p {
        font-size: 16px;
    }
}

.small-button-text {
    font-size: 9px;
}

.btn.btn-gold-ghost {
    border: 2px solid $gold;
    width: 100%;
    background: $white;
    color: $gold !important;

    &:hover {
        color: $white !important;
    }
}

.smaller-btn {
    padding: 7px;
}

.white-border {
    box-sizing: border-box;
    border: 1px solid $white;
}


.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

input:disabled {
    opacity: 0.3;
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}

.pb-0 {
    padding-bottom: 0 !important;
}

.cookie-bar-default,
.cookie-preferences-edit {
    display: none;
}
