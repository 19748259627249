.ambassadors-single {
    margin-bottom: 60px;
    h2{
        color: $darkBlue;
    }
    .info{
        color:$darkBlue;
        background-color:$lightestBlue;
        padding:20px;
        max-width:80%;
        margin:10px 0 20px;
        p{
            margin:0;
            span{
                font-family: $bodyFont-bold;
            }
        }
    }
    .content{
        margin-bottom:50px;
    }
    aside{
        img{
            width:100%;
            height:auto;
            margin-bottom:30px !important;
        }
        .quote{
            margin-top:30px;
            background:$lighterBlue;
            color:$darkBlue;
            text-align:center;
            position:relative;
            padding:40px;
            p:last-child {
                margin-bottom: 0;
            }
            &:before,
            &:after{
                display:block;
                text-align:left;
                font-size:80px;
                position:absolute;
                line-height:20px;
            }
            &:before{
                left: 10px;
                content:'“';
                top:40px;
            }
            &:after{
                right: 10px;
                content:'”';
                bottom:-5px;
            }
        }
    }
    .products-loop {
        background:$lightestBlue;
        h2{
            font-family: "GothamBlack", sans-serif;
            font-weight: 300;
            text-transform: uppercase;
            margin-bottom: 45px;
            margin-top:30px;
            padding-top:30px;
        }
        .products--link-block{
            .image-container{
                text-align:center;
                img{
                    margin:0 auto;
                }
            }
        }
    }

}