#google_translate_element {
    position: static;
    bottom: calc(53px + 16px);
    right: 16px!important;
    display: inline-block;
}
.goog-te-gadget {
    font-family: sans-serif!important;
    text-transform: uppercase;
}
.goog-te-gadget-simple  {
    background: transparent;
    padding: 8px!important;
    display: inline-block;
    cursor: pointer;
    zoom: 1;
    border:0;
    font-size: 14px;
    font-family: $titleFont;
    font-weight: 800;
}
.goog-te-menu2 {
    max-width: 100%;
}
.goog-te-menu-value {
    color: #fff !important;
    &:before {
        display: none;
        font-family: 'Material Icons';
        content: "\E927";
        margin-right: 16px;
        font-size: 2rem;
        vertical-align: -10px;
        // width:32px!important;
    }
}
.goog-te-menu-value span:nth-child(5) {
    display:none;
}
.goog-te-menu-value span:nth-child(3) {
    border:none!important;
    font-family: 'Material Icons';
    &:after {
        display: none;
        font-family: 'Material Icons';
        content: "\E5C5";
        font-size: 1.5rem;
        vertical-align: -6px;
    }
}

.goog-te-gadget-icon {
    background-image: url(https://placehold.it/32)!important;
    background-position: 0px 0px;
    height: 32px!important;
    width: 32px!important;
    margin-right: 8px!important;
    //     OR
    display: none;
}

// ============ HIDE TOP BAR ============
.goog-te-banner-frame.skiptranslate {display: none!important;}
body {top: 0px!important;}

/* ================================== *\
    Mediaqueries
\* ================================== */
@media (max-width: 667px) {
    #google_translate_element {
        bottom: calc(100% - 50% - 53px);
        left: 16px!important;
        width: 100%!important;
        goog-te-gadget {
            width:100%!important;
        }
        .skiptranslate {
            width:100%!important;
        }
        .goog-te-gadget-simple {
            width: calc(100% - 32px)!important;
            text-align: center;
        }
    }
}



