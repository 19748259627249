.other-news{
  position: relative;
  background-color: $lightestBlue;
  padding: 40px 0px;
  #see-other-news-slider{
    margin-top: 3rem;
  }
  .news-card{
    margin: 0 15px 0 0;
      @media screen and (max-width: 480px){
        margin-right: 0;
      }
  }
  .see-other-news-article{
    width: 362px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .news-card-content{
    a{
      color: $white;
    }
  }
  .more-news-prev-button,
  .more-news-next-button{
    position: absolute;
    top: 50%;
    transform: translate(-50%);
    @media screen and (max-width: 480px){
      top: 495px;
      background: $darkBlue;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      text-align: center;
      color: white;
    }
    @media screen and (max-width: 320px){
      bottom: 12px;
      top: unset;
    }
  }
  .more-news-prev-button{
    left: 320px;
    @media screen and (max-width: 768px){
      left: 45px;
    }
    @media screen and (max-width: 480px){
      left: 165px;
    }
    @media screen and (max-width: 375px){
      left: 150px;
    }
    @media screen and (max-width: 320px){
      left: 110px;
    }
  }
  .more-news-next-button{
    right: 320px;
    @media screen and (max-width: 768px){
      right: 45px;
    }
    @media screen and (max-width: 480px){
      right: 165px;
    }
    @media screen and (max-width: 375px){
      right: 150px;
    }
    @media screen and (max-width: 320px){
      right: 110px;
    }
  }
  h2{
    text-transform: uppercase;
  }
}