.events-section-wrapper {
    background: $lightestBlue;
    padding-bottom: 50px;
    margin-bottom: 80px;
    .container {
        position: relative;
    }
    .events-section {
        h2 {
            margin-bottom: 0;
            padding: 30px 0;
        }
        text-align:  center;
        .events-slider {
            .event-slide {
                img {
                    margin-bottom: 0 !important;
                    margin: 0 auto;
                    height: 200px;
                }
            }
        }
        .events-slider-button {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) translateX(-130%);
            width: 30px;
            height: 30px;
            border-radius: 30px;
            text-align: center;
            line-height: 28px;
            font-size: 14px;
            background-color: transparent;
            color: $lightBlue;
            opacity: 0.8;
            cursor: pointer;
            &.events-slider-button-prev{
                @media(max-width: 480px){
                    bottom: -30px;
                    top: unset;
                    left: 170px;
                    font-size: 20px;
                }
            }
            &.events-slider-button-next{
                left: auto;
                right: 0;
                transform: translateY(-50%) translateX(130%);
                @media(max-width: 480px){
                    bottom: -30px;
                    top: unset;
                    right: 170px;
                    font-size: 20px;
                }
            }
            &:hover{
                opacity: 1;
            }
        }
    }
}