.social-blocks {
    .social-button {
        display: block;
        width: 100%;
        padding: 10px 10px;
        color: $white;
        text-align: center;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: bold;
        i {
            margin-right: 10px;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            background: $white;
            font-size: 12px;
            line-height: 24px;
        }
        &.social-button-twitter {
            background: $twitter;
            i {
                color: $twitter;
            }
            &:hover {
                background: lighten($twitter, 10%)
            }
        }
        &.social-button-instagram {
            background: linear-gradient(#6759A6 0%, #94489A 33%, #A03A8F 50%, #F7AA54 90%);
            i {
                color: $instagramSolid;
            }
            &:hover {
                background: linear-gradient(lighten(#6759A6, 10%) 0%, lighten(#94489A, 10%) 33%, lighten(#A03A8F, 10%) 50%, lighten(#F7AA54, 10%) 90%)
            }
        }
        &.social-button-facebook {
            background: $facebook;
            i {
                color: $facebook;
            }
            &:hover {
                background: lighten($facebook, 10%)
            }
        }
        &.social-button-linkedin {
            background: $linkedin;
            i {
                color: $linkedin;
            }
            &:hover {
                background: lighten($linkedin, 10%)
            }
        }
    }
}

.not-compatible{
    margin: 0;
}

.twitter-feed{
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: none;
    }
}

