.brand-values{
  position: relative;
  background-color: $white;
  padding: 50px 0px 65px 0px;
  .particle-image,
  .particle-image-2{
    position: absolute;
    //background-image: url(/dod/images/particles-2.svg);
    height: 415px;
  }
  .particle-image{
    background-position: -37px 0px;
    bottom: 0;
    width: 228px;
  }
  .particle-image-2{
    right: -26px;
    width: 266px;
    transform: rotate(180deg);
  }
  h2{
    &.page-title{
      margin-bottom: 50px;
    }
  }
  .brand-value-tile{
    width: 100%;
    padding: 75px 45px 75px 45px;
    h2,
    p{
      color: $white;
      position:relative;
      z-index: 3;
    }
    p{
      font-size: 16px;
    }
    i{
      font-size: 15rem;
    }
    img{
      height: 273px;
      @media screen and (max-width: 768px){
        height: auto;
      }
    }
    .no-pad{
      padding: 0;
    }
    &.pioneering{
      background-color: $darkBlue;
    }
    &.experts{
      background: $green;
    }
    &.trusted{
      background: $red;
    }
    &.passionate{
      background: $gold;
    }
  }
  .content-wrapper{
    padding: 0;
    display: flex;
  }
}
