.news-card {
    margin-bottom: 30px;
    .news-card-date {
        display: block;
        margin-bottom: 10px;
    }
    .news-card-content {
        background: $darkBlue;
        margin-bottom: 10px;
        padding: 15px;
        a {
            font-weight: bold;
        }
    }
    .news-card-thumbnail {
        min-height: 200px;
        width: 100%;
        background: no-repeat center center;
        background-size: cover;
    }
}