.ambassadors-listing {
    .ambassador-categories {
        margin-bottom: 50px;
        .ambassador-category {

        }
    }
    .link-block{
        background-size: 515px;
    }
}