@charset "UTF-8";
/**
	Color Variables.
	You are encouraged to import these into your theme for consistency.
 */
/* Fonts */
@import url("https://p.typekit.net/p.css?s=1&k=ipa4njp&ht=tk&f=30023.30026&a=39581558&app=typekit&e=css");
@font-face {
  font-family: "agenda";
  src: url("https://use.typekit.net/af/2d6d3a/00000000000000003b9ae38e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff2"), url("https://use.typekit.net/af/2d6d3a/00000000000000003b9ae38e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff"), url("https://use.typekit.net/af/2d6d3a/00000000000000003b9ae38e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "agenda";
  src: url("https://use.typekit.net/af/2439e4/00000000000000003b9ae391/27/l?subset_id=2&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/2439e4/00000000000000003b9ae391/27/d?subset_id=2&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/2439e4/00000000000000003b9ae391/27/a?subset_id=2&fvd=n3&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

.tk-agenda {
  font-family: "agenda",sans-serif;
}

@font-face {
  font-family: 'CaxtonTL-Bold';
  src: url("/dod/fonts/Caxton/CaxtonTL-Bold.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CaxtonTL-BoldItalic';
  src: url("/dod/fonts/Caxton/CaxtonTL-BoldItalic.woff") format("woff");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CaxtonTL-Book';
  src: url("/dod/fonts/Caxton/CaxtonTL-Book.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CaxtonTL-BookItalic';
  src: url("/dod/fonts/Caxton/CaxtonTL-BookItalic.woff") format("woff");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AgendaBold';
  src: url("/dod/fonts/Agenda/Agenda-Bold.eot");
  src: url("/dod/fonts/Agenda/Agenda-Bold.eot?#iefix") format("embedded-opentype"), url("/dod/fonts/Agenda/Agenda-Bold.woff2") format("woff2"), url("/dod/fonts/Agenda/Agenda-Bold.woff") format("woff"), url("/dod/fonts/Agenda/Agenda-Bold.ttf") format("truetype"), url("/dod/fonts/Agenda/Agenda-Bold.svg#Agenda-Bold") format("svg");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GothamBold';
  src: url("/dod/fonts/Gotham/Gotham-BoldItalic.eot");
  src: url("/dod/fonts/Gotham/Gotham-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/dod/fonts/Gotham/Gotham-BoldItalic.woff2") format("woff2"), url("/dod/fonts/Gotham/Gotham-BoldItalic.woff") format("woff"), url("/dod/fonts/Gotham/Gotham-BoldItalic.ttf") format("truetype"), url("/dod/fonts/Gotham/Gotham-BoldItalic.svg#Gotham-BoldItalic") format("svg");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GothamBold';
  src: url("/dod/fonts/Gotham/Gotham-Bold.eot");
  src: url("/dod/fonts/Gotham/Gotham-Bold.eot?#iefix") format("embedded-opentype"), url("/dod/fonts/Gotham/Gotham-Bold.woff2") format("woff2"), url("/dod/fonts/Gotham/Gotham-Bold.woff") format("woff"), url("/dod/fonts/Gotham/Gotham-Bold.ttf") format("truetype"), url("/dod/fonts/Gotham/Gotham-Bold.svg#Gotham-Bold") format("svg");
  font-style: normal;
  font-display: swap;
}

/* Theme Brand Colours */
/* Other brand colors */
/* EM Convertor */
/* REM to PX Convertor */
/* Breakpoints */
/* Breakpoint mixin */
html {
  overflow-x: hidden;
}

* {
  outline: none !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

body {
  font-family: "agenda", sans-serif;
  font-size: 18px;
  overflow-x: hidden;
  color: #575756;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-title {
  color: #10143e;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .page-title h1 {
    font-size: 30px;
  }
}

.content iframe {
  max-width: 100% !important;
}

.title-font {
  font-family: "GothamBold", sans-serif;
}

.breadcrumbs li {
  display: none !important;
}

.breadcrumbs li a {
  color: #10143e;
}

/* Titles */
h1, h2, h3, h4, h5 {
  font-family: "GothamBold", sans-serif;
}

h1 {
  font-size: 45px;
  text-transform: uppercase;
}

h2 {
  font-size: 30px;
  text-transform: uppercase;
}

h2.text-block {
  font-family: "agenda", sans-serif;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 21px;
}

h3, h4, h5 {
  font-family: "agenda", sans-serif;
}

h3.subtitle {
  font-size: 24px;
  font-family: "AgendaBold", sans-serif;
}

p {
  font-family: "agenda", sans-serif;
  font-size: 19px;
}

.bold {
  font-weight: 900;
}

/* Content */
.content h1, .content h2 {
  color: #10143e;
}

.content ul {
  list-style: none;
  padding-left: 20px;
}

.content ul li {
  position: relative;
  padding-left: 0.5em;
}

.content ul li:before {
  content: "\2022 \00A0";
  color: #10143e;
  margin-right: 0.5em;
  position: absolute;
  top: 0px;
  left: -5px;
}

.content ol {
  list-style: none;
  counter-reset: li;
}

.content ol li {
  counter-increment: li;
}

.content ol li:before {
  content: counter(li) ". ";
  color: #d9bd45;
  margin-left: -1.5em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
}

.content a {
  color: #10143e;
  text-decoration: underline;
  font-weight: bold;
}

.content table {
  width: 100%;
  margin: 20px 0;
}

.content table td {
  vertical-align: middle;
}

.content table th {
  font-weight: 600;
  color: #d9bd45;
  vertical-align: middle;
}

.content table td {
  width: 40px;
  color: #10143e;
  font-weight: bold;
  padding-right: 40px;
}

.content table td {
  width: 70px;
  text-align: center;
  padding: 3px;
}

.content table tr:nth-child(odd) td, .content table tr:nth-child(odd) th {
  background: rgba(217, 189, 69, 0.1);
}

.table-wrapper {
  overflow-x: scroll;
}

@media screen and (max-width: 390px) {
  .table-wrapper {
    border-right: solid 15px white;
  }
}

/* Buttons */
.btn, a.btn {
  width: 262px;
  background: #d9bd45;
  border: 1px solid #d9bd45;
  color: white;
  font-size: 12px;
  border-radius: 0 !important;
  margin: 0;
  font-family: "GothamBold", sans-serif;
  font-weight: 900;
  letter-spacing: 2px;
  text-decoration: none;
  box-shadow: 0 0 0 0 #000;
}

.btn:hover, a.btn:hover {
  background: #e2cc6f;
  box-shadow: 2px 2px 6px 0 #ADAFAF;
}

.btn.btn-secondary, a.btn.btn-secondary {
  border-color: #10143e !important;
  background: #10143e !important;
  color: white !important;
}

.btn.btn-secondary:hover, a.btn.btn-secondary:hover {
  background: #70728b !important;
}

.btn.btn-gold-transparent, a.btn.btn-gold-transparent {
  background: transparent !important;
  color: #d9bd45;
}

.btn.block, a.btn.block {
  display: block;
}

.btn.block.center, a.btn.block.center {
  margin: 0 auto;
}

@media (max-width: 769px) {
  .btn.center-mobile, a.btn.center-mobile {
    display: block;
    margin: 0 auto;
  }
}

.btn.light-button, a.btn.light-button {
  background-color: #f6f7ff;
  border-color: #f6f7ff;
  color: #10143e !important;
}

.btn.pdf-button, a.btn.pdf-button {
  border: 1px solid #10143e;
  color: #10143e !important;
  background: white;
}

/* Forms */
form .waves-input-wrapper {
  max-width: 100%;
}

form .btn, form a.btn {
  max-width: calc(100% - 22px);
}

form .form-group {
  width: 100%;
}

form .form-group .form-control {
  margin-top: 0;
}

form .form-group label {
  color: #10143e;
  font-family: "GothamBold", sans-serif;
  font-size: 14px;
  margin-bottom: 0;
}

form .form-group input:not([type="submit"]) {
  border: 1px solid white;
  color: #10143e;
  padding: 5px 10px;
  width: 100%;
  background: white;
  max-width: calc(100% - 20px);
}

form .form-group input:not([type="submit"]):active, form .form-group input:not([type="submit"]):focus {
  background: white;
  border-color: #10143e !important;
  box-shadow: none !important;
  outline: none;
}

form .form-group input:not([type="submit"]).has-error {
  border-color: #F00;
}

form .form-group textarea {
  max-width: 100%;
  border: 1px solid white;
  color: #10143e;
  padding: 5px 10px;
  width: 100%;
  background: white;
  font-family: "agenda", sans-serif;
  resize: none;
}

form .form-group textarea:active, form .form-group textarea:focus {
  background: white;
  border-color: #10143e !important;
  box-shadow: none !important;
  outline: none;
}

form .form-group textarea.has-error {
  border-color: #F00;
}

form .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid white;
  padding: 5px 10px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  background-image: url("/dod/images/down-arrow.svg");
  background-position: calc(100% - 15px) 54%;
  background-size: auto 16%;
  background-repeat: no-repeat;
  color: #10143e;
  border-radius: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  form .form-group select {
    background-position: 190% 65%;
  }
}

form .form-group select:active, form .form-group select:focus {
  border-color: #10143e !important;
  box-shadow: none !important;
  outline: none;
}

form .form-group select.has-error {
  border-color: #F00;
}

/* Colours */
.background-white {
  background-color: white !important;
}

.text-white {
  color: white !important;
}

.background-black {
  background-color: black !important;
}

.text-black {
  color: black !important;
}

.background-darkBlue {
  background-color: #10143e !important;
}

.text-darkBlue {
  color: #10143e !important;
}

.background-lightBlue {
  background-color: #70728b !important;
}

.text-lightBlue {
  color: #70728b !important;
}

.background-ligherBlue {
  background-color: #cfd0d8 !important;
}

.text-ligherBlue {
  color: #cfd0d8 !important;
}

.background-lighestBlue {
  background-color: #f6f7ff !important;
}

.text-lighestBlue {
  color: #f6f7ff !important;
}

.background-gold {
  background-color: #d9bd45 !important;
}

.text-gold {
  color: #d9bd45 !important;
}

.background-bodyCopy {
  background-color: #575756 !important;
}

.text-bodyCopy {
  color: #575756 !important;
}

.slick-dots {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  padding-left: 0;
  margin-left: 0;
  list-style-type: none;
  width: 100%;
  text-align: center;
}

.slick-dots li {
  overflow: visible;
  height: 15px;
  width: 15px;
  display: inline-block;
  min-height: unset;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}

.slick-dots li:after {
  content: "";
  position: absolute;
  top: 18px;
  left: 15px;
  width: 30px;
  height: 2px;
  background: #d9bd45;
}

.slick-dots li:last-of-type:after {
  display: none;
}

.slick-dots li button {
  overflow: visible;
  height: 15px;
  width: 15px;
  display: inline-block;
  border-radius: 15px;
  background: transparent;
  font-size: 1px;
  color: transparent;
  border: 0;
  padding: 0;
  border: 2px solid #d9bd45;
  cursor: pointer;
}

.slick-dots li button:hover {
  background: #d9bd45;
}

.slick-dots li.slick-active button {
  background: #d9bd45;
}

/* Pagination */
ul.pagination {
  width: 100%;
  justify-content: center;
}

ul.pagination li {
  text-align: center;
  margin: 0 12px;
}

ul.pagination li a.page-link {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  background-color: #D8D8D8 !important;
  color: #10143e !important;
}

ul.pagination li a[aria-label*="Previous"],
ul.pagination li a[aria-label*="Next"] {
  background-color: transparent !important;
  color: #10143e !important;
}

ul.pagination li a[aria-label],
ul.pagination li span[aria-hidden] {
  font-size: 2.5rem !important;
  line-height: 21px;
}

ul.pagination li.active span {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  background-color: #D8D8D8 !important;
  color: #10143e !important;
}

.h-90 {
  height: 90%;
}

.bg-lightestBlue {
  background: #f6f7ff;
}

.uppercase {
  text-transform: uppercase;
}

.bg-darkBlue {
  background: #10143e;
}

.bg-gold {
  background: #d9bd45;
}

.bg-white {
  background: white;
}

.pl-0 {
  padding-left: 0 !important;
}

.custom-radio {
  opacity: 0;
  position: absolute;
}

.custom-radio, .custom-radio-label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

.custom-radio-label {
  position: relative;
}

.custom-radio + .custom-radio-label:before {
  content: '';
  background: #fff;
  border: 1px solid #10143e;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  width: 25px;
  height: 25px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
}

.checkbox-custom-label:before {
  content: "\f00c";
  font-family: 'FontAwesome';
  background: #10143e;
  color: #fff;
}

.custom-radio:checked + .custom-radio-label:before {
  content: "\f00c";
  font-family: 'FontAwesome';
  color: #10143e;
}

.custom-radio:focus + .custom-radio-label {
  outline: 1px solid #ddd;
  /* focus style */
}

.helpline-button p {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 5px;
}

.gold-bottom {
  border-bottom: 5px solid #d9bd45;
}

.p-no-margin p:last-of-type {
  margin-bottom: 0 !important;
}

.title-font {
  font-family: "GothamBold", sans-serif;
}

.small-p {
  font-size: 16px;
}

.small-p p {
  font-size: 16px;
}

.small-button-text {
  font-size: 9px;
}

.btn.btn-gold-ghost {
  border: 2px solid #d9bd45;
  width: 100%;
  background: white;
  color: #d9bd45 !important;
}

.btn.btn-gold-ghost:hover {
  color: white !important;
}

.smaller-btn {
  padding: 7px;
}

.white-border {
  box-sizing: border-box;
  border: 1px solid white;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

input:disabled {
  opacity: 0.3;
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

.pb-0 {
  padding-bottom: 0 !important;
}

.cookie-bar-default,
.cookie-preferences-edit {
  display: none;
}

.content iframe {
  max-width: 100% !important;
}

/**
    Frameworks
 */
[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: .5;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow .3s ease;
}

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit;
}

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  transition: transform .4s ease-in-out;
  width: 100%;
}

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap;
}

.plyr__captions .plyr__caption div {
  display: inline;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
}

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  transition: all .3s ease;
}

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px;
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0;
}

a.plyr__control {
  text-decoration: none;
}

a.plyr__control::after, a.plyr__control::before {
  display: none;
}

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none;
}

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #1aafff;
  color: #fff;
}

.plyr--video .plyr__control svg {
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #1aafff;
  color: #fff;
}

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #1aafff;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.plyr__controls .plyr__menu, .plyr__controls .plyr__progress, .plyr__controls .plyr__time, .plyr__controls .plyr__volume, .plyr__controls > .plyr__control {
  margin-left: 5px;
}

.plyr__controls .plyr__menu + .plyr__control, .plyr__controls .plyr__progress + .plyr__control, .plyr__controls > .plyr__control + .plyr__control, .plyr__controls > .plyr__control + .plyr__menu {
  margin-left: 2px;
}

.plyr__controls > .plyr__control:first-child, .plyr__controls > .plyr__control:first-child + [data-plyr=pause] {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__controls .plyr__menu, .plyr__controls .plyr__progress, .plyr__controls .plyr__time, .plyr__controls .plyr__volume, .plyr__controls > .plyr__control {
    margin-left: 10px;
  }
}

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px;
}

.plyr--video .plyr__controls {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  z-index: 3;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px;
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none;
}

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block;
}

.plyr__video-embed {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.plyr__video-embed iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform .3s ease;
}

.plyr__menu .plyr__control[aria-expanded=true] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}

.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0;
}

.plyr__menu__container [role=menu] {
  padding: 7px;
}

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px;
}

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%;
}

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
}

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px;
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px);
}

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px;
}

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor;
}

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px;
}

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  transition: all .3s ease;
  width: 16px;
}

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform .3s ease,opacity .3s ease;
  width: 6px;
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #1aafff;
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1);
}

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none;
}

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 28px;
  color: #1aafff;
  display: block;
  height: 20px;
  margin: 0;
  padding: 0;
  transition: box-shadow .3s ease;
  width: 100%;
}

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2px;
  height: 4px;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0));
}

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 14px;
  position: relative;
  transition: all .2s ease;
  width: 14px;
  -webkit-appearance: none;
  margin-top: -5px;
}

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2px;
  height: 4px;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 14px;
  position: relative;
  transition: all .2s ease;
  width: 14px;
}

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2px;
  height: 4px;
}

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2px;
  height: 4px;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent;
}

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2px;
  height: 4px;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2px;
  height: 4px;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor;
}

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 14px;
  position: relative;
  transition: all .2s ease;
  width: 14px;
  margin-top: 0;
}

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type=range]:focus {
  outline: 0;
}

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0;
}

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr__time {
  font-size: 14px;
}

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px;
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  white-space: nowrap;
  z-index: 2;
}

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0, 0) scale(1);
}

.plyr--video {
  background: #000;
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.plyr__progress {
  flex: 1;
  left: 7px;
  margin-right: 14px;
  position: relative;
}

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -7px;
  margin-right: -7px;
  width: calc(100% + 14px);
}

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2;
}

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 4px;
  left: 0;
  margin-top: -2px;
  padding: 0;
  position: absolute;
  top: 50%;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0;
  transition: width .2s ease;
}

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 4px;
}

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 4px;
  transition: width .2s ease;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width .2s ease;
}

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25);
}

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66);
}

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;
}

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2;
}

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px;
  }
}

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px;
  }
}

.plyr--is-ios .plyr__volume {
  display: none !important;
}

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important;
}

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-moz-full-screen video {
  height: 100%;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-webkit-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-moz-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-ms-fullscreen .plyr__video-embed {
  overflow: visible;
}

.plyr:fullscreen .plyr__video-embed {
  overflow: visible;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
  }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
  }
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
  }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
  }
}

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-webkit-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
  }
}

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-moz-full-screen video {
  height: 100%;
}

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-moz-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
  }
}

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-ms-fullscreen .plyr__video-embed {
  overflow: visible;
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
  }
}

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr--fullscreen-fallback .plyr__video-embed {
  overflow: visible;
}

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
  }
}

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3;
}

.plyr__ads::after:empty {
  display: none;
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: 4px;
  left: 0;
  margin: -2px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.plyr [hidden] {
  display: none !important;
}

/**
    Global Components
 */
header.site-header {
  background: #10143e;
  position: relative;
  z-index: 99;
  /**
        TODO
     */
}

@media screen and (min-width: 768px) {
  header.site-header {
    height: auto;
  }
}

header.site-header a.header-social-link {
  color: white !important;
}

header.site-header .logo-container .site-logo img {
  margin-top: 7px;
}

header.site-header .site-crest {
  margin-left: 10px;
}

header.site-header .topbar {
  width: 100%;
  text-align: right;
  font-family: "AgendaBold", sans-serif;
  /**
            TODO
         */
  font-size: 16px;
}

header.site-header .topbar .notices {
  display: inline-block;
  color: white;
}

header.site-header .topbar .notices span {
  margin-left: 15px;
}

header.site-header .topbar .notices i {
  margin-right: 5px;
}

header.site-header .topbar .notices img {
  margin-left: 10px;
}

header.site-header .topbar .search-container .search-group {
  width: 270px;
  float: right;
}

header.site-header .topbar .search-container .search-group .form-control {
  margin-top: 3px;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  background: white;
  padding-left: 10px;
  font-weight: normal;
  border-radius: 0;
  /**
                          TODO
                    */
  border: 1px solid white;
}

header.site-header .topbar .search-container .search-group .input-group-append button {
  background: white;
  border: 0;
  height: 36px;
  margin-top: 3px;
  line-height: 35px;
}

header.site-header .topbar .btn.light-button {
  margin: 0;
  margin-top: -6px;
  margin-left: 6px;
  width: auto;
  letter-spacing: 1px;
}

header.site-header .topbar .contact-info {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  padding-top: 10px;
  margin-left: 10px;
  line-height: 32px;
  padding-right: 0;
  font-size: 12px;
  position: relative;
}

@media (max-width: 992px) {
  header.site-header .topbar .contact-info {
    display: none;
  }
}

header.site-header .topbar .contact-info a {
  color: white;
}

header.site-header .topbar .contact-info > a:before {
  margin-right: 5px;
  font-weight: bold;
}

header.site-header .topbar .contact-info > a:first-child:before {
  display: none;
}

header.site-header .topbar .contact-info .social {
  padding: 0;
  margin: 0 0 0 20px;
  display: inline-block;
}

header.site-header .topbar .contact-info .social a.header-social-link {
  display: inline-block;
  list-style: none;
  font-size: 20px;
  margin-left: 3px;
  position: relative;
  top: 1px;
}

header.site-header .topbar .ct-language {
  cursor: pointer;
  /**
                TODO
             */
  font-size: 15px;
}

header.site-header .topbar .translate-ul {
  display: inline-block;
  position: relative;
}

header.site-header .topbar .translate-ul .ct-language {
  color: white;
}

header.site-header .topbar .translate-ul img.powered {
  position: absolute;
  left: 0;
}

header.site-header .topbar .translate-ul .ct-language__dropdown {
  position: absolute;
  height: 0;
  min-width: 135px;
  background-color: white;
  border-radius: 7px;
  z-index: 99;
  overflow: hidden;
  text-align: left;
  left: -10px;
}

header.site-header .topbar .translate-ul .ct-language__dropdown.open {
  height: auto;
}

header.site-header .topbar .translate-ul .ct-language__dropdown a {
  display: block;
  padding: 2px 15px;
  color: black;
}

header.site-header .topbar .translate-ul .ct-language__dropdown a:hover {
  background-color: #d9bd45;
  color: white;
}

nav.main-nav {
  margin: 25px 0 20px;
  text-align: center;
}

@media (max-width: 1200px) {
  nav.main-nav {
    margin-top: 0;
  }
}

nav.main-nav li {
  display: inline-block;
  font-family: "AgendaBold", sans-serif;
  font-weight: 600;
  position: relative;
  /**
            TODO
         */
  font-size: 16px;
}

nav.main-nav li i {
  color: #d9bd45;
}

@media (max-width: 1200px) {
  nav.main-nav li {
    font-size: 14px;
  }
}

nav.main-nav li ul {
  opacity: 0;
  position: absolute;
  top: 75%;
  background: #70728b;
  text-align: left;
  padding: 0;
  padding: 5px;
  list-style: none;
  min-width: 100%;
  left: 0;
  pointer-events: none;
  transition: all 0.25s;
  border-top: 5px solid #d9bd45;
  border-bottom: 0;
  min-width: 165px;
}

@media screen and (max-width: 768px), screen and (max-width: 480px) {
  nav.main-nav li ul {
    background: transparent;
    border-top: none;
  }
}

nav.main-nav li ul li {
  width: 100%;
  border-bottom: 1px solid #10143e;
}

nav.main-nav li ul li:last-of-type {
  border-bottom: 0;
}

nav.main-nav li ul li.item-has-child ul {
  position: absolute;
  top: -5px;
  left: calc(100% + 2px);
  border-top: 0;
}

nav.main-nav li ul li.item-has-child i {
  display: none;
}

nav.main-nav li:hover > ul {
  opacity: 1;
  top: 95%;
  pointer-events: auto;
}

nav.main-nav li:hover > ul a {
  display: block;
}

nav.main-nav li:hover > a,
nav.main-nav li:hover > i {
  color: #d9bd45;
}

nav.main-nav li a {
  color: white;
  padding: 8px 8px;
  transition: all 0.25s;
  letter-spacing: 0px;
  /**
                TODO
             */
  font-size: 16px;
}

@media (max-width: 992px) {
  nav.main-nav li a {
    padding: 8px 10px;
  }
}

@-moz-document url-prefix() {
  nav.main-nav li a {
    font-size: 15px;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  nav.main-nav li a {
    font-size: 14px;
  }
}

nav.main-nav li a.highlight {
  background: #d9bd45;
}

nav.main-nav li a.highlight:hover {
  background: white;
  color: #d9bd45;
}

nav.main-nav li a.highlight.active {
  color: white;
}

nav.main-nav li a.highlight.active:hover {
  color: #d9bd45;
}

nav.main-nav li a.active {
  color: #d9bd45;
}

nav.main-nav li a.has-child {
  padding-right: 5px;
}

nav.main-nav li:last-of-type a {
  padding-right: 0;
}

@media (max-width: 992px) {
  height: 70px;
  .logo-container {
    text-align: center;
    padding: 20px 0;
    justify-content: center;
  }
  .topbar {
    text-align: center;
  }
  .topbar .contact-info {
    margin-top: 20px;
  }
  .topbar .contact-info:after {
    content: '';
    background: #d9bd45;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    right: 100%;
  }
}

@media (max-width: 768px) {
  .logo-container {
    text-align: center !important;
  }
}

.nav-toggle {
  width: 34px;
  height: 4px;
  position: relative;
  background: #d9bd45;
  margin: 33px auto;
  float: right;
  transition: all 0.5s;
  border-radius: 50px;
}

.nav-toggle:before {
  content: '';
  position: absolute;
  top: -12px;
  height: 4px;
  background: #d9bd45;
  width: 100%;
  left: 0;
  transition: all 0.5s;
  border-radius: 50px;
}

.nav-toggle:after {
  content: '';
  position: absolute;
  bottom: -12px;
  height: 4px;
  background: #d9bd45;
  width: 100%;
  border-radius: 50px;
  left: 0;
  transition: all 0.5s;
}

.nav-toggle.open {
  background: transparent;
  transition: all 0.5s;
}

.nav-toggle.open:before {
  transform: rotate(45deg);
  transform-origin: center left;
  transition: all 0.5s;
}

.nav-toggle.open:after {
  transform: rotate(-45deg);
  transform-origin: center left;
  transition: all 0.5s;
}

@media (max-width: 992px) {
  .nav-open nav.main-nav {
    top: 111px;
    opacity: 1;
    pointer-events: auto;
  }
  .logo-container {
    text-align: left;
    justify-content: left;
  }
  .logo-container .site-logo {
    padding: 0 15px;
  }
  .logo-container .site-logo img {
    width: auto;
    height: 46px;
    margin-top: -10px;
  }
  nav.main-nav {
    position: fixed;
    top: 100%;
    opacity: 0;
    pointer-events: 0;
    left: 0;
    background: #10143e;
    height: calc(100vh - 60px);
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 40px 120px;
    overflow: auto;
    transition: all 0.5s;
  }
  nav.main-nav li {
    font-size: 24px;
    display: block;
    text-align: left;
    margin: 10px 0;
  }
  nav.main-nav li a.highlight {
    display: inline-block;
    margin-top: 20px;
  }
  nav.main-nav li a:not(.highlight) {
    padding: 10px 0;
  }
  nav.main-nav li a.has-child:before {
    display: none;
  }
}

@media screen and (max-width: 992px) and (max-width: 768px), screen and (max-width: 992px) and (max-width: 480px) {
  nav.main-nav li a {
    font-size: 25px;
  }
}

@media (max-width: 992px) {
  nav.main-nav li.open > a {
    color: #d9bd45;
  }
  nav.main-nav li:hover > ul a {
    display: inline;
  }
  nav.main-nav li i {
    margin-top: 10px;
    margin-left: 25px;
    font-size: 25px;
    color: white;
  }
  nav.main-nav li i:hover {
    color: #d9bd45;
  }
}

@media screen and (max-width: 992px) and (max-width: 768px), screen and (max-width: 992px) and (max-width: 480px) {
  nav.main-nav li i {
    font-size: 25px;
  }
}

@media (max-width: 992px) {
  nav.main-nav li ul {
    position: static;
    opacity: 1;
    padding-left: 25px;
    display: none;
  }
  nav.main-nav .social {
    padding: 0;
    margin-top: 2rem;
    margin-bottom: 0;
    text-align: left;
  }
  nav.main-nav .social ul {
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;
  }
  nav.main-nav .social ul li {
    display: inline-block;
    margin-right: 15px;
    font-size: 36px;
  }
  nav.main-nav .social ul li a {
    font-size: 24px;
    color: white;
  }
  nav.main-nav footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background: #10143e;
    color: white;
  }
  nav.main-nav footer .contact-info {
    font-size: 16px;
    text-align: left;
    margin-bottom: 25px;
  }
  nav.main-nav footer .contact-info a {
    color: white;
    text-transform: none;
  }
  nav.main-nav footer .contact-info a:before {
    content: '|';
    margin-right: 5px;
    font-weight: bold;
  }
  nav.main-nav footer .contact-info a:first-child:before {
    display: none;
  }
  nav.main-nav footer .notices {
    font-size: 16px;
  }
  nav.main-nav footer .notices span {
    float: left;
  }
  nav.main-nav footer .notices span:last-child {
    float: right;
    text-transform: none;
  }
}

.fix {
  position: fixed;
  width: 100%;
}

.site-footer {
  height: auto;
  background-color: #10143e;
  border-bottom: 10px solid #d9bd45;
  background-color: #10143e;
  background-repeat: no-repeat;
  background-position: 105% -30%;
  padding-top: 70px;
  padding-bottom: 50px;
}

.site-footer .logo-container {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .site-footer .logo-container .site-logo img {
    height: unset;
    max-width: 90%;
    margin: 0 auto;
  }
}

.site-footer .social {
  text-align: center;
  margin-top: 10px;
}

.site-footer .social a {
  color: white;
  font-size: 18px;
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
}

@media (max-width: 769px) {
  .site-footer .footer-nav {
    border-right: 0;
  }
}

.site-footer .footer-nav .footer-link {
  margin-bottom: 15px;
}

.site-footer .footer-nav .footer-link a {
  color: white;
  font-size: 14px;
}

.site-footer .footer-nav .footer-link a:hover {
  text-decoration: underline;
}

.site-footer .footer-wrapper .footer-link a {
  font-family: "agenda", sans-serif;
}

.site-footer .footer-wrapper-bottom .footer-nav {
  margin-left: 0;
  border-right: 0;
}

.site-footer .footer-wrapper-bottom .footer-nav .footer-link:first-child {
  margin-right: 132px;
}

.site-footer .footer-wrapper-bottom .footer-nav .footer-link:nth-child(2) {
  margin-right: 185px;
}

.site-footer .footer-wrapper-bottom .footer-nav .footer-link a {
  font-size: 12px;
}

.site-footer .logo-section .logo-image {
  margin-bottom: 10px;
}

.site-footer .brands-title {
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  display: block;
}

.site-footer .brands-title:before {
  content: "";
  height: 1px;
  background: white;
  position: absolute;
  top: 50%;
  left: -5px;
  width: 70px;
}

.site-footer .brands-title:after {
  content: "";
  height: 1px;
  background: white;
  position: absolute;
  top: 50%;
  right: -5px;
  width: 70px;
}

.site-footer .credits-row img {
  max-width: 70px;
}

.footer-newsletter-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #f6f7ff;
}

@media screen and (max-width: 768px) {
  .footer-newsletter-wrapper h2 {
    font-size: 22px;
  }
}

.footer-newsletter-wrapper input[type='email'] {
  background: white;
  padding: 6px 15px;
  border: 1px solid #cfd0d8;
}

@media screen and (max-width: 768px) {
  .footer-newsletter-wrapper input[type='email'] {
    width: calc(100% - 30px);
  }
}

@media screen and (max-width: 768px) {
  .footer-newsletter-wrapper .waves-input-wrapper {
    width: 100%;
    margin-left: 0;
  }
}

.footer-newsletter-wrapper input[type='submit'] {
  margin-left: 20px;
  width: 150px;
  border: 2px solid #10143e;
  color: #10143e !important;
  border-radius: 0;
}

@media screen and (max-width: 768px) {
  .footer-newsletter-wrapper input[type='submit'] {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
}

.image-with-text {
  padding-bottom: 80px;
  margin-bottom: 0;
}

.image-with-text h2 {
  margin-bottom: 45px;
}

.home-header {
  background-color: #10143e;
  height: auto;
  background-repeat: no-repeat;
  background-position: 80% 20%;
  padding-bottom: 50px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .home-header {
    padding-bottom: 0px;
    background-color: white;
  }
}

@media screen and (max-width: 768px) {
  .home-header .container.slider-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.home-header .mobile-slider .slick-slide {
  position: relative;
}

.home-header .mobile-slider .slide-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-top: 20px;
  width: 100%;
  text-align: center;
}

.home-header .mobile-slider .slide-content h2 {
  color: #ffffff;
}

.home-header .mobile-slider .slide-content h3 {
  display: block;
}

.home-header .container {
  height: 100%;
  display: block;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
}

.home-header .content-row {
  z-index: 2;
}

.home-header .home-main-slider {
  z-index: 2;
  padding-top: 50px;
}

@media (max-width: 769px) {
  .home-header .home-main-slider {
    padding-top: 0px;
  }
}

.home-header .home-main-slider .slick-dots {
  bottom: 10px;
}

@media screen and (max-width: 768px) {
  .home-header .home-main-slider .slick-dots {
    bottom: 20px;
  }
}

.home-header .home-main-slider h1 {
  font-size: 36px;
  font-family: "AgendaBold", sans-serif;
}

@media (max-width: 375px) {
  .home-header .home-main-slider h1 {
    font-size: 30px;
  }
}

@media (max-width: 320px) {
  .home-header .home-main-slider h1 {
    font-size: 30px;
  }
}

.home-header .home-main-slider h3 {
  font-weight: bold;
  font-size: 24px;
  color: white;
}

.home-header .content {
  text-align: right;
  color: white;
  padding-bottom: 50px;
  width: 100%;
}

@media (max-width: 768px) {
  .home-header .content {
    padding-bottom: 50px;
    min-height: 400px;
  }
}

.home-header .content .content-inner {
  margin-right: 0;
  margin-left: auto;
  max-width: 500px;
  text-align: center;
}

@media (max-width: 768px) {
  .home-header .content .content-inner {
    margin-top: calc(100% - 50px);
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .home-header .content .content-inner {
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .home-header .content .content-inner .btn {
    width: auto;
    margin-bottom: 30px;
  }
}

.home-header .content .content-inner .btn {
  color: #10143e !important;
  font-weight: bold !important;
  font-size: 18px;
  letter-spacing: normal;
}

.home-header .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-header .image .image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.home-header .image .image-container .main-slider-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 100%;
  width: 100%;
  max-width: unset;
  transition: background 0.2s ease;
  background-size: cover;
  background-position: center center;
}

.home-header .image .image-container .main-slider-image.active {
  display: block;
}

.home-header .image .image-container .main-slider-image.top {
  z-index: -1;
}

.blocks-row {
  z-index: 2;
  position: relative;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .blocks-row {
    transform: translateY(0px);
  }
}

.blocks-row .blocks-row-block h4 {
  text-align: center;
  font-size: 24px;
  font-weight: 900;
}

.blocks-row .blocks-row-block .btn {
  font-size: 18px;
  letter-spacing: normal;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .blocks-row .blocks-row-block {
    margin-bottom: 10px;
  }
}

.hero-slider {
  position: relative;
}

.hero-slider h1 {
  font-size: 36px;
}

.hero-slider .slick-dots {
  position: absolute;
  left: 50px;
  top: 50%;
  -ms-transform: translateX(-50%);
  transform: translateY(-50%);
  padding-left: 0;
  margin-left: 0;
  list-style-type: none;
}

@media (max-width: 769px) {
  .hero-slider .slick-dots {
    left: 15px;
  }
}

.hero-slider .slick-dots li {
  overflow: visible;
  height: 15px;
  width: 15px;
  display: block;
  min-height: unset;
  margin-top: 25px;
  margin-bottom: 25px;
  position: relative;
}

.hero-slider .slick-dots li:after {
  content: "";
  position: absolute;
  top: 26px;
  left: 7px;
  height: 27px;
  width: 2px;
  background: white;
}

.hero-slider .slick-dots li:last-of-type:after {
  display: none;
}

.hero-slider .slick-dots li button {
  overflow: visible;
  height: 15px;
  width: 15px;
  display: inline-block;
  border-radius: 15px;
  background: transparent;
  font-size: 1px;
  color: transparent;
  border: 0;
  padding: 0;
  border: 2px solid white;
  cursor: pointer;
}

.hero-slider .slick-dots li button:hover {
  background: white;
}

.hero-slider .slick-dots li.slick-active button {
  background: white;
}

.hero-slider .hero-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

.hero-slider .hero-section:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  background: rgba(16, 20, 62, 0.75);
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: 0;
}

.hero-slider .hero-section .content {
  min-height: 300px;
  z-index: 1;
}

@media (max-width: 769px) {
  .hero-slider .hero-section .content h1 {
    font-size: 28px;
  }
}

.interests {
  background: #10143e;
  padding-top: 0px;
  padding-bottom: 75px;
  /* the slides */
  /* the parent */
}

@media (max-width: 769px) {
  .interests {
    display: none;
  }
}

.interests h2.top-header {
  width: 100%;
  text-align: center;
  transform: translateY(-60px);
}

.interests .container {
  position: relative;
}

.interests .item {
  background: white;
  text-align: center;
}

.interests .slick-slide {
  margin: 0 10px;
  outline: none !important;
  background: #10143e;
}

.interests .slick-slide h3.title {
  color: white;
  text-align: center;
  font-size: 18px;
}

.interests .slick-slide .image {
  background-size: cover;
  border: 3px solid #10143e;
  width: 100%;
}

.interests .slick-slide .image:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.interests .slick-slide .image:hover {
  border-color: #d9bd45;
}

.interests .slick-list {
  margin: 0 -10px;
}

.interests .interests-button {
  position: absolute;
  top: 58%;
  left: 0;
  transform: translateY(-50%) translateX(-130%);
  width: 30px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  background-color: transparent;
  color: #d9bd45;
  opacity: 0.8;
  cursor: pointer;
  border: 2px solid #d9bd45;
}

.interests .interests-button.interests-next {
  left: auto;
  right: 0;
  transform: translateY(-50%) translateX(130%);
}

.interests .interests-button:hover {
  opacity: 1;
}

.page-heading {
  height: 350px;
  background: #10143e;
  border-bottom: 5px solid #e8274d;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.page-heading.border-green {
  border-bottom: 5px solid #6bc9bf;
}

.page-heading:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  background: rgba(16, 20, 62, 0.5);
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: 0;
}

@media screen and (max-width: 768px) {
  .page-heading {
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
  }
  .page-heading h1 {
    font-size: 30px;
  }
}

.link-block {
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

@media (max-width: 769px) {
  .link-block {
    padding-bottom: 60%;
  }
}

.link-block:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(16, 20, 62, 0.35);
  width: calc(100%);
  height: calc(100%);
  z-index: 0;
  transition: all ease 0.3s;
}

.link-block .info {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: white;
  width: calc(100% - 40px);
  text-align: center;
  margin-bottom: 0;
}

.link-block .info h3 {
  margin-bottom: 0;
}

.link-block .info p {
  height: auto;
  max-height: 0;
  transition: all ease 0.3s;
  overflow: hidden;
  opacity: 0;
  margin-bottom: 0;
  font-size: 15px;
}

.link-block:hover .info p {
  max-height: 214px;
  opacity: 1;
}

.link-block:hover:before {
  background: rgba(16, 20, 62, 0.75);
}

.page-intro {
  background-color: #10143e;
  height: auto;
  background-repeat: no-repeat;
  background-position: 100% 20%;
  margin-bottom: 100px;
}

.page-intro .container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-intro .breadcrumbs {
  color: white;
}

.page-intro .breadcrumbs li a {
  color: white;
}

.page-intro .content {
  text-align: left;
  color: white;
  padding-bottom: 50px;
}

.page-intro .image {
  position: absolute;
  top: 150px;
  right: -100px;
  width: 450px;
}

@media (max-width: 769px) {
  .page-intro .image {
    width: 350px;
    overflow: hidden;
    width: 350px;
    overflow: hidden;
    top: 450px;
    right: 0px;
  }
  .page-intro .image .image-container {
    width: 400px !important;
    height: 400px !important;
  }
}

.page-intro .image .image-container {
  position: relative;
  border: 5px solid #d9bd45;
  background-color: #d9bd45;
  border-radius: 1234567px;
  overflow: hidden;
  width: 450px;
  height: 450px;
}

.page-intro .image .image-container:after {
  content: "";
  padding-top: 100%;
}

.page-intro .image .image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 100%;
  width: auto;
  max-width: unset;
  display: block;
  z-index: 1;
  transition: z-index ease 0.2s;
}

.blue-info-block {
  margin-top: 75px;
  margin-bottom: 75px;
}

.blue-info-block p:last-of-type {
  margin-bottom: 0;
}

.get-in-touch-form {
  margin-bottom: 75px;
}

.get-in-touch-form textarea.form-control {
  height: 500px;
}

.get-in-touch-form .col-12 {
  position: relative;
}

.get-in-touch-form .col-12 .absolute {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 769px) {
  .get-in-touch-form .col-12 .absolute {
    position: static;
  }
}

.page-title h2 {
  margin-bottom: 30px;
}

.view-online-bar {
  background: #d9bd45;
  width: 100%;
  padding: 14px;
  text-align: center;
}

.view-online-bar h3 {
  margin-bottom: 0;
}

.social-blocks .social-button {
  display: block;
  width: 100%;
  padding: 10px 10px;
  color: white;
  text-align: center;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

.social-blocks .social-button i {
  margin-right: 10px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: white;
  font-size: 12px;
  line-height: 24px;
}

.social-blocks .social-button.social-button-twitter {
  background: #00aced;
}

.social-blocks .social-button.social-button-twitter i {
  color: #00aced;
}

.social-blocks .social-button.social-button-twitter:hover {
  background: #21c2ff;
}

.social-blocks .social-button.social-button-instagram {
  background: linear-gradient(#6759A6 0%, #94489A 33%, #A03A8F 50%, #F7AA54 90%);
}

.social-blocks .social-button.social-button-instagram i {
  color: #94489A;
}

.social-blocks .social-button.social-button-instagram:hover {
  background: linear-gradient(#857ab8 0%, #af60b5 33%, #bf4eac 50%, #f9c285 90%);
}

.social-blocks .social-button.social-button-facebook {
  background: #3b5998;
}

.social-blocks .social-button.social-button-facebook i {
  color: #3b5998;
}

.social-blocks .social-button.social-button-facebook:hover {
  background: #4c70ba;
}

.social-blocks .social-button.social-button-linkedin {
  background: #007bb6;
}

.social-blocks .social-button.social-button-linkedin i {
  color: #007bb6;
}

.social-blocks .social-button.social-button-linkedin:hover {
  background: #009de9;
}

.not-compatible {
  margin: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .twitter-feed {
    display: none;
  }
}

.events-section-wrapper {
  background: #f6f7ff;
  padding-bottom: 50px;
  margin-bottom: 80px;
}

.events-section-wrapper .container {
  position: relative;
}

.events-section-wrapper .events-section {
  text-align: center;
}

.events-section-wrapper .events-section h2 {
  margin-bottom: 0;
  padding: 30px 0;
}

.events-section-wrapper .events-section .events-slider .event-slide img {
  margin-bottom: 0 !important;
  margin: 0 auto;
  height: 200px;
}

.events-section-wrapper .events-section .events-slider-button {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateX(-130%);
  width: 30px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  background-color: transparent;
  color: #70728b;
  opacity: 0.8;
  cursor: pointer;
}

@media (max-width: 480px) {
  .events-section-wrapper .events-section .events-slider-button.events-slider-button-prev {
    bottom: -30px;
    top: unset;
    left: 170px;
    font-size: 20px;
  }
}

.events-section-wrapper .events-section .events-slider-button.events-slider-button-next {
  left: auto;
  right: 0;
  transform: translateY(-50%) translateX(130%);
}

@media (max-width: 480px) {
  .events-section-wrapper .events-section .events-slider-button.events-slider-button-next {
    bottom: -30px;
    top: unset;
    right: 170px;
    font-size: 20px;
  }
}

.events-section-wrapper .events-section .events-slider-button:hover {
  opacity: 1;
}

.page-heading-alt {
  margin-bottom: 50px;
}

.page-heading-alt .featured-image {
  height: 340px;
  background-size: cover !important;
  background: no-repeat center center;
}

.page-heading-alt .additional-image {
  width: 565px;
  height: 525px;
  position: absolute;
  right: -260px;
  top: 165px;
  background: no-repeat center center;
  background-size: contain !important;
}

.testimonials-slider-with-media .testimonials-slider-with-media-body {
  display: flex;
  max-width: 100%;
}

@media (max-width: 1024px) {
  .testimonials-slider-with-media .testimonials-slider-with-media-body {
    display: block;
    width: 100%;
  }
}

@media (max-width: 320px) {
  .testimonials-slider-with-media .testimonials-slider-with-media-body {
    display: block;
    width: 100%;
  }
}

.testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-media {
  display: flex;
  width: 50%;
}

@media (max-width: 1024px) {
  .testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-media {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-media {
    width: 100%;
  }
}

.testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-media .plyr__video-embed {
  height: 100%;
}

.testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  background: #f6f7ff;
  position: relative;
}

@media (max-width: 1024px) {
  .testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-wrapper {
    display: block;
    width: 100%;
  }
}

@media (max-width: 320px) {
  .testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-wrapper {
    display: block;
    width: 100%;
  }
}

.testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-wrapper h2 {
  text-align: center;
  margin-bottom: 0 !important;
  padding: 65px 0 35px 0;
}

.testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-wrapper .testimonials-slider {
  display: block;
  width: 100%;
}

.testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-wrapper .testimonials-slider .testimonial-slide {
  padding: 0 150px 80px 150px;
}

@media (max-width: 1024px) {
  .testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-wrapper .testimonials-slider .testimonial-slide {
    padding: 10px;
  }
}

@media (max-width: 320px) {
  .testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-wrapper .testimonials-slider .testimonial-slide {
    padding: 10px;
  }
}

.testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-button {
  position: absolute;
  top: 80%;
  left: calc(50% - 50px);
  width: 30px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  background-color: #10143e;
  color: white;
  cursor: pointer;
  opacity: 1;
}

.testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-button.testimonials-slider-button-next {
  left: calc(50% + 0px);
  right: 0;
}

.testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-button:hover {
  opacity: 0.8;
}

.testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-button i {
  line-height: 30px;
}

@media (max-width: 1024px) {
  .testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-button {
    top: 87%;
  }
}

@media (max-width: 320px) {
  .testimonials-slider-with-media .testimonials-slider-with-media-body .testimonials-slider-button {
    top: 93%;
  }
}

#google_translate_element {
  position: static;
  bottom: calc(53px + 16px);
  right: 16px !important;
  display: inline-block;
}

.goog-te-gadget {
  font-family: sans-serif !important;
  text-transform: uppercase;
}

.goog-te-gadget-simple {
  background: transparent;
  padding: 8px !important;
  display: inline-block;
  cursor: pointer;
  zoom: 1;
  border: 0;
  font-size: 14px;
  font-family: "GothamBold", sans-serif;
  font-weight: 800;
}

.goog-te-menu2 {
  max-width: 100%;
}

.goog-te-menu-value {
  color: #fff !important;
}

.goog-te-menu-value:before {
  display: none;
  font-family: 'Material Icons';
  content: "\E927";
  margin-right: 16px;
  font-size: 2rem;
  vertical-align: -10px;
}

.goog-te-menu-value span:nth-child(5) {
  display: none;
}

.goog-te-menu-value span:nth-child(3) {
  border: none !important;
  font-family: 'Material Icons';
}

.goog-te-menu-value span:nth-child(3):after {
  display: none;
  font-family: 'Material Icons';
  content: "\E5C5";
  font-size: 1.5rem;
  vertical-align: -6px;
}

.goog-te-gadget-icon {
  background-image: url(https://placehold.it/32) !important;
  background-position: 0px 0px;
  height: 32px !important;
  width: 32px !important;
  margin-right: 8px !important;
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

/* ================================== *\
    Mediaqueries
\* ================================== */
@media (max-width: 667px) {
  #google_translate_element {
    bottom: calc(100% - 50% - 53px);
    left: 16px !important;
    width: 100% !important;
  }
  #google_translate_element goog-te-gadget {
    width: 100% !important;
  }
  #google_translate_element .skiptranslate {
    width: 100% !important;
  }
  #google_translate_element .goog-te-gadget-simple {
    width: calc(100% - 32px) !important;
    text-align: center;
  }
}

.other-news {
  position: relative;
  background-color: #f6f7ff;
  padding: 40px 0px;
}

.other-news #see-other-news-slider {
  margin-top: 3rem;
}

.other-news .news-card {
  margin: 0 15px 0 0;
}

@media screen and (max-width: 480px) {
  .other-news .news-card {
    margin-right: 0;
  }
}

.other-news .see-other-news-article {
  width: 362px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
}

.other-news .news-card-content a {
  color: white;
}

.other-news .more-news-prev-button,
.other-news .more-news-next-button {
  position: absolute;
  top: 50%;
  transform: translate(-50%);
}

@media screen and (max-width: 480px) {
  .other-news .more-news-prev-button,
  .other-news .more-news-next-button {
    top: 495px;
    background: #10143e;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    color: white;
  }
}

@media screen and (max-width: 320px) {
  .other-news .more-news-prev-button,
  .other-news .more-news-next-button {
    bottom: 12px;
    top: unset;
  }
}

.other-news .more-news-prev-button {
  left: 320px;
}

@media screen and (max-width: 768px) {
  .other-news .more-news-prev-button {
    left: 45px;
  }
}

@media screen and (max-width: 480px) {
  .other-news .more-news-prev-button {
    left: 165px;
  }
}

@media screen and (max-width: 375px) {
  .other-news .more-news-prev-button {
    left: 150px;
  }
}

@media screen and (max-width: 320px) {
  .other-news .more-news-prev-button {
    left: 110px;
  }
}

.other-news .more-news-next-button {
  right: 320px;
}

@media screen and (max-width: 768px) {
  .other-news .more-news-next-button {
    right: 45px;
  }
}

@media screen and (max-width: 480px) {
  .other-news .more-news-next-button {
    right: 165px;
  }
}

@media screen and (max-width: 375px) {
  .other-news .more-news-next-button {
    right: 150px;
  }
}

@media screen and (max-width: 320px) {
  .other-news .more-news-next-button {
    right: 110px;
  }
}

.other-news h2 {
  text-transform: uppercase;
}

.employees-list {
  position: relative;
  text-align: center;
  margin-bottom: 80px;
  padding: 50px 0px 35px 0px;
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .employees-list {
    padding-bottom: 5rem;
  }
}

.employees-list p {
  margin-bottom: .2rem;
}

.employees-list .employee-wrapper {
  padding-top: 30px;
}

.employees-list .employee-thumbnail {
  height: 230px;
  width: 230px;
  background-position: -36px -37px;
  border-radius: 50%;
  margin: 0 auto;
  background-size: 282px;
  background-repeat: no-repeat;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
}

.employees-list .employee-heading {
  color: #10143e;
  margin-bottom: 65px;
}

.employees-list .particle-image {
  position: absolute;
  width: 482px;
  height: 530px;
  top: -303px;
  left: -203px;
}

.employees-list .employee-name {
  font-family: "AgendaBold", sans-serif;
  color: #10143e;
  margin-bottom: 5px;
}

.employees-list .job-role {
  color: #2f4954;
}

@media screen and (max-width: 480px) {
  .employees-list .slick-dots {
    width: 100%;
    bottom: -35px;
  }
}

.employees-list a {
  color: #323232;
}

.circle-image-header {
  position: relative;
  width: 100%;
  background-color: #10143e;
  background-repeat: no-repeat;
  background-position: right -65px;
  color: white;
  margin-bottom: 25px;
}

.circle-image-header h1 {
  text-transform: uppercase;
}

@media screen and (max-width: 1024px) {
  .circle-image-header {
    background-position: 633px 111px;
  }
}

@media screen and (max-width: 768px) {
  .circle-image-header {
    margin-bottom: 100px;
    background-position: 398px 218px;
  }
}

@media screen and (max-width: 480px) {
  .circle-image-header {
    margin-bottom: 100px;
    background-position: 100px 257px;
  }
}

@media screen and (max-width: 320px) {
  .circle-image-header {
    margin-bottom: 10px;
  }
}

.circle-image-header .breadcrumbs {
  margin: 0;
  padding-top: 25px;
}

.circle-image-header .breadcrumbs li:after {
  color: white;
}

.circle-image-header .breadcrumbs li a {
  color: white;
}

.circle-image-header .circle-hero-image {
  width: 500px;
  height: 500px;
  border: 5px solid #d9bd45;
  border-radius: 50%;
  position: absolute;
  right: -625px;
  bottom: -250px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1024px) {
  .circle-image-header .circle-hero-image {
    width: 300px;
    height: 300px;
    right: -250px;
    bottom: -150px;
  }
}

@media screen and (max-width: 768px) {
  .circle-image-header .circle-hero-image {
    width: 350px;
    height: 350px;
    top: initial;
    right: -175px;
    bottom: -175px;
  }
}

@media screen and (max-width: 480px) {
  .circle-image-header .circle-hero-image {
    width: 250px;
    height: 250px;
    right: -50px;
    top: initial;
    bottom: -127px;
  }
}

@media screen and (max-width: 320px) {
  .circle-image-header .circle-hero-image {
    width: 200px;
    height: 200px;
    right: -40px;
    bottom: -103px;
  }
}

.circle-image-header .circle-header-wrapper {
  position: relative;
  padding-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .circle-image-header .circle-header-wrapper {
    padding-bottom: 140px;
  }
}

.video-with-text {
  padding-bottom: 45px;
  padding-top: 45px;
}

.video-with-text .video-title {
  color: #10143e;
}

.other-employees {
  position: relative;
}

.other-employee-slider {
  position: relative;
  margin-top: 40px;
  margin-bottom: 20px;
}

.other-employee-slider .employee-thumbnail {
  height: 230px;
  width: 230px;
  background-position: -36px -37px;
  border-radius: 50%;
  background-size: 282px;
  background-repeat: no-repeat;
  margin-bottom: 15px;
  margin: 0 auto;
}

.other-employee-slider .employee-info {
  margin-top: 15px;
  font-weight: bold;
}

.other-employee-slider p {
  color: #323232;
}

.other-employee.next-button, .other-employee.prev-button {
  position: absolute;
  cursor: pointer;
}

.other-employee.next-button i, .other-employee.prev-button i {
  color: black;
}

.other-employee.prev-button {
  top: 180px;
  left: -105px;
}

@media screen and (max-width: 1024px) {
  .other-employee.prev-button {
    left: -5px;
  }
}

@media screen and (max-width: 768px) {
  .other-employee.prev-button {
    left: -55px;
  }
}

@media screen and (max-width: 480px) {
  .other-employee.prev-button {
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (orientation: landscape) {
  .other-employee.prev-button {
    left: -25px;
  }
}

.other-employee.next-button {
  top: 180px;
  right: -105px;
}

@media screen and (max-width: 1024px) {
  .other-employee.next-button {
    right: -5px;
  }
}

@media screen and (max-width: 768px) {
  .other-employee.next-button {
    right: -55px;
  }
}

@media screen and (max-width: 480px) {
  .other-employee.next-button {
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (orientation: landscape) {
  .other-employee.next-button {
    right: -25px;
  }
}

.light-page-heading {
  height: auto;
  background: #f6f7ff;
  border-bottom: 0;
  position: relative;
  padding-top: 20px;
  padding-bottom: 10px;
}

.light-page-heading:before {
  display: none;
}

.light-page-heading p {
  color: #10143e;
  margin-bottom: 0 !important;
}

.light-page-heading:after {
  content: "";
  width: 220px;
  height: 490px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -50px;
  right: 0;
  z-index: 1;
}

.products--link-block {
  text-align: center;
  margin-bottom: 50px;
}

.products--link-block .product-title {
  margin-top: 10px;
  display: block;
  margin-bottom: 15px;
}

.products--link-block .btn.block {
  width: 80%;
}

.products--grid {
  position: relative;
  margin-top: 30px;
}

.products--grid:after {
  content: "";
  position: absolute;
  right: -100px;
  top: 50%;
  background-repeat: no-repeat;
  background-position: 0 0;
  transform: rotate(-180deg) translateY(50%);
  width: 400px;
  height: 800px;
  background-size: contain;
}

.canine-product-links {
  margin-bottom: 30px;
}

.canine-product-links .canine-product-link-wrapper {
  margin-bottom: 30px;
}

.canine-product-links .canine-product-link-wrapper .canine-product-link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 60px;
  min-height: 300px;
  height: 300px;
  width: 100%;
  text-align: center;
  flex-direction: column;
}

.canine-product-links .canine-product-link-wrapper .canine-product-link .canine-product-link-image {
  margin-bottom: 0 !important;
}

.canine-product-links .canine-product-link-wrapper .canine-product-link .canine-product-link-text {
  padding-top: 20px;
  overflow: hidden;
  height: auto;
  max-height: 0;
  transition: all 0.75s;
  width: 100%;
}

.canine-product-links .canine-product-link-wrapper .canine-product-link:hover .canine-product-link-text {
  max-height: 150px;
}

.bubble-t1 {
  left: 80px;
}

@media screen and (max-width: 1024px) {
  .bubble-t1 {
    left: 73px;
  }
}

.bubble-t2 {
  left: 160px;
}

@media screen and (max-width: 1024px) {
  .bubble-t2 {
    left: 146px;
  }
}

.bubble-t3 {
  left: 240px;
}

@media screen and (max-width: 1024px) {
  .bubble-t3 {
    left: 219px;
  }
}

.bubble-t4 {
  left: 320px;
}

@media screen and (max-width: 1024px) {
  .bubble-t4 {
    left: 292px;
  }
}

.bubble-t5 {
  left: 400px;
}

@media screen and (max-width: 1024px) {
  .bubble-t5 {
    left: 365px;
  }
}

.bubble-t6 {
  left: 480px;
}

@media screen and (max-width: 1024px) {
  .bubble-t6 {
    left: 438px;
  }
}

.bubble-t7 {
  left: 560px;
}

@media screen and (max-width: 1024px) {
  .bubble-t7 {
    left: 511px;
  }
}

.bubble-t8 {
  left: 640px;
}

@media screen and (max-width: 1024px) {
  .bubble-t8 {
    left: 584px;
  }
}

.bubble-t9 {
  left: 720px;
}

@media screen and (max-width: 1024px) {
  .bubble-t9 {
    left: 657px;
  }
}

.bubble-t10 {
  left: 800px;
}

@media screen and (max-width: 1024px) {
  .bubble-t10 {
    left: 730px;
  }
}

.bubble-t11 {
  left: 880px;
}

@media screen and (max-width: 1024px) {
  .bubble-t11 {
    left: 803px;
  }
}

.bubble-t12 {
  left: 960px;
}

@media screen and (max-width: 1024px) {
  .bubble-t12 {
    left: 876px;
  }
}

.about-us-timeline {
  background-repeat: no-repeat;
  background-position: -359px;
  background-color: #10143e;
  padding: 50px 0px 35px 0px;
}

.about-us-timeline h3.timeline-heading {
  text-transform: uppercase;
}

.timeline-about-us {
  position: relative;
  margin-top: 300px;
  margin-bottom: 345px;
}

@media screen and (max-width: 768px) {
  .timeline-about-us {
    margin-bottom: 120px;
    margin-top: 75px;
  }
}

.timeline-about-us:before {
  content: '';
  display: block;
  height: 100%;
  background-color: white;
}

.timeline-about-us .main-line {
  width: 100%;
  height: 3px;
  background: white;
}

.timeline-about-us .main-line:before, .timeline-about-us .main-line:after {
  position: absolute;
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  top: -6px;
}

.timeline-about-us .main-line:before {
  left: 0;
}

.timeline-about-us .main-line:after {
  right: 0;
}

.timeline-about-us .bubble {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid white;
  text-align: center;
}

.timeline-about-us .bubble .bubble-content {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  padding: 10px 2px 0 7px;
}

.timeline-about-us .bubble .bubble-content h4,
.timeline-about-us .bubble .bubble-content p {
  color: #d9bd45;
}

.timeline-about-us .bubble .bubble-content h4 {
  font-size: 1.5rem;
}

.timeline-about-us .bubble .bubble-content p {
  /**
            TODO
         */
  font-size: 0.75rem;
}

.timeline-about-us .bubble .bubble-icon {
  position: absolute;
  width: 68px;
}

.timeline-about-us .bubble.bubble-top:before, .timeline-about-us .bubble.bubble-top:after, .timeline-about-us .bubble.bubble-bottom:before, .timeline-about-us .bubble.bubble-bottom:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #ffffff;
}

.timeline-about-us .bubble.bubble-top:before, .timeline-about-us .bubble.bubble-bottom:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.timeline-about-us .bubble.bubble-top {
  top: -241px;
}

.timeline-about-us .bubble.bubble-top:before, .timeline-about-us .bubble.bubble-top:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #ffffff;
}

.timeline-about-us .bubble.bubble-top .bubble-icon {
  bottom: -102px;
  left: 99px;
}

.timeline-about-us .bubble.bubble-top:before {
  bottom: -99px;
  left: 69px;
}

.timeline-about-us .bubble.bubble-top:after {
  width: 2px;
  height: 96px;
  bottom: -96px;
  left: 50%;
}

.timeline-about-us .bubble.bubble-top:nth-of-type(4n + 3) {
  top: -200px;
}

.timeline-about-us .bubble.bubble-top:nth-of-type(4n + 3):before {
  bottom: -59px;
}

.timeline-about-us .bubble.bubble-top:nth-of-type(4n + 3):after {
  bottom: -55px;
  height: 55px;
}

.timeline-about-us .bubble.bubble-top:nth-of-type(4n + 3) .bubble-icon {
  bottom: -60px;
  left: 127px;
}

.timeline-about-us .bubble.bubble-bottom {
  bottom: -200px;
}

.timeline-about-us .bubble.bubble-bottom .bubble-icon {
  right: -58px;
  top: -45px;
}

.timeline-about-us .bubble.bubble-bottom:before {
  top: -58px;
  left: 69px;
}

.timeline-about-us .bubble.bubble-bottom:after {
  width: 2px;
  height: 55px;
  top: -55px;
  left: 50%;
}

.timeline-about-us .bubble.bubble-bottom:nth-of-type(4n + 2) {
  bottom: -241px;
}

.timeline-about-us .bubble.bubble-bottom:nth-of-type(4n + 2) .bubble-icon {
  right: -15px;
  top: -87px;
}

.timeline-about-us .bubble.bubble-bottom:nth-of-type(4n + 2):before {
  left: 69px;
  top: -99px;
}

.timeline-about-us .bubble.bubble-bottom:nth-of-type(4n + 2):after {
  top: -96px;
  height: 96px;
}

.mobile-timeline .vertical-line {
  width: 3px;
  height: 100%;
  background-color: white;
}

.mobile-timeline .bubble,
.mobile-timeline .bubble-content {
  position: relative;
}

.mobile-timeline .bubble {
  height: 250px;
  width: 250px;
  margin-bottom: 15px;
  transform: translate(0);
}

.mobile-timeline .bubble .bubble-icon {
  bottom: -84px;
  left: -47px;
}

.mobile-timeline .bubble:before, .mobile-timeline .bubble:after {
  content: '';
  display: block;
  background: white;
  position: absolute;
}

.mobile-timeline .bubble:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -95px;
  top: 120px;
}

@media screen and (max-width: 480px) {
  .mobile-timeline .bubble:before {
    left: -72px;
  }
}

.mobile-timeline .bubble:after {
  width: 92px;
  height: 3px;
  top: 50%;
  left: -92px;
}

@media screen and (max-width: 480px) {
  .mobile-timeline .bubble:after {
    width: 68px;
    left: -69px;
  }
}

.mobile-timeline .bubble:nth-child(even) {
  margin-left: 170px;
}

.mobile-timeline .bubble:nth-child(even) .bubble-icon {
  bottom: -83px;
  right: 0;
  left: initial;
}

@media screen and (max-width: 320px) {
  .mobile-timeline .bubble:nth-child(even) .bubble-icon {
    right: unset;
    left: -35px;
  }
}

@media screen and (max-width: 480px) {
  .mobile-timeline .bubble:nth-child(even) {
    margin-left: 70px;
  }
}

@media screen and (max-width: 320px) {
  .mobile-timeline .bubble:nth-child(even) {
    margin-left: -5px;
  }
}

.mobile-timeline .bubble:nth-child(even):before {
  left: -265px;
}

@media screen and (max-width: 480px) {
  .mobile-timeline .bubble:nth-child(even):before {
    left: -142px;
  }
}

@media screen and (max-width: 320px) {
  .mobile-timeline .bubble:nth-child(even):before {
    left: -53px;
  }
}

.mobile-timeline .bubble:nth-child(even):after {
  width: 264px;
  left: -264px;
}

@media screen and (max-width: 480px) {
  .mobile-timeline .bubble:nth-child(even):after {
    width: 138px;
    left: -139px;
  }
}

@media screen and (max-width: 320px) {
  .mobile-timeline .bubble:nth-child(even):after {
    width: 50px;
    left: -50px;
  }
}

.mobile-timeline .bubble .bubble-content {
  padding: 35px 10px 0 10px;
}

.mobile-timeline .bubble .bubble-content p {
  font-size: 1.2rem;
}

.brand-values {
  position: relative;
  background-color: white;
  padding: 50px 0px 65px 0px;
}

.brand-values .particle-image,
.brand-values .particle-image-2 {
  position: absolute;
  height: 415px;
}

.brand-values .particle-image {
  background-position: -37px 0px;
  bottom: 0;
  width: 228px;
}

.brand-values .particle-image-2 {
  right: -26px;
  width: 266px;
  transform: rotate(180deg);
}

.brand-values h2.page-title {
  margin-bottom: 50px;
}

.brand-values .brand-value-tile {
  width: 100%;
  padding: 75px 45px 75px 45px;
}

.brand-values .brand-value-tile h2,
.brand-values .brand-value-tile p {
  color: white;
  position: relative;
  z-index: 3;
}

.brand-values .brand-value-tile p {
  font-size: 16px;
}

.brand-values .brand-value-tile i {
  font-size: 15rem;
}

.brand-values .brand-value-tile img {
  height: 273px;
}

@media screen and (max-width: 768px) {
  .brand-values .brand-value-tile img {
    height: auto;
  }
}

.brand-values .brand-value-tile .no-pad {
  padding: 0;
}

.brand-values .brand-value-tile.pioneering {
  background-color: #10143e;
}

.brand-values .brand-value-tile.experts {
  background: #6bc9bf;
}

.brand-values .brand-value-tile.trusted {
  background: #e8274d;
}

.brand-values .brand-value-tile.passionate {
  background: #d9bd45;
}

.brand-values .content-wrapper {
  padding: 0;
  display: flex;
}

.charities {
  position: relative;
}

.charities .charity-slide {
  height: 115px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

@media screen and (max-width: 480px) {
  .charities .charity-slide {
    background-size: cover;
  }
}

@media screen and (max-width: 425px) {
  .charities .charity-slide {
    background-size: contain;
  }
}

@media screen and (max-width: 414px) {
  .charities .charity-slide {
    background-size: contain;
  }
}

@media screen and (max-width: 375px) {
  .charities .charity-slide {
    height: 165px;
  }
}

@media screen and (max-width: 320px) {
  .charities .charity-slide {
    height: 145px;
  }
}

.charities .charities-slider {
  margin-top: 65px;
}

.charities .next-button,
.charities .prev-button {
  position: absolute;
  cursor: pointer;
}

.charities .next-button i,
.charities .prev-button i {
  color: black;
}

.charities .prev-button {
  top: 50%;
  transform: translateY(-50%);
  left: -80px;
}

@media screen and (max-width: 1024px) {
  .charities .prev-button {
    left: -28px;
  }
}

@media screen and (max-width: 768px) {
  .charities .prev-button {
    left: -65px;
  }
}

@media screen and (max-width: 480px) {
  .charities .prev-button {
    left: 150px;
    top: 150px;
  }
}

@media screen and (max-width: 320px) {
  .charities .prev-button {
    left: 90px;
  }
}

.charities .next-button {
  top: 50%;
  transform: translateY(-50%);
  right: -80px;
}

@media screen and (max-width: 1024px) {
  .charities .next-button {
    right: -28px;
  }
}

@media screen and (max-width: 768px) {
  .charities .next-button {
    right: -65px;
  }
}

@media screen and (max-width: 480px) {
  .charities .next-button {
    right: 150px;
    top: 150px;
  }
}

@media screen and (max-width: 320px) {
  .charities .next-button {
    right: 90px;
  }
}

@media screen and (max-width: 375px) {
  .charities .next-button,
  .charities .prev-button {
    top: 190px;
  }
}

@media screen and (max-width: 320px) {
  .charities .next-button,
  .charities .prev-button {
    top: 165px;
  }
}

.sponsorships {
  position: relative;
}

.sponsorships .sponsors-slide {
  height: 115px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

@media screen and (max-width: 480px) {
  .sponsorships .sponsors-slide {
    background-size: cover;
  }
}

@media screen and (max-width: 425px) {
  .sponsorships .sponsors-slide {
    background-size: contain;
  }
}

@media screen and (max-width: 414px) {
  .sponsorships .sponsors-slide {
    background-size: contain;
  }
}

@media screen and (max-width: 375px) {
  .sponsorships .sponsors-slide {
    height: 165px;
  }
}

@media screen and (max-width: 320px) {
  .sponsorships .sponsors-slide {
    background-size: contain;
    height: 145px;
  }
}

.sponsorships .sponsors-slider {
  margin-top: 65px;
}

.sponsorships .next-button,
.sponsorships .prev-button {
  position: absolute;
  cursor: pointer;
}

.sponsorships .next-button i,
.sponsorships .prev-button i {
  color: black;
}

.sponsorships .prev-button {
  top: 50%;
  transform: translateY(-50%);
  left: -80px;
}

@media screen and (max-width: 1024px) {
  .sponsorships .prev-button {
    left: -28px;
  }
}

@media screen and (max-width: 768px) {
  .sponsorships .prev-button {
    left: -65px;
  }
}

@media screen and (max-width: 480px) {
  .sponsorships .prev-button {
    left: 150px;
    top: 150px;
  }
}

@media screen and (max-width: 320px) {
  .sponsorships .prev-button {
    left: 90px;
  }
}

.sponsorships .next-button {
  top: 50%;
  transform: translateY(-50%);
  right: -80px;
}

@media screen and (max-width: 1024px) {
  .sponsorships .next-button {
    right: -28px;
  }
}

@media screen and (max-width: 768px) {
  .sponsorships .next-button {
    right: -65px;
  }
}

@media screen and (max-width: 480px) {
  .sponsorships .next-button {
    right: 150px;
    top: 150px;
  }
}

@media screen and (max-width: 320px) {
  .sponsorships .next-button {
    right: 90px;
  }
}

@media screen and (max-width: 375px) {
  .sponsorships .next-button,
  .sponsorships .prev-button {
    top: 190px;
  }
}

@media screen and (max-width: 320px) {
  .sponsorships .next-button,
  .sponsorships .prev-button {
    top: 165px;
  }
}

.login-box {
  margin-top: 30px;
  background-color: white;
  border-radius: 7px;
}

.login-box .login-box-intro {
  padding-top: 15px;
  margin-bottom: 15px;
}

.login-box .login-box-intro h4 {
  margin-bottom: 0;
}

.login-box .login-box-intro .register {
  color: #323232;
  text-decoration: underline;
}

.login-box .login-box-intro a {
  display: block;
}

.login-box .label-holder {
  background-color: #f6f7ff;
  color: #10143e;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 15px 0 15px 10px;
}

.login-box .label-holder label {
  margin-bottom: 0;
}

.login-box .input-holder {
  padding-right: 0.8rem;
}

.login-box .input-holder input {
  padding: .5rem 0 .6rem;
  padding-left: 0.8rem;
  border: none;
}

.login-box .input-holder input[type="password"] {
  border-bottom: 1px solid #e5e5e5;
}

.login-box form {
  margin-bottom: 30px;
}

.login-box .login-box-footer {
  padding-top: 38px;
  padding-bottom: 25px;
  background-color: #f6f7ff;
  border-radius: 7px;
}

.login-box .login-box-footer .sign-in-btn {
  padding: 7px 0px;
  font-weight: lighter;
}

.login-box .login-box-footer .forgot-credentials {
  font-size: 15px;
  color: #323232;
  margin-top: 30px;
  display: block;
  text-decoration: underline;
}

.trade-area .container {
  background: white;
  margin-top: -100px;
  border-radius: 25px;
}

@media (max-width: 600px) {
  .trade-area .container {
    margin: -100px 20px 0;
    width: calc(100% - 40px);
    border-top: 50px solid #d9bd45;
    border-radius: 25px;
  }
  .trade-area .container main {
    padding: 15px;
  }
  .trade-area .container main h2 {
    margin: 15px 0;
    text-align: center;
  }
}

.trade-area main {
  padding: 50px;
}

.trade-area aside .title {
  height: 50px;
  background: #d9bd45;
  border-radius: 25px 0 0 0;
  color: white;
  line-height: 50px;
  padding-left: 20px;
  font-family: "GothamBold", sans-serif;
  font-size: 21px;
  font-weight: 800;
}

.trade-area aside .title a {
  font-size: 16px;
  font-weight: normal;
  color: white;
}

.trade-area aside .title a:hover span {
  text-decoration: underline;
}

.trade-area aside nav h2.menu-heading {
  font-size: 18px;
  text-transform: unset;
  color: #10143e;
  padding-top: 25px;
  padding-bottom: 10px;
}

.trade-area aside nav > ul {
  margin: 20px 0;
  padding: 0;
}

.trade-area aside nav > ul > li > a {
  position: relative;
}

.trade-area aside nav > ul > li > a:before {
  font-family: 'Font Awesome 5 Pro';
  content: '\f054';
  margin-right: 10px;
  font-size: 15px;
  position: relative;
  top: 2px;
}

.trade-area aside nav > ul > li > a:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 10px;
  width: calc(100% - 20px);
  height: 1px;
  background: #10143e;
}

.trade-area aside nav > ul > li > a.no-icon:before {
  display: none;
}

.trade-area aside nav > ul > li ul {
  padding-left: 35px;
}

.trade-area aside nav > ul > li ul li ul {
  padding-left: 20px;
}

.trade-area aside nav > ul > li ul li ul li {
  position: relative;
}

.trade-area aside nav > ul > li ul li ul li a:before {
  content: "-";
  position: absolute;
  top: 10px;
  left: -10px;
}

.trade-area aside nav > ul > li a {
  color: rgba(16, 20, 62, 0.5);
  padding: 10px 0;
  display: block;
}

.trade-area aside nav > ul > li a.has-child {
  border-bottom: 0;
}

.trade-area aside nav > ul > li a.has-child:after {
  display: none;
}

.trade-area aside nav > ul > li a.active {
  color: #10143e;
  border-bottom: 0;
  font-weight: bolder;
}

.trade-area aside nav > ul > li a.active.has-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.trade-area aside nav > ul > li a.active ul {
  padding-left: 35px;
}

.trade-area aside nav > ul > li a:hover {
  color: #10143e;
}

.trade-area aside nav > ul > li:last-child a {
  border: none;
}

.trade-area aside nav > ul > li:last-child a:after {
  display: none;
}

.trade-area aside nav > ul > li.has-child {
  padding-bottom: 20px;
}

.trade-area aside nav > ul > li.has-child > a:first-of-type {
  background: #f6f7ff;
  padding: 0px 0px 4px;
}

.trade-area aside nav li {
  list-style: none;
}

.trade-area aside nav li li a {
  padding-bottom: 0;
}

.trade-area aside nav li i {
  display: none;
}

@media (max-width: 600px) {
  .trade-area aside {
    border-top: none;
  }
}

.trade-area form {
  background: #f6f7ff;
  padding: 20px 30px;
}

@media screen and (max-width: 768px) {
  .trade-area form {
    padding: 15px;
  }
}

.trade-area form .form-group {
  clear: both;
}

.trade-area form .form-group label {
  font-weight: normal;
  width: 160px;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .trade-area form .form-group label {
    width: 100%;
    display: block;
  }
}

.trade-area form .form-group input,
.trade-area form .form-group textarea,
.trade-area form .form-group select,
.trade-area form .form-group .other-text,
.trade-area form .form-group .map {
  display: inline-block;
  width: calc(100% - 165px);
  box-sizing: border-box;
  vertical-align: middle;
  float: right;
}

@media screen and (max-width: 768px) {
  .trade-area form .form-group input,
  .trade-area form .form-group textarea,
  .trade-area form .form-group select,
  .trade-area form .form-group .other-text,
  .trade-area form .form-group .map {
    width: 100%;
    float: none;
    display: block;
    max-width: 100%;
  }
}

.trade-area form .form-group .map {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.trade-area form .form-group textarea {
  vertical-align: top;
  height: auto;
}

.trade-area form .form-group.address input {
  margin-bottom: 1rem;
}

.grey-wrapper {
  background: #cfd0d8;
  padding-bottom: 50px;
}

.news-card {
  margin-bottom: 30px;
}

.news-card .news-card-date {
  display: block;
  margin-bottom: 10px;
}

.news-card .news-card-content {
  background: #10143e;
  margin-bottom: 10px;
  padding: 15px;
}

.news-card .news-card-content a {
  font-weight: bold;
}

.news-card .news-card-thumbnail {
  min-height: 200px;
  width: 100%;
  background: no-repeat center center;
  background-size: cover;
}

.news-card-first {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .news-card-first a.img-holder {
    display: block;
    height: 365px;
  }
}

.news-card-first .news-card-first-content {
  padding: 30px;
  float: left;
}

.news-card-first .news-card-first-content a.btn {
  width: 165px;
  padding: 7px;
  margin-bottom: 20px;
}

.news-card-first .news-card-first-content .news-card-first-date {
  display: block;
}

.news-card-first .news-card-first-content .news-card-first-excerpt, .news-card-first .news-card-first-content .news-card-first-date, .news-card-first .news-card-first-content h3 {
  margin-bottom: 20px;
}

.news-card-first .news-card-first-thumbnail {
  float: left;
  background: no-repeat center center;
  background-size: cover;
  height: 100%;
}

.careers-card {
  padding: 15px;
  background-color: #f6f7ff;
  margin-bottom: 15px;
  height: 100%;
}

.careers-card h4 {
  font-family: "AgendaBold", sans-serif;
}

.careers-single-page form {
  background-color: #f6f7ff;
  padding: 20px;
}

.careers-single-page form textarea {
  height: auto;
}

.application-form {
  border-radius: 3px;
  margin-bottom: 5rem;
}

.application-form .blue-header {
  background: #f6f7ff;
  color: #2f4954;
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 3px 3px 0 0;
}

.application-form form {
  padding: 0.75rem 1.5rem;
}

.application-form form .alert {
  margin-bottom: 0;
}

.application-form form .hidden {
  display: none;
}

.application-form form .form-group .error {
  color: red;
  font-size: 14px;
  margin-top: -10px;
  display: block;
}

.application-form form .form-group label {
  color: #2f4954;
  font-size: 0.875rem;
  margin-bottom: 0;
  display: block;
}

.application-form form .form-group input.hidden-input {
  opacity: 0;
  width: 0;
  height: 0;
  z-index: -99999;
  pointer-events: none;
  overflow: hidden;
  display: none;
}

.application-form form .form-group label.file-label {
  margin-top: 10px;
  background: #d9bd45;
  padding: 0;
  width: 200px;
  height: 50px;
  line-height: 50px;
  margin-left: 0;
  border-radius: 5px;
}

.application-form form .form-group label.file-label .fal {
  margin-right: 10px;
}

.application-form form .form-group label.file-label:hover {
  background: rgba(217, 189, 69, 0.8);
  box-shadow: none;
}

.application-form form .form-footer {
  text-align: right;
}

.application-form form .form-footer input[type="submit"] {
  margin: 0 auto;
}

.stockists > .container {
  margin: 50px auto;
  margin-bottom: 50px;
}

.stockists .form-group {
  margin-bottom: 0;
}

.stockists .town-input {
  background-image: url("/dod/images/map-crosshair.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 17px 25px !important;
  padding-left: 50px !important;
  background-position: 15px center !important;
  height: 50px !important;
  line-height: 50px !important;
  max-width: 100% !important;
}

.stockists .town-input.searching {
  background-image: url("/dod/images/location-searching.gif") !important;
  background-size: 25px 17px !important;
}

.stockists .intro-section h2 {
  text-align: center;
  color: #10143e;
}

.stockists .search-group {
  position: relative;
}

.stockists .search-group #getCurrentLocation {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background: #70728b;
  opacity: 0;
  border: 0;
  cursor: pointer;
  z-index: 20;
  color: transparent;
  padding: 0;
}

.stockists .search-group #getCurrentLocation:hover {
  opacity: 0.2;
}

.stockists .search-group input.town-input {
  width: 90%;
  display: inline-block;
  border: 1px solid #10143e;
}

.stockists .search-group .input-group-append {
  width: 10%;
  display: inline-block;
}

.stockists .search-group .input-group-append .btn {
  padding: 0.95rem 0;
}

.stockists .form-wrap {
  padding-top: 15px;
  padding-bottom: 15px;
}

.stockists .form-wrap input {
  max-width: 100%;
  box-sizing: border-box;
  height: 45px;
  line-height: 50px;
  border: 1px solid #10143e;
}

.stockists .form-wrap select {
  border: 1px solid #10143e;
}

.stockists .map-wrap {
  position: relative;
}

.stockists .map-wrap .loading-block {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.stockists .map-wrap .loading-block #loading-circle {
  display: block;
  width: 80px;
  height: 80px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.stockists .map-wrap .loading-block #loading-circle:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #10143e transparent #10143e transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 900px) {
  .stockists .map-wrap {
    display: flex;
    flex-direction: column-reverse;
  }
}

.stockists .map-wrap.open .stockist-list {
  width: 400px;
  overflow: scroll;
}

.stockists .map-wrap.open .map {
  width: calc(100% - 400px);
}

.stockists .map-wrap .stockist-list {
  height: 625px;
  width: 0;
  overflow: hidden;
  float: left;
}

.stockists .map-wrap .stockist-list .results-heading {
  font-size: 14px;
  padding: 0 20px;
}

.stockists .map-wrap .stockist-list .show-all {
  display: none;
  font-size: 14px;
  padding: 8px;
  width: 100%;
}

.stockists .map-wrap .stockist-list h3 {
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solic #10143e;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.stockists .map-wrap .stockist-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.stockists .map-wrap .stockist-list ul li {
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
}

.stockists .map-wrap .stockist-list ul li .other-details {
  padding-left: 35px;
}

.stockists .map-wrap .stockist-list ul li .address {
  min-height: 55px;
}

.stockists .map-wrap .stockist-list ul li:hover {
  background: rgba(112, 114, 139, 0.05);
}

.stockists .map-wrap .stockist-list ul li.active {
  background: rgba(112, 114, 139, 0.2);
}

.stockists .map-wrap .stockist-list ul li h4 {
  font-size: 16px;
  font-weight: bold;
  color: #10143e;
}

.stockists .map-wrap .stockist-list ul li i {
  color: white;
  background: url("/dod/images/map-marker-stockists.svg");
  width: 24px;
  height: 55px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 3px;
  font-style: normal;
  font-weight: 900;
  font-family: "Hind", sans-serif;
  margin-right: 10px;
  float: left;
}

.stockists .map-wrap .stockist-list ul li .address {
  height: 0;
  max-height: 400px;
  overflow: hidden;
  transition: height 0.5s;
  height: 1.4em;
}

.stockists .map-wrap .map {
  height: 625px;
}

@media (max-width: 900px) {
  .stockists .map-wrap.open .stockist-list {
    height: 300px;
    width: 100%;
  }
  .stockists .map-wrap.open .stockist-list li {
    width: 50%;
    float: left;
  }
  .stockists .map-wrap.open .map {
    width: 100%;
  }
  .stockists .map-wrap .stockist-list {
    width: 100%;
    height: 0;
  }
}

@media (max-width: 576px) {
  .stockists .map-wrap.open .stockist-list {
    height: 600px;
    width: 100%;
  }
  .stockists .map-wrap.open .stockist-list li {
    width: 100%;
    float: left;
  }
}

.stockists #markerContent {
  background: #10143e;
  color: white;
  min-width: 400px;
  font-size: 16px;
  padding: 20px;
}

.stockists #markerContent p {
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 0;
}

.stockists #markerContent #firstHeading {
  font-size: 18px;
}

.stockists #markerContent a {
  color: white;
}

.stockists #markerContent a:hover {
  text-decoration: underline;
}

.stockists .gm-style-iw.gm-style-iw-c {
  padding: 0 !important;
  border-radius: 0 !important;
}

.stockists .gm-style .gm-style-iw-d {
  box-sizing: border-box;
  overflow: auto !important;
  border-radius: 0 !important;
}

.stockists button.gm-ui-hover-effect:before {
  content: '×';
  color: white;
  font-size: 25px;
  position: relative;
  top: 5px;
  right: 3px;
}

.stockists button.gm-ui-hover-effect img {
  display: none;
}

.stockists .gm-style .gm-style-iw-t::after {
  background: linear-gradient(45deg, #10143e 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
}

.stockists .results-search .content {
  font-size: 14px;
}

.stockists .results-search #countryName {
  color: #10143e;
  font-weight: bold;
}

.stockists .results-search select {
  margin-top: 5px;
  padding: 8px;
  padding-right: 25px;
  font-size: 16px;
  max-width: 100% !important;
  width: 100%;
  border: 1px solid #10143e;
}

.stockists .results-search .search-group .town-input {
  padding-left: 50px !important;
  background-position: 15px center !important;
  height: 25px !important;
  line-height: 25px !important;
  max-width: 100% !important;
  width: auto;
}

.stockists .results-search .search-group .input-group-append {
  width: 13%;
}

.stockists .results-search .search-group .input-group-append .btn {
  padding: 13px 0;
  margin: 0;
  margin-top: 3px;
}

/**
    Partials
 */
/**
    Pages
 */
.page-404 {
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;
}

.home-page {
  overflow-x: hidden;
}

.home-page .light-page-heading p {
  font-weight: 600;
}

.home-page h2 {
  font-weight: 900;
}

.home-page .image-with-text {
  background-repeat: no-repeat;
  background-position: 0 0;
  padding-bottom: 100px;
}

.home-page .advice-center {
  position: relative;
  color: white;
  font-weight: 400;
  word-break: break-word;
}

.home-page .advice-center a {
  color: white;
  text-decoration: underline;
}

.home-page .advice-center strong {
  font-family: "GothamBold", sans-serif;
}

.home-page .advice-center select {
  width: 100%;
  background: #10143e;
  color: white;
  padding: 10px;
  font-family: "GothamBold", sans-serif;
  font-weight: 900;
  max-width: 250px;
  border: 2px solid #d9bd45;
}

@media screen and (max-width: 768px) {
  .home-page .advice-center select {
    max-width: unset;
  }
}

.home-page .advice-center h2 {
  font-weight: 900;
}

.home-page .advice-center img {
  width: 100%;
  border: 10px solid #cfd0d8;
}

.home-page .advice-center .right-content-image {
  width: 100%;
  border: 10px solid #cfd0d8;
}

.home-page .advice-center {
  padding-bottom: 150px;
}

@media screen and (max-width: 768px) {
  .home-page .values {
    transform: translateY(-30px);
  }
}

.home-page .feed-room-blocks {
  margin-top: -150px;
}

@media screen and (max-width: 768px) {
  .home-page .feed-room-blocks {
    margin-top: 0;
    transform: translateY(-120px);
  }
}

.home-page .feed-room-blocks .feed-room-block {
  padding: 20px 10px;
  background: white;
  color: #10143e;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border: 10px solid #cfd0d8;
}

.home-page .feed-room-blocks .feed-room-block h4 {
  font-weight: 900;
  font-family: "GothamBold", sans-serif;
  margin-bottom: 10px;
}

.home-page .feed-room-blocks .feed-room-block p {
  margin-bottom: 20px;
  font-weight: 400;
}

.home-page .feed-room-blocks .feed-room-block .btn {
  display: inline-block;
  width: auto;
  margin-top: 10px;
}

.home-page .logo-slider {
  margin: 0 auto;
  justify-content: center;
  gap: 2rem;
}

.home-page .logo-slider .single-logo img {
  height: 100px;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
}

.home-page .light-page-heading {
  height: auto;
  padding-bottom: 50px;
  background: white;
}

.home-page .categories-row .category-button {
  background: white;
  cursor: pointer;
  transition: all ease 0.25s;
}

.home-page .categories-row .category-button:hover, .home-page .categories-row .category-button.active {
  color: white;
  background: #70728b;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.home-page .slick-arrow {
  background: transparent;
  border: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 28px;
  cursor: pointer;
}

.home-page .slick-arrow:before {
  color: #10143e;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

.home-page .slick-arrow.slick-prev {
  z-index: 4;
}

.home-page .slick-arrow.slick-prev:before {
  content: '\f053';
}

.home-page .slick-arrow.slick-next {
  z-index: 4;
  right: 0;
  left: auto;
}

.home-page .slick-arrow.slick-next:before {
  content: '\f054';
}

.home-page .products-slider .products--link-block {
  margin-bottom: 0;
}

.home-page .products-slider .products--link-block img {
  margin: 0 auto !important;
}

@media screen and (max-width: 768px) {
  .home-page .products-slider .products--link-block img {
    max-width: 200px;
    height: auto;
  }
}

.home-page .products-slider .products--link-block .btn {
  border: 2px solid #10143e;
  color: #10143e !important;
  font-size: 16px;
  font-weight: bold;
}

.home-page .products-slider .slick-dots li {
  margin-left: 5px;
  margin-right: 5px;
  width: 10px;
  height: 10px;
}

.home-page .products-slider .slick-dots li:after {
  display: none;
}

.home-page .products-slider .slick-dots li button {
  background: transparent;
  border: 1px solid #10143e;
  width: 10px;
  height: 10px;
}

.home-page .products-slider .slick-dots li.slick-active button {
  background: #10143e;
}

.home-page .has-blue-box {
  position: relative;
  margin-bottom: 100px;
}

.home-page .has-blue-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #f6f7ff;
  height: 65%;
}

.home-page .has-blue-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%;
  background: transparent;
  pointer-events: none;
  height: 105%;
  z-index: 1;
  border: 10px solid #f6f7ff;
}

@media screen and (max-width: 1024px) {
  .home-page .has-blue-box:after {
    display: none;
  }
}

.home-page .has-blue-box .container {
  z-index: 2;
  position: relative;
}

.home-page .products-button .btn {
  display: block;
  margin: 0 auto;
  margin-top: 80px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  width: 300px;
  font-size: 18px;
  background: transparent;
  border: 2px solid #10143e;
  color: #10143e !important;
}

.home-page .products-button .btn:hover {
  color: white !important;
  background-color: #10143e;
}

.home-page .pl-0 {
  padding-left: 0;
}

@media screen and (max-width: 768px) {
  .home-page .pl-0 {
    padding-left: 15px !important;
  }
}

.home-page .factsheet-block {
  border: 3px solid transparent;
  padding-bottom: 50px !important;
  position: relative;
}

.home-page .factsheet-block:hover {
  border: 3px solid #d9bd45;
  background: #10143e !important;
}

.home-page .factsheet-block:hover .text-darkBlue {
  color: white !important;
}

.home-page .factsheet-block .btn {
  position: absolute;
  bottom: 15px;
  left: 34%;
  width: 62% !important;
}

@media screen and (max-width: 768px) {
  .home-page .factsheet-block .btn {
    width: calc(100% - 30px) !important;
    left: 15px;
  }
}

.home-page #loading-circle {
  display: block;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}

.home-page #loading-circle:before {
  content: "Loading products...";
  text-align: center;
  font-size: 14px;
}

.home-page #loading-circle:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #10143e transparent #10143e transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 992px) {
  .home-page .blocks-row h4 {
    font-size: 16px;
  }
  .home-page .blocks-row .btn {
    font-size: 14px;
  }
}

.has-blue-border {
  border: 10px solid #cfd0d8;
}

.trade-area .container {
  background: white;
  margin-top: -100px;
  border-radius: 25px;
}

@media (max-width: 600px) {
  .trade-area .container {
    margin: -100px 20px 0;
    width: calc(100% - 40px);
    border-top: 50px solid #d9bd45;
    border-radius: 25px;
  }
  .trade-area .container main {
    padding: 15px;
  }
  .trade-area .container main h2 {
    margin: 15px 0;
    text-align: center;
  }
}

.trade-area main {
  padding: 50px;
}

.trade-area aside .title {
  height: 50px;
  background: #d9bd45;
  border-radius: 25px 0 0 0;
  color: white;
  line-height: 50px;
  padding-left: 20px;
  font-family: "GothamBold", sans-serif;
  font-size: 21px;
  font-weight: 800;
}

.trade-area aside .title a {
  font-size: 16px;
  font-weight: normal;
  color: white;
}

.trade-area aside .title a:hover span {
  text-decoration: underline;
}

.trade-area aside nav h2.menu-heading {
  font-size: 18px;
  text-transform: unset;
  color: #10143e;
  padding-top: 25px;
  padding-bottom: 10px;
}

.trade-area aside nav > ul {
  margin: 20px 0;
  padding: 0;
}

.trade-area aside nav > ul > li > a {
  position: relative;
}

.trade-area aside nav > ul > li > a:before {
  font-family: 'Font Awesome 5 Pro';
  content: '\f054';
  margin-right: 10px;
  font-size: 15px;
  position: relative;
  top: 2px;
}

.trade-area aside nav > ul > li > a:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 10px;
  width: calc(100% - 20px);
  height: 1px;
  background: #10143e;
}

.trade-area aside nav > ul > li > a.no-icon:before {
  display: none;
}

.trade-area aside nav > ul > li ul {
  padding-left: 35px;
}

.trade-area aside nav > ul > li ul li ul {
  padding-left: 20px;
}

.trade-area aside nav > ul > li ul li ul li {
  position: relative;
}

.trade-area aside nav > ul > li ul li ul li a:before {
  content: "-";
  position: absolute;
  top: 10px;
  left: -10px;
}

.trade-area aside nav > ul > li a {
  color: rgba(16, 20, 62, 0.5);
  padding: 10px 0;
  display: block;
}

.trade-area aside nav > ul > li a.has-child {
  border-bottom: 0;
}

.trade-area aside nav > ul > li a.has-child:after {
  display: none;
}

.trade-area aside nav > ul > li a.active {
  color: #10143e;
  border-bottom: 0;
  font-weight: bolder;
}

.trade-area aside nav > ul > li a.active.has-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.trade-area aside nav > ul > li a.active ul {
  padding-left: 35px;
}

.trade-area aside nav > ul > li a:hover {
  color: #10143e;
}

.trade-area aside nav > ul > li:last-child a {
  border: none;
}

.trade-area aside nav > ul > li:last-child a:after {
  display: none;
}

.trade-area aside nav > ul > li.has-child {
  padding-bottom: 20px;
}

.trade-area aside nav > ul > li.has-child > a:first-of-type {
  background: #f6f7ff;
  padding: 0px 0px 4px;
}

.trade-area aside nav li {
  list-style: none;
}

.trade-area aside nav li li a {
  padding-bottom: 0;
}

.trade-area aside nav li i {
  display: none;
}

@media (max-width: 600px) {
  .trade-area aside {
    border-top: none;
  }
}

.trade-area form {
  background: #f6f7ff;
  padding: 20px 30px;
}

@media screen and (max-width: 768px) {
  .trade-area form {
    padding: 15px;
  }
}

.trade-area form .form-group {
  clear: both;
}

.trade-area form .form-group label {
  font-weight: normal;
  width: 160px;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .trade-area form .form-group label {
    width: 100%;
    display: block;
  }
}

.trade-area form .form-group input,
.trade-area form .form-group textarea,
.trade-area form .form-group select,
.trade-area form .form-group .other-text,
.trade-area form .form-group .map {
  display: inline-block;
  width: calc(100% - 165px);
  box-sizing: border-box;
  vertical-align: middle;
  float: right;
}

@media screen and (max-width: 768px) {
  .trade-area form .form-group input,
  .trade-area form .form-group textarea,
  .trade-area form .form-group select,
  .trade-area form .form-group .other-text,
  .trade-area form .form-group .map {
    width: 100%;
    float: none;
    display: block;
    max-width: 100%;
  }
}

.trade-area form .form-group .map {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.trade-area form .form-group textarea {
  vertical-align: top;
  height: auto;
}

.trade-area form .form-group.address input {
  margin-bottom: 1rem;
}

.grey-wrapper {
  background: #cfd0d8;
  padding-bottom: 50px;
}

.search-results .search-result {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 30px;
}

.search-results .search-result h3 {
  font-size: 24px;
  font-weight: bold;
  color: #10143e;
}

.search-results .search-result h3 a {
  color: #10143e;
  font-weight: 900;
}

.search-results .search-result .badge {
  box-shadow: none;
  background: #70728b;
  padding: 5px 10px;
  font-weight: 900;
  border-radius: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.search-results .search-result a {
  color: #d9bd45;
  font-weight: normal;
  text-decoration: underline;
}

.search-results .search-result a:hover {
  text-decoration: underline;
}

.search-page .page-title {
  font-size: 35px;
  margin-bottom: 20px;
}

.search-page .search-container {
  background: #f6f7ff;
  padding: 20px;
}

.search-page .search-container .form-group input:not([type="submit"]) {
  padding: 10px;
  max-width: 100%;
}

.search-page .filter-search {
  padding: 20px;
  padding-left: 0 !important;
}

.search-page .filter-search label {
  color: #10143e;
  font-weight: bold;
}

.search-page .filter-search select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #10143e;
  padding: 5px 10px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  background-image: url("/dod/images/down-arrow.svg");
  background-position: calc(100% - 15px) 54%;
  background-size: auto 16%;
  background-repeat: no-repeat;
  color: #10143e;
  border-radius: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-page .filter-search select {
    background-position: 190% 65%;
  }
}

.search-page .filter-search select:active, .search-page .filter-search select:focus {
  border-color: #10143e !important;
  box-shadow: none !important;
  outline: none;
}

.search-page .filter-search select.has-error {
  border-color: #F00;
}

.products-landing-page {
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 200px;
}

.products-landing-page h2.page-title {
  margin-bottom: 50px;
}

.products-landing-page .image-container {
  display: block;
  border: 5px solid #d9bd45;
  border-radius: 50%;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  transition: all ease 0.2s;
  background-size: cover;
  background-color: transparent;
  background-blend-mode: multiply;
  background-position: center center;
  box-shadow: 12px 12px 10px 0px rgba(0, 0, 0, 0.1);
}

.products-landing-page .image-container h2 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  color: white;
  opacity: 0;
  transition: all ease 0.2s;
}

.products-landing-page .image-container.hover-red {
  border-color: #e8274d;
}

.products-landing-page .image-container.hover-red:hover {
  background-color: #e8274d;
}

.products-landing-page .image-container.hover-green {
  border-color: #6bc9bf;
}

.products-landing-page .image-container.hover-green:hover {
  background-color: #6bc9bf;
}

.products-landing-page .image-container:hover {
  transform: scale(1.2);
  z-index: 2;
}

.products-landing-page .image-container:hover h2 {
  opacity: 1;
}

@media (max-width: 769px) {
  .products-landing-page .image-container {
    margin-top: 50px;
    margin-bottom: 15px;
  }
  .products-landing-page .image-container:hover {
    transform: scale(1);
  }
  .products-landing-page .image-container h2 {
    display: none;
  }
}

.products-landing-page:after {
  content: "";
  width: 500px;
  height: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  right: -100px;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.equine-products-page .breadcrumbs {
  margin: 20px 0 20px;
}

.canine-product-overview-page .breadcrumbs {
  margin: 20px 0 20px;
}

.what-goes-in-page .page-heading-alt {
  background-color: #f6f7ff;
  padding-bottom: 50px;
}

.what-goes-in-page .item-row {
  border-bottom: 1px solid #323232;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.what-goes-in-page .item-row p {
  margin-bottom: 0;
}

.what-goes-in-page .item-row img {
  width: 123px;
  height: 123px;
}

.what-goes-in-page .references-text {
  font-size: 14px;
}

.what-goes-in-page .references-text p {
  font-size: inherit;
}

.trade-login-page {
  background-image: url("/dod/images/trade-login-background-image.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
}

#registerModal .modal-header,
#forgotPasswordModal .modal-header {
  justify-content: space-around;
}

#registerModal .modal-header p,
#forgotPasswordModal .modal-header p {
  margin: 0;
}

#registerModal button.close,
#forgotPasswordModal button.close {
  float: none;
  position: absolute;
  right: 25px;
  top: 10px;
}

#registerModal .modal-body,
#forgotPasswordModal .modal-body {
  background-color: #f6f7ff;
}

#registerModal .modal-body label,
#forgotPasswordModal .modal-body label {
  font-weight: bold;
}

#registerModal .modal-body input,
#forgotPasswordModal .modal-body input {
  background-color: white;
  border-bottom: none;
}

.code-of-conduct-page {
  margin-top: 30px;
}

.code-of-conduct-page h1,
.code-of-conduct-page h2 {
  color: #10143e;
}

.standard-tc-page {
  margin-top: 30px;
}

.standard-tc-page h1,
.standard-tc-page h2 {
  color: #10143e;
}

.tc-purchase-page {
  margin-top: 30px;
}

.tc-purchase-page h1,
.tc-purchase-page h2 {
  color: #10143e;
}

.security-page {
  margin-top: 30px;
}

.security-page h1,
.security-page h2 {
  color: #10143e;
}

.terms-conditions-page {
  margin-top: 30px;
}

.terms-conditions-page h1,
.terms-conditions-page h2 {
  color: #10143e;
}

.privacy-policy-page {
  margin-top: 30px;
}

.privacy-policy-page h1,
.privacy-policy-page h2 {
  color: #10143e;
}

.trade-area-landing a.btn {
  padding: .85rem 1.13rem;
}

.trade-area-landing .trade-area-link {
  margin-top: 45px;
}

.trade-area-landing .page-heading-alt .featured-image {
  height: 140px;
}

.trade-area .listing-accordion .accordion-header {
  font-size: 24px;
  text-transform: unset;
  color: #10143e;
  border-bottom: 1px solid #10143e;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
  cursor: pointer;
}

.trade-area .listing-accordion .accordion-header:after {
  content: "+";
  font-weight: normal;
  position: absolute;
  top: 55%;
  right: 0;
  transform: translateY(-50%);
}

.trade-area .listing-accordion .accordion-header.open:after {
  content: "-";
}

.trade-area .listing-accordion .accordion-body {
  display: none;
  padding-left: 15px;
  padding-right: 15px;
}

.trade-area .listing-accordion .accordion-body.open {
  display: block;
}

.team-members-page .circle-hero-image {
  display: none;
}

.team-members-page .circle-image-header .breadcrumbs {
  margin-left: -15px;
}

.team-members-page .team-member-block {
  margin-bottom: 30px;
  background: #f6f7ff;
}

.team-members-page .team-member-block .content {
  padding: 15px;
}

.team-members-page .team-member-block .content a {
  font-weight: bold;
  text-decoration: none;
  color: #10143e;
}

.team-members-page .team-member-block .content a:hover {
  text-decoration: underline;
}

.team-members-page .employees-list .employee-thumbnail {
  border-radius: 0;
  box-shadow: unset;
  width: 100%;
  background-position: center center;
  background-size: cover;
}

.nutritional-helpline-page .advisors-section {
  position: relative;
}

.nutritional-helpline-page .advisors-section:after {
  content: "";
  width: 420px;
  height: 590px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -200px;
  left: 0;
  z-index: 1;
}

.stockists-list .form-container {
  margin-bottom: 30px;
  margin-top: -45px;
}

.stockists-list .form-container .map-link {
  font-size: 16px;
  color: #10143e;
  margin-top: -20px;
  margin-bottom: 10px;
}

.stockists-list .form-container form {
  background: rgba(112, 114, 139, 0.1);
  padding: 15px;
  padding-bottom: 0;
}

.stockists-list .form-container form .waves-input-wrapper {
  max-width: 100%;
  overflow: initial;
}

.stockists-list .form-container form input[type=text] {
  max-width: 100%;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  background: white;
  padding-left: 15px;
  padding-right: 15px;
  border: 0;
}

.stockists-list .form-container form input[type=submit] {
  height: 50px;
  border-radius: 0 !important;
}

.stockists-list .form-container form select {
  border: 1px solid #10143e;
}

.stockists-list .page-heading-alt {
  background-color: #f6f7ff;
  padding-bottom: 30px;
}

.stockists-list .tab-container {
  display: none;
}

.stockists-list .tab-container.active {
  display: block;
}

.stockists-list ul.letters-list {
  padding: 0;
  list-style-type: none;
}

.stockists-list ul.letters-list li {
  background-color: white;
  text-align: center;
  box-shadow: #909090 0px 1px 5px 0;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  text-transform: uppercase;
}

.stockists-list ul.letters-list li.active {
  position: relative;
  background-color: #d9bd45;
  cursor: default;
}

.stockists-list ul.letters-list li.active:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid #d9bd45;
}

@media screen and (max-width: 480px) {
  .stockists-list ul.letters-list li.active:after {
    display: none;
  }
}

.stockists-list ul.letters-list li.active a {
  color: white;
}

.stockists-list ul.letters-list li a {
  color: #10143e;
  padding: 12px 0;
  display: block;
}

.stockists-list .stockist-accordion {
  margin-top: 35px;
  margin-bottom: 30px;
}

.stockists-list .stockist-accordion .stockist-container {
  padding: 20px 0;
  position: relative;
}

.stockists-list .stockist-accordion .stockist-container:after {
  content: "";
  position: absolute;
  bottom: 0;
  border-bottom: 2px solid #323232;
  width: calc(100% - 15px);
  left: 0px;
}

.stockists-list .stockist-accordion .stockist-container h5 {
  margin-bottom: 0;
  font-family: "GothamBold", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

.stockists-list .stockist-accordion .stockist-container .accordion-expand {
  float: right;
  margin-right: 15px;
}

.stockists-list .stockist-accordion .stockist-container .stockist-content.open {
  max-height: 100%;
  overflow: auto;
  margin-top: 20px;
}

.stockists-list .stockist-accordion .stockist-container .stockist-content.closed {
  max-height: 0;
  overflow: hidden;
}

.newsletter-signup-form .relative {
  position: relative;
}

.newsletter-signup-form .relative .featured-image {
  position: absolute;
  bottom: -20px;
  right: 0;
  width: 45%;
}

@media screen and (max-width: 768px) {
  .newsletter-signup-form .relative .featured-image {
    width: 100%;
    position: static;
  }
}

.newsletter-signup-form ul.subjects {
  list-style-type: none;
  padding-left: 0;
  margin: 0 !important;
}

.newsletter-signup-form ul.subjects li {
  padding-top: 10px;
  padding-bottom: 10px;
}

.newsletter-signup-form ul.subjects li label {
  display: block;
  width: 100%;
}

.newsletter-signup-form ul.subjects li input, .newsletter-signup-form ul.subjects li span {
  display: inline-block;
  vertical-align: middle;
}

.newsletter-signup-form ul.subjects li input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.newsletter-signup-form ul.subjects li span {
  font-weight: normal !important;
}

.news-page {
  margin-bottom: 30px;
}

.news-page .heading-container {
  background: #f6f7ff;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.news-page .heading-container .breadcrumbs {
  margin: 0 0 20px;
}

.news-single-page .news-single-banner,
.news-single-page .article-content {
  padding: 18px;
}

.news-single-page .breadcrumbs {
  display: none;
}

.news-single-page .news-single-banner {
  background-color: #f6f7ff;
}

.news-single-page .news-single-banner p {
  margin-bottom: 0;
}

.news-single-page .news-article-footer {
  margin-bottom: 50px;
}

.news-single-page .article-btn {
  padding: .85rem 0;
}

.news-single-page .article-btn a {
  color: white;
}

.social-link {
  margin: 10px;
  font-size: 28px;
}

.careers-listing-page .circle-image-header {
  margin-bottom: 125px;
}

.careers-listing-page .page-heading-alt {
  background-color: #f6f7ff;
}

.careers-listing-page .no-vacancies {
  font-family: "AgendaBold", sans-serif;
}

.careers-listing-page .page-content strong {
  font-family: "GothamBold", sans-serif;
  font-size: 17px;
}

.careers-single-page h3 {
  font-family: "GothamBold", sans-serif;
  color: #10143e;
}

#cvModal .modal-dialog {
  top: 50%;
  transform: translateY(-50%);
}

.faqs-listing-page .page-heading-alt {
  background-color: #f6f7ff;
  padding-bottom: 30px;
}

.faqs-listing-page ul.faq-list {
  padding: 0;
  list-style-type: none;
}

.faqs-listing-page ul.faq-list li {
  background-color: white;
  text-align: center;
  box-shadow: #909090 0px 1px 5px 0;
  margin-bottom: 15px;
}

.faqs-listing-page ul.faq-list li.active-topic {
  position: relative;
  background-color: #d9bd45;
}

.faqs-listing-page ul.faq-list li.active-topic:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #d9bd45;
}

@media screen and (max-width: 480px) {
  .faqs-listing-page ul.faq-list li.active-topic:after {
    display: none;
  }
}

.faqs-listing-page ul.faq-list li.active-topic a {
  color: white;
}

.faqs-listing-page ul.faq-list li a {
  color: #10143e;
  padding: 12px 0;
  display: block;
}

.faqs-listing-page .faq-accordion {
  margin-top: 55px;
  margin-bottom: 30px;
}

.faqs-listing-page .faq-accordion .faq-question-container {
  padding: 20px 0;
  border-bottom: 2px solid #323232;
}

.faqs-listing-page .faq-accordion .faq-question-container:first-child {
  border-top: 2px solid #323232;
}

.faqs-listing-page .faq-accordion .faq-question-container h5 {
  margin-bottom: 0;
  font-family: "GothamBold", sans-serif;
}

.faqs-listing-page .faq-accordion .faq-question-container .accordion-expand {
  float: right;
  margin-right: 15px;
}

.faqs-listing-page .faq-accordion .faq-question-container .faq-content.open {
  max-height: 100%;
  overflow: auto;
  margin-top: 20px;
}

.faqs-listing-page .faq-accordion .faq-question-container .faq-content.closed {
  max-height: 0;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .category-single h1 {
    font-size: 30px;
  }
}

.category-single .page-intro .breadcrumbs {
  display: none;
}

.category-single .page-intro .content-container {
  margin-top: 90px;
}

@media (max-width: 1024px) {
  .category-single .page-intro .image {
    top: 300px;
    right: -180px;
  }
  .category-single .page-intro .image .image-container {
    width: 330px !important;
    height: 330px !important;
  }
}

@media screen and (max-width: 425px) {
  .category-single .page-intro .image {
    top: 625px;
    right: -100px;
  }
}

@media screen and (max-width: 375px) {
  .category-single .page-intro .image {
    top: 750px;
    right: -80px;
  }
  .category-single .page-intro .image-container {
    width: 250px !important;
    height: 250px !important;
  }
}

@media (max-width: 320px) {
  .category-single .page-intro .image {
    top: 772px;
    right: -93px;
  }
  .category-single .page-intro .image .image-container {
    width: 270px !important;
    height: 270px !important;
  }
}

.category-single .products--grid:after {
  display: none;
}

.category-single div.text-block {
  margin-bottom: 50px;
  position: relative;
}

.category-single div.text-block h2 {
  font-family: "GothamBold", sans-serif;
}

.category-single div.text-block p {
  font-weight: bold;
  color: #10143e;
}

.category-single div.text-block:after {
  content: "";
  position: absolute;
  right: -100px;
  top: -400px;
  background-repeat: no-repeat;
  background-position: 0 0;
  transform: rotate(-180deg);
  width: 400px;
  height: 800px;
  background-size: contain;
  opacity: 0.3;
}

.factsheets-listing-page h3.toggle-title {
  color: #10143e;
  font-size: 30px;
  text-transform: uppercase;
  font-family: "GothamBold", sans-serif;
  width: 100%;
  border-bottom: 1px solid #979797;
  position: relative;
  cursor: pointer;
}

.factsheets-listing-page h3.toggle-title:after {
  content: "-";
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 20px;
  transform: translateY(-50%);
}

.factsheets-listing-page h3.toggle-title.collapsed:after {
  content: "+";
}

.factsheet-generic-page .page-heading-alt {
  margin-bottom: 0;
}

.factsheet-generic-page .page-heading-alt .page-title h2 {
  margin-bottom: 0;
}

.factsheet-generic-page main h3,
.factsheet-generic-page main h4 {
  font-family: "GothamBold", sans-serif;
  color: #d9bd45;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.factsheet-generic-page main ul {
  padding-left: 15px;
}

.factsheet-generic-page main .contact-box {
  padding: 1.5rem;
  background-color: #10143e;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: -175px -125px;
}

.factsheet-generic-page main .contact-box .contact-box-title p {
  font-family: "GothamBold", sans-serif;
  color: white;
  font-size: 21px;
}

.factsheet-generic-page main .contact-box .contact-box-details {
  display: flex;
  align-items: center;
}

.factsheet-generic-page main .contact-box .contact-box-details .icon {
  width: 50px;
  margin-right: .5rem;
}

.factsheet-generic-page main .contact-box .contact-box-details .detail {
  margin-bottom: 1rem;
}

.factsheet-generic-page main .contact-box .contact-box-details .detail .detail-title,
.factsheet-generic-page main .contact-box .contact-box-details .detail .detail-main {
  font-family: "GothamBold", sans-serif;
}

.factsheet-generic-page main .contact-box .contact-box-details .detail p.detail-title {
  margin: 0;
  color: white;
}

.factsheet-generic-page main .contact-box .contact-box-details .detail .detail-main {
  color: #d9bd45;
  font-size: 22px;
}

.factsheet-generic-page main .side-images {
  margin-top: 2rem;
}

.factsheet-generic-page .related-products {
  position: relative;
  margin-top: 80px;
  background-color: #f6f7ff;
  padding: 2rem 0;
  background-repeat: no-repeat;
  background-position: right -466px;
  background-size: contain;
}

.factsheet-generic-page .related-products h2 {
  color: #10143e;
}

.factsheet-generic-page .related-products .related-products-slider .slick-list {
  display: flex;
}

.factsheet-generic-page .related-products .related-products-slider .slick-list .slick-track {
  display: flex;
}

.factsheet-generic-page .related-products .related-products-slider .slick-list .slick-track .related-product-slide {
  display: flex;
  align-items: center;
}

.factsheet-generic-page .related-products .related-products-slider .slick-list .slick-track .related-product-slide .products--link-block {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.factsheet-generic-page .related-products .related-products-slider .slick-list .slick-track .related-product-slide .products--link-block .image-container {
  margin-top: auto;
}

.factsheet-generic-page .related-products i.prev, .factsheet-generic-page .related-products i.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 28px;
  color: #10143e;
  cursor: pointer;
}

.factsheet-generic-page .related-products i.prev {
  left: 215px;
}

.factsheet-generic-page .related-products i.next {
  right: 215px;
}

.our-team-page .page-heading-alt {
  font-weight: bold;
  color: #10143e;
  background-color: #f6f7ff;
  margin-bottom: 0;
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .our-team-page .page-heading-alt .featured-image {
    height: 200px;
  }
}

@media screen and (max-width: 500px) {
  .our-team-page .page-heading-alt .featured-image {
    height: 130px;
  }
}

.our-team-page .page-heading-alt .page-content {
  padding-top: 30px;
}

.our-team-page .page-heading-alt .page-content p {
  font-size: 21px;
}

.our-team-page .team-title {
  border-bottom: 1px solid #10143e;
  color: #10143e;
  padding-bottom: 5px;
  font-weight: 900;
  margin-bottom: 10px;
}

.our-team-page .team-list {
  margin-top: 50px;
}

.our-team-page .member-block {
  background-size: cover;
  background-position: center center;
  width: 100%;
  position: relative;
}

.our-team-page .member-block:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.our-team-page .member-block .inner {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(16, 20, 62, 0.75);
  width: 100%;
  height: 100%;
  color: white;
  opacity: 0;
  pointer-events: none;
  transition: all ease-in-out 0.2s;
}

.our-team-page .member-block .inner .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  max-width: 100%;
}

.our-team-page .member-block .inner .content strong {
  font-weight: 900;
  font-size: 21px;
}

.our-team-page .member-block .inner .content a {
  color: white;
  text-decoration: none;
  font-weight: normal;
}

.our-team-page .member-block .inner .content a:hover {
  text-decoration: underline;
}

.our-team-page .member-block .inner .content p {
  font-size: 18px;
  margin-bottom: 0;
}

.our-team-page .member-block:hover .inner {
  opacity: 1;
  pointer-events: all;
}

.landing-form-page {
  padding-top: 50px;
  padding-bottom: 24px;
}

.landing-form-page .page-title {
  display: flex;
  width: 100%;
  font-size: 26px;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  font-family: "CaxtonTL-Book", sans-serif;
}

.landing-form-page .page-title img {
  width: 60px;
  margin-top: -6px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 0px !important;
}

.landing-form-page .horse-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
  margin-top: 16px;
  justify-content: center;
}

.landing-form-page .horse-row .horse-item-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 8px;
}

@media screen and (max-width: 800px) {
  .landing-form-page .horse-row .horse-item-container {
    width: 33.3333%;
  }
}

@media screen and (max-width: 600px) {
  .landing-form-page .horse-row .horse-item-container {
    width: 50%;
  }
}

.landing-form-page .horse-row .horse-item {
  display: flex;
  flex-direction: column;
  background: #f0f0f0;
}

.landing-form-page .horse-row .horse-item .content-container {
  background-color: #B19B6F;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 4px;
  text-align: center;
  align-items: center;
  font-size: 16px;
}

.landing-form-page .horse-row .horse-item .content-container p {
  padding: 0;
  font-size: 16px;
  margin-top: -4px;
  margin-bottom: 0 !important;
}

.landing-form-page .horse-row .horse-item .image-container {
  display: flex;
  width: 100%;
  aspect-ratio: 1/1.15;
  overflow: hidden;
}

.landing-form-page .horse-row .horse-item .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landing-form-page .main-content {
  color: #273261;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
}

.landing-form-page .main-content strong {
  font-family: 'AgendaBold';
}

.landing-form-page .embed-container img {
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  max-width: 200px;
}

.ambassadors-listing .ambassador-categories {
  margin-bottom: 50px;
}

.ambassadors-listing .link-block {
  background-size: 515px;
}

.ambassadors-single {
  margin-bottom: 60px;
}

.ambassadors-single h2 {
  color: #10143e;
}

.ambassadors-single .info {
  color: #10143e;
  background-color: #f6f7ff;
  padding: 20px;
  max-width: 80%;
  margin: 10px 0 20px;
}

.ambassadors-single .info p {
  margin: 0;
}

.ambassadors-single .info p span {
  font-family: "AgendaBold", sans-serif;
}

.ambassadors-single .content {
  margin-bottom: 50px;
}

.ambassadors-single aside img {
  width: 100%;
  height: auto;
  margin-bottom: 30px !important;
}

.ambassadors-single aside .quote {
  margin-top: 30px;
  background: #cfd0d8;
  color: #10143e;
  text-align: center;
  position: relative;
  padding: 40px;
}

.ambassadors-single aside .quote p:last-child {
  margin-bottom: 0;
}

.ambassadors-single aside .quote:before, .ambassadors-single aside .quote:after {
  display: block;
  text-align: left;
  font-size: 80px;
  position: absolute;
  line-height: 20px;
}

.ambassadors-single aside .quote:before {
  left: 10px;
  content: '“';
  top: 40px;
}

.ambassadors-single aside .quote:after {
  right: 10px;
  content: '”';
  bottom: -5px;
}

.ambassadors-single .products-loop {
  background: #f6f7ff;
}

.ambassadors-single .products-loop h2 {
  font-family: "GothamBlack", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 45px;
  margin-top: 30px;
  padding-top: 30px;
}

.ambassadors-single .products-loop .products--link-block .image-container {
  text-align: center;
}

.ambassadors-single .products-loop .products--link-block .image-container img {
  margin: 0 auto;
}

.nutrition-advice-centre-page .content-wrapper {
  position: relative;
}

.nutrition-advice-centre-page .content-wrapper img {
  margin-bottom: 0 !important;
}

.nutrition-advice-centre-page .content-wrapper:before {
  content: '';
  position: absolute;
  bottom: -6px;
  height: 5px;
  z-index: 9999;
}

.nutrition-advice-centre-page .content-wrapper:after {
  content: '';
  display: block;
  height: 5px;
  position: absolute;
  z-index: 9999;
  top: 0;
  width: calc(100% - 30px);
  left: 15px;
}

.nutrition-advice-centre-page .image-with-text {
  margin-bottom: 0;
  padding-bottom: 0;
}

.nutrition-advice-centre-page .image-with-text p {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .nutrition-advice-centre-page .image-with-text img {
    width: 100%;
  }
}

.nutrition-advice-centre-page .image-with-text h2 {
  margin-top: 15px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .nutrition-advice-centre-page .image-with-text h2 {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .nutrition-advice-centre-page .image-with-text h2,
  .nutrition-advice-centre-page .image-with-text p {
    text-align: left !important;
  }
}

.nutrition-advice-centre-page .image-with-text:nth-of-type(2) .content-wrapper:before, .nutrition-advice-centre-page .image-with-text:nth-of-type(2) .content-wrapper:after {
  background: #e8274d;
}

.nutrition-advice-centre-page .image-with-text:nth-of-type(6) .content-wrapper:before, .nutrition-advice-centre-page .image-with-text:nth-of-type(6) .content-wrapper:after {
  background: #6bc9bf;
}

.nutrition-advice-centre-page .image-with-text:nth-of-type(4) .content-wrapper:before, .nutrition-advice-centre-page .image-with-text:nth-of-type(4) .content-wrapper:after {
  background: #d8a809;
}

.nutrition-advice-centre-page .image-with-text:nth-of-type(4) .content-wrapper img {
  float: right;
}

.nutrition-advice-centre-page .image-with-text:nth-child(4) div[class*="col-"]:first-child {
  order: 2;
}

.nutrition-advice-centre-page .image-with-text:nth-child(4) div[class*="col-"]:nth-child(2) {
  order: 1;
}

@media screen and (max-width: 768px) {
  .nutrition-advice-centre-page .image-with-text:nth-child(4) div[class*="col-"]:first-child {
    order: 1;
  }
  .nutrition-advice-centre-page .image-with-text:nth-child(4) div[class*="col-"]:nth-child(2) {
    order: 2;
  }
}

.nutrition-advice-centre-page .ambassador-hero-promo {
  position: relative;
  padding: 75px 0;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.nutrition-advice-centre-page .ambassador-hero-promo:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(16, 20, 62, 0.5);
}

.nutrition-advice-centre-page .page-heading-alt {
  background-color: #f6f7ff;
}

.nutrition-advice-centre-page .page-heading-alt .additional-image {
  left: -260px;
}

.nutrition-advice-centre-page .helpline {
  margin-bottom: 80px;
}

.nutrition-advice-centre-page .helpline .employees-list {
  background-color: #f6f7ff;
}

.nutrition-advice-centre-page .helpline .employees-list .employee-wrapper {
  justify-content: space-around;
}

.nutrition-advice-centre-page .nutrition .employee-wrapper,
.nutrition-advice-centre-page .canine-message .employee-wrapper {
  background-color: #f6f7ff;
}

.nutrition-advice-centre-page .nutrition .employee-wrapper .particle-image,
.nutrition-advice-centre-page .canine-message .employee-wrapper .particle-image {
  top: 95px;
  right: -280px;
  left: unset;
}

.nutrition-advice-centre-page .canine-message .employees-list .row {
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .nutrition-advice-centre-page .helpline .employees-list,
  .nutrition-advice-centre-page .nutrition .employees-list,
  .nutrition-advice-centre-page .canine-message .employees-list {
    background: #f6f7ff;
  }
}

@media screen and (max-width: 480px) {
  .nutrition-advice-centre-page .helpline .employees-list,
  .nutrition-advice-centre-page .nutrition .employees-list,
  .nutrition-advice-centre-page .canine-message .employees-list {
    background: #f6f7ff;
  }
}

.nutrition-advice-centre-page .employee-slider .slick-dots {
  bottom: -35px;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .nutrition-advice-centre-page .employee-slider .slick-dots {
    bottom: -90px;
  }
}

.nutrition-advice-centre-page .employee-slider .slick-dots li button {
  border-color: #10143e;
}

.nutrition-advice-centre-page .employee-slider .slick-dots li button:hover {
  background: transparent;
}

.nutrition-advice-centre-page .employee-slider .slick-dots li.slick-active button {
  background: #70728b;
}

.nutrition-advice-centre-page .employee-slider .slick-dots li:after {
  background: #10143e;
}

.nutrition-advice-centre-page .employees-list {
  padding-top: 0;
}

.nutrition-advice-centre-page .employees-list .particle-image {
  display: none;
}

.nutrition-advice-centre-page .employees-list .job-role {
  margin: 0;
}

.about-us-page .sponsors-slider-wrapper,
.about-us-page .charities-slider-wrapper {
  position: relative;
}

.about-us-page .employees-list {
  margin-bottom: 0;
}

.about-us-page .employees-list .slick-dots {
  bottom: -30px;
}

.about-us-page .board {
  justify-content: space-around;
}

.about-us-page .board-members {
  background-color: #f6f7ff;
  position: relative;
  background-repeat: no-repeat;
  background-position: -298px 424px;
}

.about-us-page .board-members .particle-image {
  background-repeat: no-repeat;
  position: absolute;
  width: 482px;
  height: 444px;
  right: -319px;
  top: 55px;
}

.about-us-page .board-members .employees-list .particle-image {
  display: none;
}

.about-us-page .about-us-video {
  background-repeat: no-repeat;
  background-position: -346px -10px;
}

.about-us-page .about-us-video:after {
  content: '';
  display: block;
  width: 100%;
  height: 5px;
  background-color: #d9bd45;
}

@media (max-width: 1024px) {
  .about-us-page .about-us-video {
    margin-top: 120px;
  }
}

@media (max-width: 320px) {
  .about-us-page .about-us-video {
    margin-top: 70px;
  }
}

.about-us-page .video-with-text {
  padding-bottom: 0;
}

.about-us-page .charities,
.about-us-page .sponsorships {
  padding: 55px 0 65px 0;
}

.about-us-page .charities {
  background-color: #f6f7ff;
}

.contact-us-page .light-page-heading {
  height: 250px;
  padding-bottom: 50px;
  padding-top: 50px;
}

@media screen and (max-width: 768px) {
  .contact-us-page .light-page-heading {
    height: auto;
  }
}

.contact-us-page .pull-up {
  margin-top: -30px;
}

.contact-us-page address p {
  margin-bottom: 0;
}

.contact-us-page address p.nutrition-helpline {
  margin-top: 15px;
  margin-bottom: 0;
}

.contact-us-page address span {
  display: block;
}

.contact-us-page address span.nutrition-helpline-number {
  margin-top: 0;
  display: inline;
}

.contact-us-page address span a {
  color: #323232;
}

.contact-us-page .map {
  width: 100%;
  height: 450px;
}

.contact-us-page h3 {
  font-family: "GothamBold", sans-serif;
}

.contact-us-page .get-in-touch-form {
  margin-bottom: 75px;
}

.contact-us-page .get-in-touch-form textarea.form-control {
  height: 400px;
}

.contact-us-page .get-in-touch-form .col-12 {
  position: relative;
}

.contact-us-page .get-in-touch-form .col-12 .absolute {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 769px) {
  .contact-us-page .get-in-touch-form .col-12 .absolute {
    position: static;
  }
}

.contact-us-page .feed-advice-section, .contact-us-page .advisors-section {
  position: relative;
}

.products-single {
  margin-bottom: 50px;
}

.products-single *.lang-hide {
  visibility: hidden;
  height: 0;
  overflow: hidden;
  margin-bottom: 0;
  margin: 0 !important;
}

.products-single h3.subtitle {
  margin-bottom: 1em;
}

.products-single h1 {
  word-break: break-word;
}

@media screen and (max-width: 768px) {
  .products-single h1 {
    font-size: 30px;
  }
}

.products-single .content h4:nth-of-type(2) {
  margin-top: 45px;
}

.products-single #nutritional-analysis .inner {
  columns: 2;
}

.products-single .content strong {
  color: #10143e;
}

.products-single .product-buttons {
  padding-top: 30px;
}

.products-single .product-buttons .product.btn {
  background: #e8274d;
  width: 100%;
  margin-bottom: 15px;
}

@media (max-width: 1200px) {
  .products-single .product-buttons .product.btn {
    height: 7em;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 400px) {
  .products-single .product-buttons .product.btn {
    height: auto;
  }
}

.products-single .large-slider {
  margin: 0 auto 50px;
}

.products-single .large-slider .slick-slide img {
  width: auto !important;
  max-height: 500px;
  max-width: 100%;
  margin: auto !important;
  display: block !important;
}

.products-single .slider-nav {
  width: 90%;
  margin: 0 auto 40px;
}

.products-single .slider-nav .slick-slide {
  height: 193px;
}

.products-single .slider-nav .slick-slide > div {
  height: 100%;
  display: flex;
}

.products-single .slider-nav .slick-slide > div img {
  max-width: 90%;
  width: auto !important;
  max-height: 100%;
  display: block !important;
  margin: auto !important;
}

.products-single .slider-nav .slick-arrow:before {
  color: #10143e;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

.products-single .slider-nav .slick-arrow.slick-prev:before {
  content: '\f053';
}

.products-single .slider-nav .slick-arrow.slick-next:before {
  content: '\f054';
}

.products-single aside {
  text-align: center;
  position: relative;
  z-index: 4;
}

.products-single aside .tips-bubble {
  padding: 30px;
  text-align: left;
  background: #cfd0d8;
  margin-top: 30px;
  margin-bottom: 35px;
  position: relative;
}

.products-single aside .tips-bubble strong {
  font-family: "GothamBold", sans-serif;
  margin-bottom: 10px;
  display: block;
  font-weight: normal;
}

.products-single aside .tips-bubble p:last-of-type {
  margin-bottom: 0;
}

.products-single aside .tips-bubble:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 40px solid #cfd0d8;
  position: absolute;
  top: 100%;
  left: 0;
}

.products-single .ambassador-section {
  margin-top: 74px;
}

.products-single .ambassador-section .ambassador-slider a {
  display: block;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.products-single .ambassador-section .ambassador-slider li {
  text-align: center;
}

.products-single .ambassador-section .ambassador-slider li img {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.products-single .ambassador-section p.underline {
  display: block;
  border-bottom: 1px solid rgba(87, 87, 86, 0.4);
  text-align: left;
  margin-bottom: 20px;
}

.products-single .ambassador-section .slick-arrow {
  position: absolute;
  font-size: 0;
  color: transparent;
  width: 40px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: 0;
  cursor: pointer;
}

.products-single .ambassador-section .slick-arrow:before {
  color: #10143e;
  font-size: 20px;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

.products-single .ambassador-section .slick-arrow.slick-prev {
  left: -40px;
}

@media screen and (max-width: 768px) {
  .products-single .ambassador-section .slick-arrow.slick-prev {
    left: 0;
    top: 100%;
  }
}

.products-single .ambassador-section .slick-arrow.slick-prev:before {
  content: '\f053';
}

.products-single .ambassador-section .slick-arrow.slick-next {
  right: -40px;
}

@media screen and (max-width: 768px) {
  .products-single .ambassador-section .slick-arrow.slick-next {
    right: 0;
    top: 100%;
  }
}

.products-single .ambassador-section .slick-arrow.slick-next:before {
  content: '\f054';
}

.products-single main {
  padding-left: 65px;
}

@media (max-width: 768px) {
  .products-single main {
    padding-left: 15px;
  }
}

.products-single main .links {
  margin-top: 50px;
}

.products-single main .links .btn {
  width: 100%;
}

.products-single main .tabs {
  margin: 60px auto;
  margin-bottom: 20px;
}

.products-single main .tabs .row {
  margin: 0;
}

.products-single main .tabs header li {
  list-style: none;
  padding: 8px 10px;
  display: flex;
  font-size: 16px;
  text-align: center;
  background: #10143e;
  font-family: "GothamBold", sans-serif;
  border: 1px solid #10143e;
  border-right: 0;
  border-bottom-color: white;
  color: white;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.products-single main .tabs header li:last-child {
  border-right: 1px solid #10143e;
}

.products-single main .tabs header li.active {
  background: white;
  border-bottom: 0;
  border-color: #10143e;
  color: #10143e;
  border-right: 1px solid #10143e;
  cursor: default;
}

.products-single main .tabs header li.active + li {
  border-left: none;
}

.products-single main .tabs .tab-content > div {
  display: none;
  padding: 20px;
  background: white;
  border: 1px solid #10143e;
  border-top: none;
  font-size: 16px;
  max-height: 20em;
  overflow: hidden;
  position: relative;
  transition: all 1s;
}

.products-single main .tabs .tab-content > div.active {
  display: block;
}

.products-single main .tabs .tab-content > div.show-more .button-wrap {
  display: block;
}

.products-single main .tabs .tab-content > div.expanded {
  max-height: unset;
}

.products-single main .tabs .tab-content > div.expanded .button-wrap {
  opacity: 0;
}

.products-single main .tabs .tab-content > div .button-wrap {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: #f6f7ff;
  opacity: 1;
  transition: opacity 0.5s;
}

.products-single main .tabs .tab-content > div .table-wrap {
  overflow: auto;
  margin-bottom: 30px;
}

.products-single main .tabs .tab-content > div .table-wrap table th {
  padding: 10px;
  white-space: nowrap;
  background-color: rgba(16, 20, 62, 0.1);
  border: 1px solid #323232;
  font-weight: bold;
}

.products-single main .tabs .tab-content > div .table-wrap table td {
  padding: 10px;
  border: 1px solid #323232;
}

.products-single main h4.content-section-heading {
  font-weight: bold;
}

.products-single main .pdf-button {
  padding: 0.85rem 1.13rem;
}

.products-single main .pdf-button img {
  margin-bottom: 0 !important;
  float: left;
}

.products-single .products-loop {
  background: #f6f7ff;
  position: relative;
}

.products-single .products-loop .container {
  padding: 60px 15px;
}

.products-single .products-loop:after {
  content: "";
  position: absolute;
  right: -100px;
  top: -450px;
  background-repeat: no-repeat;
  background-position: 0 0;
  transform: rotate(-180deg);
  width: 400px;
  height: 800px;
  background-size: contain;
}

@media (max-width: 1024px) {
  .products-single .products-loop:after {
    z-index: -1;
  }
}

.products-single .products-loop h2 {
  font-family: "GothamBold", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 45px;
}

.products-single .products-loop .products--link-block .image-container {
  text-align: center;
}

.products-single .products-loop .products--link-block .image-container img {
  margin: 0 auto;
}

.products-single .products-loop .slick-arrow {
  position: absolute;
  font-size: 0;
  color: transparent;
  width: 40px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: 0;
  cursor: pointer;
}

.products-single .products-loop .slick-arrow:before {
  color: #10143e;
  font-size: 20px;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

.products-single .products-loop .slick-arrow.slick-prev {
  left: -40px;
}

.products-single .products-loop .slick-arrow.slick-prev:before {
  content: '\f053';
}

.products-single .products-loop .slick-arrow.slick-next {
  right: -40px;
}

.products-single .products-loop .slick-arrow.slick-next:before {
  content: '\f054';
}

.products-single .info h4,
.products-single .feeding-guidelines h4 {
  font-family: "AgendaBold", sans-serif;
}

.products-single .info p,
.products-single .feeding-guidelines p {
  color: black;
}

.products-single .info h4 {
  margin-top: 1rem;
}

.products-single .info p:nth-child(n+3) {
  margin-bottom: 2px;
}

.products-single .feeding-guidelines h4 {
  margin-top: 2rem;
}

.employee-single-page {
  background-repeat: no-repeat;
  background-position: -296px -153px;
  margin-top: 30px;
}

.employee-single-page .job-role {
  color: #d9bd45;
}

.employee-single-page .other-employees {
  margin-top: 60px;
}

.employee-single-page .ambassador-hero-promo {
  position: relative;
  padding: 75px 0;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.employee-single-page .ambassador-hero-promo:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(16, 20, 62, 0.5);
}

.stockists > .container {
  margin: 50px auto;
  margin-bottom: 50px;
}

.stockists .form-group {
  margin-bottom: 0;
}

.stockists .town-input {
  background-image: url("/dod/images/map-crosshair.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 17px 25px !important;
  padding-left: 50px !important;
  background-position: 15px center !important;
  height: 50px !important;
  line-height: 50px !important;
  max-width: 100% !important;
}

.stockists .town-input.searching {
  background-image: url("/dod/images/location-searching.gif") !important;
  background-size: 25px 17px !important;
}

.stockists .intro-section h2 {
  text-align: center;
  color: #10143e;
}

.stockists .search-group {
  position: relative;
}

.stockists .search-group #getCurrentLocation {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background: #70728b;
  opacity: 0;
  border: 0;
  cursor: pointer;
  z-index: 20;
  color: transparent;
  padding: 0;
}

.stockists .search-group #getCurrentLocation:hover {
  opacity: 0.2;
}

.stockists .search-group input.town-input {
  width: 90%;
  display: inline-block;
  border: 1px solid #10143e;
}

.stockists .search-group .input-group-append {
  width: 10%;
  display: inline-block;
}

.stockists .search-group .input-group-append .btn {
  padding: 0.95rem 0;
}

.stockists .form-wrap {
  padding-top: 15px;
  padding-bottom: 15px;
}

.stockists .form-wrap input {
  max-width: 100%;
  box-sizing: border-box;
  height: 45px;
  line-height: 50px;
  border: 1px solid #10143e;
}

.stockists .form-wrap select {
  border: 1px solid #10143e;
}

.stockists .map-wrap {
  position: relative;
}

.stockists .map-wrap .loading-block {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.stockists .map-wrap .loading-block #loading-circle {
  display: block;
  width: 80px;
  height: 80px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.stockists .map-wrap .loading-block #loading-circle:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #10143e transparent #10143e transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 900px) {
  .stockists .map-wrap {
    display: flex;
    flex-direction: column-reverse;
  }
}

.stockists .map-wrap.open .stockist-list {
  width: 400px;
  overflow: scroll;
}

.stockists .map-wrap.open .map {
  width: calc(100% - 400px);
}

.stockists .map-wrap .stockist-list {
  height: 625px;
  width: 0;
  overflow: hidden;
  float: left;
}

.stockists .map-wrap .stockist-list .results-heading {
  font-size: 14px;
  padding: 0 20px;
}

.stockists .map-wrap .stockist-list .show-all {
  display: none;
  font-size: 14px;
  padding: 8px;
  width: 100%;
}

.stockists .map-wrap .stockist-list h3 {
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solic #10143e;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.stockists .map-wrap .stockist-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.stockists .map-wrap .stockist-list ul li {
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
}

.stockists .map-wrap .stockist-list ul li .other-details {
  padding-left: 35px;
}

.stockists .map-wrap .stockist-list ul li .address {
  min-height: 55px;
}

.stockists .map-wrap .stockist-list ul li:hover {
  background: rgba(112, 114, 139, 0.05);
}

.stockists .map-wrap .stockist-list ul li.active {
  background: rgba(112, 114, 139, 0.2);
}

.stockists .map-wrap .stockist-list ul li h4 {
  font-size: 16px;
  font-weight: bold;
  color: #10143e;
}

.stockists .map-wrap .stockist-list ul li i {
  color: white;
  background: url("/dod/images/map-marker-stockists.svg");
  width: 24px;
  height: 55px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 3px;
  font-style: normal;
  font-weight: 900;
  font-family: "Hind", sans-serif;
  margin-right: 10px;
  float: left;
}

.stockists .map-wrap .stockist-list ul li .address {
  height: 0;
  max-height: 400px;
  overflow: hidden;
  transition: height 0.5s;
  height: 1.4em;
}

.stockists .map-wrap .map {
  height: 625px;
}

@media (max-width: 900px) {
  .stockists .map-wrap.open .stockist-list {
    height: 300px;
    width: 100%;
  }
  .stockists .map-wrap.open .stockist-list li {
    width: 50%;
    float: left;
  }
  .stockists .map-wrap.open .map {
    width: 100%;
  }
  .stockists .map-wrap .stockist-list {
    width: 100%;
    height: 0;
  }
}

@media (max-width: 576px) {
  .stockists .map-wrap.open .stockist-list {
    height: 600px;
    width: 100%;
  }
  .stockists .map-wrap.open .stockist-list li {
    width: 100%;
    float: left;
  }
}

.stockists #markerContent {
  background: #10143e;
  color: white;
  min-width: 400px;
  font-size: 16px;
  padding: 20px;
}

.stockists #markerContent p {
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 0;
}

.stockists #markerContent #firstHeading {
  font-size: 18px;
}

.stockists #markerContent a {
  color: white;
}

.stockists #markerContent a:hover {
  text-decoration: underline;
}

.stockists .gm-style-iw.gm-style-iw-c {
  padding: 0 !important;
  border-radius: 0 !important;
}

.stockists .gm-style .gm-style-iw-d {
  box-sizing: border-box;
  overflow: auto !important;
  border-radius: 0 !important;
}

.stockists button.gm-ui-hover-effect:before {
  content: '×';
  color: white;
  font-size: 25px;
  position: relative;
  top: 5px;
  right: 3px;
}

.stockists button.gm-ui-hover-effect img {
  display: none;
}

.stockists .gm-style .gm-style-iw-t::after {
  background: linear-gradient(45deg, #10143e 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
}

.stockists .results-search .content {
  font-size: 14px;
}

.stockists .results-search #countryName {
  color: #10143e;
  font-weight: bold;
}

.stockists .results-search select {
  margin-top: 5px;
  padding: 8px;
  padding-right: 25px;
  font-size: 16px;
  max-width: 100% !important;
  width: 100%;
  border: 1px solid #10143e;
}

.stockists .results-search .search-group .town-input {
  padding-left: 50px !important;
  background-position: 15px center !important;
  height: 25px !important;
  line-height: 25px !important;
  max-width: 100% !important;
  width: auto;
}

.stockists .results-search .search-group .input-group-append {
  width: 13%;
}

.stockists .results-search .search-group .input-group-append .btn {
  padding: 13px 0;
  margin: 0;
  margin-top: 3px;
}

.ambassadors-categories-single:after {
  content: "";
  width: 220px;
  height: 490px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: -1;
}

.ambassadors-categories-single .title {
  background: #70728b;
  color: white;
  padding: 15px 0;
}

.ambassadors-categories-single .title h2 {
  padding: 0;
  margin: 0;
}

.ambassadors-categories-single .title .categories {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.ambassadors-categories-single .title .categories li {
  display: inline-block;
  padding-right: 34px;
  position: relative;
}

.ambassadors-categories-single .title .categories li:after {
  content: '';
  position: absolute;
  height: 1px;
  width: 39px;
  top: 6px;
  left: 13px;
  display: block;
  background: white;
}

.ambassadors-categories-single .title .categories li.active a {
  background: white;
}

.ambassadors-categories-single .title .categories li:last-child {
  padding-right: 0;
}

.ambassadors-categories-single .title .categories li:last-child:after {
  display: none;
}

.ambassadors-categories-single .title .categories li a {
  display: block;
  height: 13px;
  width: 13px;
  border: 1px solid white;
  border-radius: 50%;
  background: transparent;
}

.ambassadors-categories-single .ambassador-categories .link-block:before {
  background: none;
}

.ambassadors-categories-single .ambassador-categories .link-block a {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ambassadors-categories-single .ambassador-categories .link-block .info {
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: none;
  border-bottom: 3px solid #d9bd45;
  background-color: rgba(16, 20, 62, 0.75);
}

.ambassadors-categories-single .ambassador-categories .link-block .info h3 {
  padding: 10px 0;
}

.ambassadors-categories-single .ambassador-categories .link-block .info p {
  display: none;
}

.resources h3 {
  background: #f6f7ff;
  border-bottom: 1px solid #10143e;
  padding: 10px;
  font-size: 24px;
}

.resources .items {
  padding: 0;
}

.resources .items li {
  margin: 15px 0;
  list-style: none;
}

.resources .items li .image-container {
  display: block;
  width: 100%;
  height: 200px;
}

.resources .items li img {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: block;
  margin-bottom: 0 !important;
}

@media (max-width: 600px) {
  .resources .items li img {
    height: auto;
    width: 120px;
    float: none;
    margin: 0 auto;
    margin-bottom: 15px !important;
  }
}

.resources .items li h4 {
  font-size: 14px;
  color: #10143e;
  margin: 10px 0;
}

.resources .items li .content {
  font-size: 14px;
  line-height: 1.4em;
  margin-bottom: 10px;
}

.resources .point-of-sale .items li {
  clear: both;
  padding-bottom: 20px;
}

.resources .point-of-sale .items a.btn {
  width: 100%;
}
