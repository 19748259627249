.landing-form-page{
    padding-top:50px;
    padding-bottom: 24px;
    .page-title{
        display: flex;
        width: 100%;
        font-size: 26px;
        text-align: center;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        font-family: $caxtonFont;
        img{
            width: 60px;
            margin-top: -6px;
            margin-left: 6px;
            margin-right: 6px;
            margin-bottom: 0px !important;
        }
    }
    .horse-row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 50px;
        margin-top:16px;
        justify-content: center;
        .horse-item-container{
            display: flex;
            flex-direction: column;
            width: 20%;
            padding:8px;

            @media screen and (max-width: 800px) {
                width: 33.3333%;
            }

            @media screen and (max-width: 600px) {
                width: 50%;
            }
        }

        .horse-item{
            display: flex;
            flex-direction: column;
            background: #f0f0f0;
            .content-container{
                background-color: #B19B6F;
                color: white;
                display: flex;
                flex-direction: column;
                padding:4px;
                text-align: center;
                align-items: center;
                font-size: 16px;
                p{
                    padding:0;
                    font-size: 16px;
                    margin-top:-4px;
                    margin-bottom: 0 !important;
                }
            }

            .image-container{
                display: flex;
                width: 100%;
                aspect-ratio: 1/1.15;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .main-content{
        color:#273261;
        margin:0 auto;
        max-width: 800px;
        text-align: center;
        strong{
            font-family: 'AgendaBold';
        }
    }

    .embed-container{
        img{
            display: block;
            margin:0 auto;
            margin-top:30px;
            max-width: 200px;
        }
    }
}
