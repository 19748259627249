.careers-single-page{
  h3{
    font-family: $titleFont;
    color: $darkBlue;
  }
}

#cvModal{
  .modal-dialog{
    top: 50%;
    transform: translateY(-50%);
  }
}