.news-single-page {
    .news-single-banner,
    .article-content {
        padding: 18px;
    }

    .breadcrumbs {
        display: none;
    }

    .news-single-banner {
        background-color: $lightestBlue;

        p {
            margin-bottom: 0;
        }
    }

    .news-article-footer {
        margin-bottom: 50px;
    }

    .article-btn {
        padding: .85rem 0;

        a {
            color: $white;
        }
    }
}

.social-link {
    margin: 10px;
    font-size: 28px;
}
