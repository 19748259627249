.site-footer {
    height: auto;
    background-color: $darkBlue;
    border-bottom: 10px solid $gold;
    background-color: $darkBlue;
    ////background-image: url('/dod/images/particles-3.svg');
    background-repeat: no-repeat;
    background-position: 105% -30%;
    padding-top: 70px;
    padding-bottom: 50px;
    //margin-top: 2rem;
    .logo-container {
        margin-top: 10px;
        @media screen and (max-width: 768px) {
            .site-logo{
                img{
                    height: unset;
                    max-width: 90%;
                    margin:0 auto;
                }
            }
        }
    }

    .social {
        text-align: center;
        margin-top: 10px;

        a {
            color: $white;
            font-size: 18px;
            display: inline-block;
            margin-left: 3px;
            margin-right: 3px;
        }
    }

    .footer-nav {
        //border-right: 2px solid $gold;
        @media (max-width: 769px) {
            border-right: 0;
        }

        .footer-link {
            margin-bottom: 15px;

            a {
                color: $white;
                font-size: 14px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .footer-wrapper {
        .footer-link {
            a {
                font-family: $bodyFont;
            }
        }
    }

    .footer-wrapper-bottom {
        .footer-nav {
            margin-left: 0;
            border-right: 0;

            .footer-link {
                &:first-child {
                    margin-right: 132px;
                }

                &:nth-child(2) {
                    margin-right: 185px;
                }

                a {
                    font-size: 12px;
                }
            }
        }
    }

    .logo-section {
        .logo-image {
            margin-bottom: 10px;
        }
    }

    .brands-title {
        position: relative;
        margin-top: 30px;
        margin-bottom: 20px;
        display: block;

        &:before {
            content: "";
            height: 1px;
            background: $white;
            position: absolute;
            top: 50%;
            left: -5px;
            width: 70px;
        }

        &:after {
            content: "";
            height: 1px;
            background: $white;
            position: absolute;
            top: 50%;
            right: -5px;
            width: 70px;
        }
    }

    .credits-row {
        img {
            max-width: 70px;
        }
    }

}

.footer-newsletter-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    background: $lightestBlue;

    h2 {
        @media screen and (max-width: 768px) {
            font-size: 22px;
        }
    }

    input[type='email'] {
        background: $white;
        padding: 6px 15px;
        border: 1px solid $lighterBlue;
        @media screen and (max-width: 768px) {
            width: calc(100% - 30px);
        }
    }

    .waves-input-wrapper {
        @media screen and (max-width: 768px) {
            width: 100%;
            margin-left: 0;
        }
    }

    input[type='submit'] {
        margin-left: 20px;
        width: 150px;
        border: 2px solid $darkBlue;
        color: $darkBlue !important;
        border-radius: 0;
        @media screen and (max-width: 768px) {
            width: 100%;
            margin-left: 0;
            margin-top: 20px;
        }
    }
}
