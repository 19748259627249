.news-page {
    margin-bottom: 30px;
    .heading-container{
        background: $lightestBlue;
        padding-top:50px;
        padding-bottom: 50px;
        margin-bottom: 50px;
        .breadcrumbs{
            margin: 0 0 20px;
        }
    }
}
