.team-members-page {
    .circle-hero-image{
        display: none;
    }
    .circle-image-header .breadcrumbs{
        margin-left: -15px;
    }
    .team-member-block {
        margin-bottom: 30px;
        background: $lightestBlue;
        .content{
            padding:15px;
            a{
                font-weight: bold;
                text-decoration: none;
                color: $darkBlue;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .employees-list .employee-thumbnail{
        border-radius: 0;
        box-shadow: unset;
        width: 100%;
        background-position: center center;
        background-size: cover;
    }
}
