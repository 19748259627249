.home-header {
    background-color: $darkBlue;
    height: auto;
    //background-image: url('/dod/images/particles-1.svg');
    background-repeat: no-repeat;
    background-position: 80% 20%;
    padding-bottom: 50px;
    position: relative;
    @media screen and (max-width: 768px){
        padding-bottom: 0px;
        background-color: $white;
    }

    .container.slider-container{
        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .mobile-slider{
        .slick-slide{
            position: relative;
        }
        .slide-content{
            position: absolute;
            top:0;
            left: 0;
            height: 100%;
            padding-top: 20px;
            width: 100%;
            text-align: center;
            h2{
                color: #ffffff;
            }
            h3{
                display: block;
            }
            //.btn{
            //    position: absolute;
            //    bottom:30px;
            //    left: 50%;
            //    transform: translateX(-50%);
            //}
        }
    }

    .container {
        height: 100%;
        display: block;
        z-index: 2;
        flex-direction: column;
        justify-content: center;
    }

    .content-row {
        z-index: 2;
        @media screen and (max-width: 768px) {
            //position: absolute;
            //bottom:0;
            //left:0;
        }
    }

    .home-main-slider {
        z-index: 2;
        padding-top: 50px;
        @media (max-width: 769px) {
            padding-top: 0px;
        }

        .slick-dots {
            bottom: 10px;
            @media screen and (max-width: 768px) {
                bottom:20px;
            }
        }

        h1 {
            font-size: 36px;
            font-family: "AgendaBold", sans-serif;
            @media(max-width: 375px) {
                font-size: 30px;
            }
            @media(max-width: 320px) {
                font-size: 30px;
            }
        }

        h3 {
            font-weight: bold;
            font-size: 24px;
            color: white;
        }
    }

    .content {
        text-align: right;
        color: $white;
        padding-bottom: 50px;
        width: 100%;
        @media (max-width: 768px) {
            padding-bottom: 50px;
            min-height: 400px;
            //max-height: 400px;
        }

        .content-inner {
            margin-right: 0;
            margin-left: auto;
            max-width: 500px;
            text-align: center;
            @media (max-width: 768px) {
                margin-top: calc(100% - 50px);
                margin-bottom: 0;
                @media screen and (max-width: 768px) {
                    margin-top:10px;
                }
                .btn{
                    width: auto;
                    margin-bottom: 30px;
                }
            }

            @media screen and (max-width: 768px) {
                h1, h3 {
                    //display: none;
                }
            }

            .btn {
                color: $darkBlue !important;
                font-weight: bold !important;
                font-size: 18px;
                letter-spacing: normal;
            }
        }
    }

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //@media screen and (max-width: 768px) {
        //    position: static;
        //    height: 300px;
        //    width: calc(100% + 30px);
        //    margin-left: -15px;
        //}

        .image-container {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;
            z-index: 1;

            .main-slider-image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                height: 100%;
                width: 100%;
                max-width: unset;
                transition: background 0.2s ease;
                background-size: cover;
                background-position: center center;

                &.active {
                    display: block;
                }

                &.top {
                    z-index: -1;
                }
            }

            //&:after{
            //    content:"";
            //    position: absolute;
            //    top:0;
            //    left: 0;
            //    width: 100%;
            //    height: 100%;
            //    background: rgba($darkBlue, 0.5);
            //}
        }
    }
}

.blocks-row {
    z-index: 2;
    position: relative;
    transform: translateY(-50%);
    @media screen and (max-width: 768px) {
        transform: translateY(0px);
    }

    .blocks-row-block {
        h4 {
            text-align: center;
            font-size: 24px;
            font-weight: 900;
        }

        .btn {
            font-size: 18px;
            letter-spacing: normal;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }
}
