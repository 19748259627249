.faqs-listing-page{
  .page-heading-alt{
    background-color: $lightestBlue;
    padding-bottom: 30px;
  }
  ul{
    &.faq-list{
      padding: 0;
      list-style-type: none;
      li{
        background-color: $white;
        text-align: center;
        box-shadow: #909090 0px 1px 5px 0;
        margin-bottom: 15px;
        &.active-topic{
          position: relative;
          background-color: $gold;
          &:after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid $gold;
            @media screen and (max-width: 480px){
              display: none;
            }
          }
          a{
            color: $white;
          }
        }
        a{
          color: $darkBlue;
          padding: 12px 0;
          display: block;
        }
      }
    }
  }
  .faq-accordion{
    margin-top: 55px;
    margin-bottom: 30px;
    .faq-question-container{
      //margin-bottom: 15px;
      padding: 20px 0;
      &:first-child{
        border-top: 2px solid $grey;
      }
      border-bottom: 2px solid $grey;
      h5{
        margin-bottom: 0;
        font-family: $titleFont;
      }
      .accordion-expand{
        float: right;
        margin-right: 15px;
      }
      .faq-content{
        &.open{
          max-height: 100%;
          overflow: auto;
          margin-top: 20px;
        }
        &.closed{
          max-height: 0;
          overflow: hidden;
        }
      }
    }
  }
}