.light-page-heading{
    height: auto;
    background: $lightestBlue;
    border-bottom: 0;
    position: relative;
    padding-top:20px;
    padding-bottom: 10px;
    &:before{
        display: none;
    }
    p{
        color: $darkBlue;
        margin-bottom: 0 !important;
    }
    &:after{
        content: "";
        width: 220px;
        height: 490px;
        ////background-image: url(/dod/images/particles-5.svg);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: -50px;
        right: 0;
        z-index: 1;
    }
}
