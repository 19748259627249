.charities{
  position: relative;
  .charity-slide{
    height: 115px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    @media screen and (max-width: 480px){
      background-size: cover;
    }
    @media screen and (max-width: 425px){
      background-size: contain;
    }
    @media screen and (max-width: 414px){
      background-size: contain;
    }
    @media screen and (max-width: 375px){
      height: 165px;
    }
    @media screen and (max-width: 320px){
      height: 145px;
    }
  }
  .charities-slider{
    margin-top: 65px;
  }
  .next-button,
  .prev-button{
    position: absolute;
    cursor: pointer;
    i{
      color: $black;
    }
  }
  .prev-button{
    top: 50%;
    transform: translateY(-50%);
    left: -80px;
    @media screen and (max-width: 1024px){
      left: -28px;
    }
    @media screen and (max-width: 768px){
      left: -65px;
    }
    @media screen and (max-width: 480px){
      left: 150px;
      top: 150px;
    }
    @media screen and (max-width: 320px){
      left: 90px;
    }
  }
  .next-button{
    top: 50%;
    transform: translateY(-50%);
    right: -80px;
    @media screen and (max-width: 1024px){
      right: -28px;
    }
    @media screen and (max-width: 768px){
      right: -65px;
    }
    @media screen and (max-width: 480px){
      right: 150px;
      top: 150px;
    }
    @media screen and (max-width: 320px){
      right: 90px;
    }
  }

  .next-button,
  .prev-button{
    @media screen and (max-width: 375px){
      top: 190px;
    }
    @media screen and (max-width: 320px){
      top: 165px;
    }
  }
}

