.trade-area-landing {
    a {
        &.btn {
            padding: .85rem 1.13rem;
        }
    }

    .trade-area-link {
        margin-top: 45px;
    }

  .page-heading-alt .featured-image{
    height: 140px;
  }
}

.trade-area{
    .listing-accordion{
        .accordion-header{
            font-size: 24px;
            text-transform: unset;
            color: $darkBlue;
            border-bottom: 1px solid $darkBlue;
            padding-bottom: 10px;
            padding-top:10px;
            position: relative;
            cursor: pointer;
            &:after{
                content:"+";
                font-weight: normal;
                position: absolute;
                top:55%;
                right:0;
                transform: translateY(-50%);
            }
            &.open{
                &:after{
                    content:"-";
                }
            }
        }
        .accordion-body{
            display: none;
            padding-left: 15px;
            padding-right: 15px;
            &.open{
                display: block;
            }
        }
    }
}
