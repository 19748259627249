.canine-product-links {
    margin-bottom: 30px;
    .canine-product-link-wrapper {
        margin-bottom: 30px;
        .canine-product-link {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 30px 60px;
            min-height: 300px;
            height: 300px; //IE 11 needs fixed height
            width: 100%;
            text-align: center;
            flex-direction: column;
            .canine-product-link-image {
                margin-bottom: 0 !important;
            }
            .canine-product-link-text {
                //margin-top: 20px;
                //margin-top:0;
                padding-top:20px;
                overflow:hidden;
                height:auto;
                max-height:0;
                transition:all 0.75s;
                width: 100%; //IE11 needs to add a specific width
            }
            &:hover{
                .canine-product-link-text{

                    max-height:150px;
                }
            }
        }
    }
}