.contact-us-page {

    .light-page-heading {
        height: 250px;
        padding-bottom: 50px;
        padding-top:50px;
        @media screen and (max-width: 768px){
            height: auto;
        }
    }

    .pull-up {
        margin-top: -30px;
    }

    address {
        p {
            margin-bottom: 0;

            &.nutrition-helpline {
                margin-top: 15px;
                margin-bottom: 0;
            }
        }

        span {
            display: block;
            //margin-top: 15px;
            &.nutrition-helpline-number {
                margin-top: 0;
                display: inline;
            }

            a {
                color: $grey;
            }
        }
    }

    .map {
        width: 100%;
        height: 450px;
    }

    h3 {
        font-family: $titleFont;
    }


    .get-in-touch-form {
        margin-bottom: 75px;

        textarea.form-control {
            height: 400px;
        }

        .col-12 {
            position: relative;

            .absolute {
                position: absolute;
                bottom: 0;
                right: 0;
                @media (max-width: 769px) {
                    position: static;
                }
            }
        }
    }

    .feed-advice-section, .advisors-section {
        position: relative;

        &:after {
            //content: "";
            //width: 420px;
            //height: 590px;
            ////background-image: url(/dod/images/particles-4.svg);
            //background-repeat: no-repeat;
            //background-size: contain;
            //position: absolute;
            //bottom: -200px;
            //left: 0;
            //z-index: 1;
            //opacity: 0.5;
        }
    }

}



