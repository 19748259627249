.trade-area{
    .container {
        background: $white;
        margin-top: -100px;
        border-radius: 25px;

        @media (max-width:600px){
            margin:-100px 20px 0;
            width:calc(100% - 40px);
            border-top:50px solid $gold;
            border-radius:25px;
            main{
                padding:15px;
                h2{
                    margin:15px 0;
                    text-align:center;
                }
            }
        }
    }
    main{
        padding: 50px;

    }

    aside{
        .title{
            height: 50px;
            background: $gold;
            border-radius:25px 0 0 0;
            color: $white;
            line-height: 50px;
            padding-left: 20px;
            font-family: $titleFont;
            font-size: 21px;
            font-weight: 800;
            a{
                font-size: 16px;
                font-weight: normal;
                color: $white;
                &:hover{
                    span{
                        text-decoration: underline;
                    }
                }
            }
        }
        nav{
            h2.menu-heading{
                font-size: 18px;
                text-transform: unset;
                color: $darkBlue;
                padding-top:25px;
                padding-bottom: 10px;
            }

            > ul{
                margin:20px 0;
                padding:0;
                > li{
                    > a {
                        //border-bottom: 1px solid;
                        position: relative;
                        &:before {
                            font-family:'Font Awesome 5 Pro';
                            content: '\f054';
                            margin-right: 10px;
                            font-size: 15px;
                            position: relative;
                            top: 2px;
                        }
                        &:after{
                            content:"";
                            position: absolute;
                            top:100%;
                            left: 10px;
                            width: calc(100% - 20px);
                            height: 1px;
                            background: $darkBlue;
                        }
                        &.no-icon:before {
                            display: none;
                        }
                    }
                    ul{
                        padding-left: 35px;
                        li{
                            ul{
                                padding-left: 20px;
                                li{
                                    position: relative;
                                    a {
                                        &:before {
                                            content:"-";
                                            position: absolute;
                                            top: 10px;
                                            left: -10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    a{
                        color: rgba($darkBlue, 0.5);
                        padding:10px 0;
                        display:block;
                        &.has-child{
                            border-bottom: 0;
                            &:after{
                                display: none;
                            }
                        }
                        &.active {
                            color: $darkBlue;
                            border-bottom: 0;
                            font-weight: bolder;
                            &.has-child{
                                padding-bottom: 0;
                                border-bottom: 0;
                            }
                            ul{
                                padding-left: 35px;
                            }
                        }
                        &:hover{
                            color:$darkBlue;
                        }
                    }
                    &:last-child{
                        a{
                            border:none;
                            &:after{
                                display: none;
                            }
                        }
                    }
                    &.has-child{
                        > a:first-of-type{
                            background: $lightestBlue;
                            padding: 0px 0px 4px;
                        }
                        padding-bottom: 20px;
                    }
                }
            }
            li{
                list-style:none;
                li{
                    a{
                        padding-bottom:0;
                    }
                }

                i{
                    display:none;
                }
            }
        }

        @media (max-width:600px){
            border-top:none;
        }
    }
    form{
        background:$lightestBlue;
        padding:20px 30px;
        @media screen and (max-width: 768px){
            padding:15px;
        }
        .form-group {
            clear:both;
            label {
                font-weight: normal;
                width: 160px;
                display:inline-block;
                vertical-align: middle;
                @media screen and (max-width: 768px){
                    width: 100%;
                    display: block;
                }
            }
            input,
            textarea,
            select,
            .other-text,
            .map {
                display:inline-block;
                width: calc(100% - 165px);
                box-sizing: border-box;
                vertical-align: middle;
                float:right;
                @media screen and (max-width: 768px){
                    width: 100%;
                    float: none;
                    display: block;
                    max-width: 100%;
                }
            }
            .map{
                position:relative;
                height:300px;
                overflow:hidden;
            }
            textarea{
                vertical-align:top;
                height:auto;
            }
            button{
                //float:right;
            }
            &.address{
                input{
                    margin-bottom: 1rem;
                }
            }
        }
    }


}

.grey-wrapper{
    background:$lighterBlue;
    padding-bottom:50px;
}
