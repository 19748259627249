.about-us-page{
  .sponsors-slider-wrapper,
  .charities-slider-wrapper{
    position: relative;
  }
  .employees-list{
    margin-bottom: 0;
    .slick-dots{
      bottom: -30px;
    }
  }
  .board{
    justify-content: space-around;
  }
  .board-members{
    background-color: $lightestBlue;
    position: relative;
    //background-image: url(/dod/images/particles-4.svg);
    background-repeat: no-repeat;
    background-position: -298px 424px;
    .particle-image{
      //background-image: url(/dod/images/particles-4.svg);
      background-repeat: no-repeat;
      position: absolute;
      width: 482px;
      height: 444px;
      right: -319px;
      top: 55px;
    }
    .employees-list{
      .particle-image{
        display: none;
      }
    }
  }
  .about-us-video{
    //background-image: url(/dod/images/particles-4.svg);
    background-repeat: no-repeat;
    background-position: -346px -10px;
    &:after{
      content: '';
      display: block;
      width: 100%;
      height: 5px;
      background-color: $gold;
    }
    @media (max-width: 1024px){
      margin-top: 120px;
    }
    @media(max-width: 320px){
      margin-top: 70px;
    }
  }
  .video-with-text{
    padding-bottom: 0;
  }
  .charities,
  .sponsorships{
    padding: 55px 0 65px 0;
  }
  .charities{
    background-color: $lightestBlue;
  }
}
