.employee-single-page{
  //background-image: url('/dod/images/particles-4.svg');
  background-repeat: no-repeat;
  background-position: -296px -153px;
  margin-top: 30px;
  .job-role{
    color: $gold;
  }
  .other-employees{
    margin-top: 60px;
  }
  .ambassador-hero-promo{
    position: relative;
    padding: 75px 0;
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(16, 20, 62, 0.5);
    }
  }

}
