.trade-login-page{
  background-image: url('/dod/images/trade-login-background-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
}

#registerModal,
#forgotPasswordModal{
  .modal-header{
    justify-content: space-around;
    p{
      margin: 0;
    }
  }
  button{
    &.close{
      float: none;
      position: absolute;
      right: 25px;
      top: 10px;
    }
  }
  .modal-body{
    background-color: $lightestBlue;
    label{
      font-weight: bold;
    }
    input{
      background-color: $white;
      border-bottom: none;
    }
  }
}



