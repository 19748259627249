.ambassadors-categories-single{
    &:after{
        content: "";
        width: 220px;
        height: 490px;
        //background-image: url(/dod/images/particles-5.svg);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 60px;
        right: 0;
        z-index: -1;
    }
    .title{
        background:$lightBlue;
        color:$white;
        padding:15px 0;
        h2{
            padding:0;
            margin: 0;
        }
        .categories{
            padding:0;
            margin:0;
            list-style:none;
            text-align: center;
            li{
                display:inline-block;
                padding-right:34px;
                position:relative;
                &:after{
                    content:'';
                    position:absolute;
                    height:1px;
                    width:39px;
                    top:6px;
                    left:13px;
                    display:block;
                    background:$white;
                }
                &.active{
                    a{
                        background:$white;
                    }
                }
                &:last-child{
                    padding-right:0;
                    &:after{
                        display:none;
                    }
                }
                a{
                    display:block;
                    height:13px;
                    width:13px;
                    border:1px solid $white;
                    border-radius:50%;
                    background:transparent;
                }
            }
        }
    }
    .ambassador-categories{
        .link-block{
            &:before{
                background:none;
            }
            a{
                display: block;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
            .info{
                top:auto;
                bottom:0;
                left:0;
                width:100%;
                transform:none;
                border-bottom:3px solid $gold;
                background-color:rgba($darkBlue, 0.75);
                h3{
                    padding:10px 0;
                }
                p{
                    display:none;
                }
            }
        }
    }
}
