.employees-list{
  position: relative;
  text-align: center;
  margin-bottom: 80px;
  padding: 50px 0px 35px 0px;
  overflow: hidden;

  @media screen and (max-width: 480px){
    padding-bottom: 5rem;
  }

  p{
    margin-bottom: .2rem;
  }

  .employee-wrapper{
    padding-top: 30px;
  }
  .employee-thumbnail{
    height: 230px;
    width: 230px;
    background-position: -36px -37px;
    border-radius: 50%;
    margin: 0 auto;
    background-size: 282px;
    background-repeat: no-repeat;
    box-shadow: inset 0px 1px 3px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    margin-bottom: 15px;
  }
  .employee-heading{
    color: $darkBlue;
    margin-bottom: 65px;
  }
  .particle-image{
    position: absolute;
    ////background-image: url(/dod/images/particles-4.svg);
    width: 482px;
    height: 530px;
    top: -303px;
    left: -203px;
  }
  .employee-name{
    font-family: $bodyFont-bold;
    color: $darkBlue;
    margin-bottom: 5px;
  }
  .job-role{
    color: $autarkyGrey;
  }
  .slick-dots{
    @media screen and (max-width: 480px){
      width: 100%;
      bottom: -35px;
    }
  }
  a{
    color: $grey;
  }
}
