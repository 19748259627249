.careers-listing-page{
  .circle-image-header{
    margin-bottom: 125px;
  }
  .page-heading-alt{
    background-color: $lightestBlue;
  }
  .no-vacancies{
    font-family: $bodyFont-bold;
  }
  .page-content{
    strong{
      font-family: $titleFont;
      font-size: 17px;
    }
  }
}






